import * as React from "react";
import * as Icons from "../../../icons";
import Tooltip from "@valraiso-esf/esf-ui/es/tooltip";
import AdsBadges from "./ads-badge";
import GmapsBadge from "./gmaps-badge";
import GmbBadge from "./gmb-badge";
import PaaBadge from "./paa-badge";
import { findUrlSearchConsole } from "../../../../utils/seo";

const Badges = ({ data, device, kinds }) => {
  return (
    <div className="seo-ranks__badges">
      {data &&
        data.keywords &&
        data.keywords[0] &&
        data.keywords.map((elmt, index) => {
          if (!elmt.keyword) {
            return null;
          }
          const ranks = elmt[device].ranks ? elmt[device].ranks : [];
          const gmb = ranks.filter((obj) => obj.type === 1)[0];
          const gmaps = ranks.filter((obj) => obj.type === 2)[0];
          const paa = ranks.filter((obj) => obj.type === 3)[0];
          const warning =
            gmb &&
            gmb.data &&
            ((gmb.data.rating !== undefined && Number(gmb.data.rating) < 3) ||
              (gmb.data.website &&
                findUrlSearchConsole(gmb.data.website, kinds) !==
                  elmt.searchConsole));
          return (
            <div
              key={elmt.keyword + "badgesline" + index}
              className="seo-ranks__line seo-ranks__badges__line"
            >
              {warning && (
                <Tooltip
                  outerClassName="seo-ranks__badges__warning"
                  title="Google My Business noté moins de 3 étoiles / lien différent"
                  position="right"
                >
                  <Icons.Warning />
                </Tooltip>
              )}
              <PaaBadge value={paa} />
              <GmapsBadge value={gmaps} />
              <GmbBadge value={gmb} />
              <AdsBadges value={elmt[device].ads} keyword={elmt.keyword} />
            </div>
          );
        })}
    </div>
  );
};

export default Badges;
