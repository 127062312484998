import * as React from "react";
import * as Icons from "../../icons";

const Filter = ({
  defaultValue = "esf-lesmenuires.fr",
  children,
  Icon,
  disabled,
  value,
  onRemove,
  open,
  setOpened,
}) => {
  return (
    <div className="filter">
      <label className="filter__button">
        <Icon className="filter__button__icon" />
        <button
          className="filter__button__button"
          onClick={() => setOpened(!open)}
          disabled={disabled || value}
        >
          {value ? value : defaultValue}
        </button>
        {value && (
          <button
            onClick={(e) => {
              e.preventDefault();
              onRemove();
            }}
            className="filter__button__close"
          >
            <Icons.Close className="filter__button__close__icon" />
          </button>
        )}
      </label>
      {open && <div>{children}</div>}
    </div>
  );
};

export default Filter;
