import * as React from "react";
import classnames from "classnames";

const Card = ({ style, value, className, visibility, bottomColor }) => {
  const classes = classnames("sale-tunnel-bloc", className);
  return (
    <div className={classes}>
      <span className="sale-tunnel-bloc__stat" style={style}>
        {value
          ? value.visitors
            ? value.visitors
            : value.number
            ? value.number
            : 0
          : 0}
      </span>
      <span className="sale-tunnel-bloc__title">
        {value ? value.name : "Informations payement"}
      </span>
      {value && value.percentage === "♾️" ? (
        <span
          className="sale-tunnel-bloc__numbers"
          style={{ color: bottomColor }}
        >
          &#8734;%
        </span>
      ) : (
        <span
          className="sale-tunnel-bloc__numbers"
          style={{ color: bottomColor }}
        >
          {value ? value.percentage : 0}
          {value && typeof value.percentage === typeof "" ? "" : "%"}
        </span>
      )}
      {visibility && (
        <span className="sale-tunnel-bloc__under">
          Visibilité: {visibility}%
        </span>
      )}
    </div>
  );
};

export default Card;
