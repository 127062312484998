import * as React from "react";
import * as Icons from "../../icons";
import classnames from "classnames";
import Button from "@valraiso-esf/esf-ui/es/button";
import { useAnalysisContext } from "../../common/contexts/analysis-context";
import { isESFDomain } from "../../../utils/seo";
import { useAppContext } from "../../common/contexts/app-context";

const AdminFilters = ({ filtersData, setDomain, page }) => {
  const { setSelectedFilter, updateAllPerfomancesData, datePeriod } =
    useAnalysisContext();
  const { domain, trackEvent } = useAppContext();
  const { setESFDomain } = useAppContext();
  const [isOpen, setIsOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const ref = React.useRef();
  const buttonRef = React.useRef();

  const classes = classnames("all-filters__choices__admin", {
    "all-filters__choices__admin--open": isOpen,
  });

  const onClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", onClickOutside);
    return () => {
      document.body.removeEventListener("click", onClickOutside);
    };
  }, []);

  const searched = filtersData.filter((option) => {
    if (option.kind !== "Sites" || (page === "Référencement" && !option.seo))
      return false;
    const val = inputValue
      .toLocaleLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const cmp = option.name
      .toLocaleLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    if (cmp.replace(" ", "").includes(val.replace(" ", ""))) return true;
    return false;
  });

  const updateDomain = (elmt) => {
    trackEvent("Domain selection", { props: { name: elmt.name } });
    setDomain(elmt.name);
    window.localStorage.setItem("domain", elmt.name);
    setESFDomain(isESFDomain(elmt.name));
    setIsOpen(false);
    setSelectedFilter({
      name: elmt.name,
      kind: "Sites",
      filters: {
        name: [],
        type: [],
        filter: [],
      },
    });
    updateAllPerfomancesData(
      {
        name: elmt.name,
        kind: "Sites",
        filters: {
          name: [],
          type: [],
          filter: [],
        },
      },
      datePeriod
    );
  };

  const onEnter = (key) => {
    if (!searched[0] || key !== "Enter") return;
    updateDomain(searched[0]);
  };

  return (
    <div className="admin-filter" ref={ref}>
      <Button
        className="admin-filter__button"
        onClick={() => {
          if (!isOpen) buttonRef.current.focus();
          setIsOpen(!isOpen);
        }}
        color="tertiary"
        variant="outlined"
        iconLeft={<Icons.User className="admin-filters__icon" />}
      >
        <span className="admin-filter__button__domain">
          {domain.length > 35 ? `${domain.slice(0, 30)}...` : domain}
        </span>
      </Button>
      <div className={classes}>
        <label
          className="admin-filters__searchbar"
          style={!searched[0] ? { border: "none" } : undefined}
        >
          <Icons.Search className="filter__button__icon" />
          <input
            ref={buttonRef}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="ex: meribel"
            onKeyDown={(e) => onEnter(e.key)}
            className="admin-filter__input"
          ></input>
        </label>
        <div className="filter-scroll">
          {searched.map((elmt) => {
            return (
              <button
                className="all-filters__choice"
                key={elmt.name + "choice"}
                onClick={() => updateDomain(elmt)}
              >
                {elmt.name}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdminFilters;
