import * as React from "react";
import * as Icons from "../../icons";
import classnames from "classnames";

const ComparativeLineName = ({
  value,
  charged,
  setCharged,
  loading,
  setLoading,
  firstTimeOpen,
  setFirstTimeOpen,
  startIncomeLoading,
  setStartIncomeLoading,
  onClick,
  updateSeoSeaIncomes,
}) => {
  const [startLoading, setStartLoading] = React.useState(0);
  const loadingCmp = value === "Affiliations" ? "Sites" : value;
  const isFilter =
    value !== "Affiliations" && value !== "Sites" && value !== "SEO / SEA";

  const backClasses = classnames("comparative-line__loader__back", {
    "comparative-line__loader__back--loading":
      charged.includes(loadingCmp) &&
      loading[loadingCmp] &&
      (startLoading === 1 || startIncomeLoading === 1),
    "comparative-line__loader__back--loading-end":
      charged.includes(loadingCmp) &&
      loading[loadingCmp] &&
      (startLoading === 2 || startIncomeLoading === 2),
  });
  const hourGlassClasses = classnames("comparative-line__loader__icon", {
    "comparative-line__loader__icon--loading":
      charged.includes(loadingCmp) && loading[loadingCmp],
  });
  const classes = classnames("comparative-line__cell__spe", {
    "comparative-line__cell__spe--small": isFilter,
    "comparative-line__cell__spe--big": value === "Affiliations",
  });

  const loadIncome = () => {
    if (charged.includes(value)) return;
    setStartLoading(1);
    setTimeout(() => setStartLoading(2), 1000);
    if (value === "SEO / SEA") {
      setLoading({ ...loading, "SEO / SEA": true, Sites: true });
      setCharged([...charged, value, "Sites"]);
      updateSeoSeaIncomes();
    }
    if (
      ((value === "Sites" || value === "Affiliations") &&
        firstTimeOpen === -1) ||
      value === "SEO / SEA"
    ) {
      setStartIncomeLoading(1);
      setTimeout(() => setStartIncomeLoading(2), 1000);
      setFirstTimeOpen(firstTimeOpen + 1);
      if (value !== "SEO / SEA") {
        setCharged([...charged, "Sites"]);
        setLoading({ ...loading, Sites: true });
      }
      return;
    }
    const addLoading = loading;
    addLoading[value] = true;
    setLoading({ ...addLoading });
    setCharged([...charged, value]);
    onClick();
  };

  return (
    <div className="comparative-line__row">
      <span className="comparative-line__cell__num">Rang</span>
      <span className={classes}>{value}</span>
      <span className="comparative-line__cell__vu"></span>
      {value !== "Affiliations" && (
        <span className="comparative-line__cell__tdc"></span>
      )}
      {(value === "Sites" || value === "SEO / SEA") && (
        <span className="comparative-line__cell__tdp"></span>
      )}
      <div className="comparative-line__loader">
        <span className="comparative-line__cell__nbr-command"></span>
        <span className="comparative-line__cell__ca"></span>
        {!(charged.includes(loadingCmp) && !loading[loadingCmp]) && (
          <button
            className="comparative-line__loader__button"
            onClick={loadIncome}
          >
            <div className={backClasses}>
              <Icons.HourGlass className={hourGlassClasses} />
            </div>
            Charger
          </button>
        )}
      </div>
    </div>
  );
};

export default ComparativeLineName;
