import { getShortUrl } from "./seo";
import { addSiteType, updateSiteType } from "../services/api";

const REGEX = /http[s]{0,1}:\/\/([A-Za-z0-9-.]+)/;

export const getSubDomain = (url) => {
  const site = REGEX.exec(url);
  if (site === null) return url;
  return site[1];
};

const findKind = (url, kinds, selection, newRank) => {
  let site = REGEX.exec(url);
  if (site === null) return false;
  site = site[1];
  let separator = site.indexOf(".");
  let found = kinds[selection].find((kind) => kind.url === site);

  while (separator >= 0 && !found) {
    site = site.slice(separator + 1);
    separator = site.indexOf(".");
    found = kinds[selection].find((kind) => kind.url === site);
  }
  if (found) {
    newRank.kind = found.kind;
    newRank.name = found.name;
    newRank.kindGroup = found.kindGroup;
    newRank.shortUrl = found.url;
    newRank.searchConsole = found.searchConsole;
    return true;
  }
  return false;
};

export const getSiteKind = (rank, kinds, seo) => {
  const newRank = {
    kind: rank.kind ? rank.kind : null,
    kindGroup: rank.kindGroup ? rank.kindGroup : null,
    shortUrl: rank.shortUrl ? rank.shortUrl : null,
    name: rank.name ? rank.name : null,
    searchConsole: rank.searchConsole ? rank.searchConsole : null,
  };

  if (findKind(rank.url, kinds, seo, newRank)) return newRank;
  findKind(rank.url, kinds, "all", newRank);
  return newRank;
};

export const addType = async (
  subDomain,
  choosenName,
  choosenKind,
  choosenKindName,
  choosenKindGroup,
  choosenKindGroupName,
  choosenIconUrl,
  iconUrl,
  seo,
  kinds,
  rank,
  setIsOpen,
  setChangeSettings,
  kindGroup,
  kind,
  setKinds,
  urls,
  setUrls
) => {
  const subDomainName = getSubDomain(rank.url);
  const domainName = getShortUrl(rank.url);
  const toFind = subDomain ? subDomainName : domainName;
  const body = {
    url: toFind,
    name: choosenName === "" ? undefined : choosenName,
    kind: choosenKind === "Créer un groupe" ? choosenKindName : choosenKind,
    kindGroup:
      choosenKindGroup === "Créer un type"
        ? choosenKindGroupName
        : choosenKindGroup,
    iconUrl:
      choosenIconUrl !== iconUrl && choosenIconUrl !== ""
        ? choosenIconUrl
        : undefined,
    seo,
  };

  const alreadyExist = kinds[seo].find((elmt) => elmt.url === toFind);
  if (!alreadyExist) {
    setKinds({
      ...kinds,
      [seo]: kinds[seo].concat([
        { ...body, name: choosenName === "" ? null : choosenName },
      ]),
    });
    await addSiteType(body);
  } else {
    const index = kinds[seo].findIndex((elmt) => elmt.url === toFind);
    const newKinds = kinds[seo]
      .slice(0, index)
      .concat(kinds[seo].slice(index + 1, kinds[seo].length));
    setKinds({
      ...kinds,
      [seo]: newKinds.concat([
        { ...body, name: choosenName === "" ? null : choosenName },
      ]),
    });
    await updateSiteType(body);
  }

  if (urls[seo][choosenKind] !== choosenIconUrl) {
    if (choosenKind === kind && choosenKindGroup === kindGroup)
      delete urls[seo][kind];
    const newUrls = { ...urls };
    if (choosenIconUrl !== "") newUrls[seo][body.kind] = choosenIconUrl;

    setUrls(newUrls);
  }
  setIsOpen(false);
  setChangeSettings(false);
};
