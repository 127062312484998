import * as React from "react";
import Card from "../../../common/card";

const SeoStats = ({ stats, device, data }) => {
  const colors = [
    "seo-ranks-stats__title__esf",
    "seo-ranks-stats__title__esf",
    "seo-ranks-stats__title__concurrent",
    "seo-ranks-stats__title__intermediaire",
    "seo-ranks-stats__title__ot",
  ];

  return (
    <div className="seo-ranks-stats">
      {stats &&
        data &&
        data.keywords &&
        data.keywords[0] &&
        stats[device].map((elmt, index) => {
          return (
            <Card
              key={elmt.name + "stats"}
              value={{ ...elmt, visitors: elmt.number, percentage: elmt.top }}
              style={{ color: "var(--color-front-light)" }}
              className={colors[index]}
              visibility={
                data && index < 2 && data.visibility
                  ? index === 0
                    ? data.visibility[`${device}Average`].esfNote
                    : data.visibility[`${device}Average`].esfFamilleNote
                  : undefined
              }
            />
          );
        })}
    </div>
  );
};

export default SeoStats;
