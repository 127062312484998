import * as React from "react";
import * as Icons from "../icons";
import classnames from "classnames";
import { updateOldAlert, addNewAlert } from "../../services/api";
import SettingsSelect from "../common/settings/select";
import Toast from "@valraiso-esf/esf-ui/es/toast";
import { useSettingsContext } from "../common/contexts/settings-context";
import { filterKeywords } from "../../utils/alerts";

const getFirstkeywordOfFilter = (alert, keywords, filter) => {
  if (alert.keyword) return alert.keyword;
  const keyword = keywords.find((obj) => obj.filter === filter);
  return keyword.keyword;
};

const SettingsEditAlertLine = ({
  alert,
  editable,
  className,
  index,
  setIsLoading,
}) => {
  const { alerts, setAlerts, mail, keywords, filters } = useSettingsContext();
  const [newFilter, setNewFilter] = React.useState(
    alert.filter ? alert.filter : filters[0]
  );
  const [newKeyword, setNewKeyword] = React.useState(
    getFirstkeywordOfFilter(alert, keywords, filters[0])
  );
  const [newDevice, setNewDevice] = React.useState(alert.device);
  const [errorMessage, setErrorMessage] = React.useState();
  const classes = classnames(
    "settings-alerts__array__line",
    className,
    "settings-alerts__array__line--edit"
  );

  const endEdition = async () => {
    setIsLoading(true);
    try {
      if (alert.new) {
        await addNewAlert({
          keyword: newKeyword,
          filter: newFilter,
          device: newDevice,
          mail: mail,
        });
      } else {
        await updateOldAlert({
          oldKeyword: alert.keyword,
          oldFilter: alert.filter,
          oldDevice: alert.device,
          keyword: newKeyword,
          filter: newFilter,
          device: newDevice,
          mail: mail,
        });
      }
      const newAlerts = [...alerts];
      newAlerts[index].edit = false;
      newAlerts[index].keyword = newKeyword;
      newAlerts[index].filter = newFilter;
      newAlerts[index].device = newDevice;
      newAlerts[index].new = undefined;
      setAlerts(newAlerts);
    } catch (e) {
      setErrorMessage("Mot clé non conforme / alerte déjà créée");
      setTimeout(() => setErrorMessage(undefined), 2500);
      const newAlerts = [...alerts];
      if (!alert.new) {
        newAlerts[index].edit = false;
      }
      setAlerts(newAlerts);
    }
    setIsLoading(false);
  };

  const stopEdition = () => {
    const newAlerts = [...alerts];
    if (alerts[index].new) {
      newAlerts.splice(index, 1);
    } else {
      newAlerts[index].edit = false;
    }
    setAlerts(newAlerts);
  };

  const updateFilter = (e) => {
    setNewFilter(e);
    const firstKeyword = keywords.find((keyword) => keyword.filter === e);
    setNewKeyword(firstKeyword.keyword);
  };

  const filteredKeywords = filterKeywords(keywords, newFilter);

  return (
    <div className={classes}>
      {errorMessage && (
        <Toast seconds={2.5} variant="error">
          {errorMessage}
        </Toast>
      )}
      <span className="settings-alerts__array__line__keyword">
        <SettingsSelect
          value={newKeyword}
          onChange={setNewKeyword}
          options={filteredKeywords}
        />
      </span>
      <span className="settings-alerts__array__line__filter">
        <SettingsSelect
          value={newFilter}
          onChange={updateFilter}
          options={filters}
        />
      </span>
      <div className="settings-alerts__array__line__others">
        <span className="settings-alerts__array__line__device">
          <SettingsSelect
            value={newDevice}
            onChange={setNewDevice}
            options={["ordinateur", "téléphone", "tout appareil"]}
          />
        </span>
        <div className="settings-alerts__array__line__actions">
          {editable && (
            <div className="settings-alerts__array__line__action__wrapper">
              <button
                className="settings-alerts__array__line__action"
                onClick={endEdition}
              >
                <Icons.Check />
              </button>
              <button
                className="settings-alerts__array__line__action"
                onClick={stopEdition}
              >
                <Icons.Close />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsEditAlertLine;
