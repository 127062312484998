import * as React from "react";

const AdsImpactLegend = ({ label, color }) => {
  return (
    <div className="seo-ads-impact__legend__line">
      <span
        className="seo-ads-impact__legend__line__color"
        style={{ backgroundColor: color }}
      />
      {label}
    </div>
  );
};

export default AdsImpactLegend;
