import * as React from "react";
import * as Icons from "../../../icons";
import Tooltip from "@valraiso-esf/esf-ui/es/tooltip";
import AdsBadges from "./ads-badge";
import GmapsBadge from "./gmaps-badge";
import GmbBadge from "./gmb-badge";
import PaaBadge from "./paa-badge";
import { findUrlSearchConsole } from "../../../../utils/seo";

const Stations = ({ data, device, kinds }) => {
  return (
    <div className="seo-ranks__keywords">
      <span className="seo-ranks__top__line">Stations</span>
      {data &&
        data.keywords &&
        data.keywords.map((keyword) => {
          if (
            !keyword[device] ||
            !keyword[device].ranks ||
            !keyword[device].ranks[0]
          ) {
            return null;
          }
          const badges = keyword.keyword;
          const ranks = keyword[device].ranks ? keyword[device].ranks : [];
          const gmb = ranks.filter((obj) => obj.type === 1)[0];
          const gmaps = ranks.filter((obj) => obj.type === 2)[0];
          const paa = ranks.filter((obj) => obj.type === 3)[0];
          const warning =
            gmb &&
            gmb.data &&
            ((gmb.data.rating !== undefined && Number(gmb.data.rating) < 3) ||
              (gmb.data.website &&
                findUrlSearchConsole(gmb.data.website, kinds) !==
                  keyword.searchConsole));

          return (
            <div
              key={"station" + keyword.stationName}
              className="seo-ranks__line seo-ranks__keywords__line seo-ranks__station__line"
              style={{ fontWeight: "bold" }}
            >
              {keyword.stationName}
              <div className="seo-ranks__badges--small">
                {window.innerWidth < 695 && badges && (
                  <div className="seo-ranks__line seo-ranks__badges__line">
                    {warning && (
                      <Tooltip
                        outerClassName="seo-ranks__badges__warning"
                        title="Google My Business noté moins de 3 étoiles / lien différent"
                        position="right"
                      >
                        <Icons.Warning />
                      </Tooltip>
                    )}
                    <PaaBadge value={paa} />
                    <GmapsBadge value={gmaps} />
                    <GmbBadge value={gmb} />
                    <AdsBadges
                      value={keyword[device].ads}
                      keyword={keyword.keyword}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Stations;
