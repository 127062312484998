import { ESF_NATIONAL } from "../data";
import { alphaSort } from "./sort";

export const getShortUrl = (url) => {
  try {
    const shortUrl = url.split("/")[2].split(".");
    const len = shortUrl.length;
    const newUrl = [shortUrl[len - 1]];
    let i = len - 2;

    for (; i >= 0 && shortUrl[i].length <= 3 && shortUrl[i] !== "esf"; i--) {
      newUrl.push(shortUrl[i]);
    }
    newUrl.push(shortUrl[i]);
    return newUrl
      .filter((elmt) => elmt)
      .reverse()
      .join(".");
  } catch (e) {
    return url;
  }
};

export const filterKindGroupAndKind = (kinds, choosenKindGroup) => {
  const kindGroupsPropositions = kinds
    ? alphaSort([
        ...new Set(
          kinds.map((elmt) => {
            return elmt.kindGroup;
          })
        ),
      ])
    : [""];

  const kindsPropositions = alphaSort(
    kinds.reduce((acc, kind) => {
      if (kind.kindGroup !== choosenKindGroup) return acc;
      const exist = acc.find((obj) => obj === kind.kind);
      if (exist) return acc;
      return [...acc, kind.kind];
    }, [])
  );
  return { kindGroupsPropositions, kindsPropositions };
};

export const isESFDomain = (domain, filtersData) => {
  if (!filtersData) return false;
  const exist = filtersData.find(
    (data) => data.kind === "Sites" && data.name === domain
  );
  if (!exist) return false;
  return exist.seo && exist.seo === 1;
};

export const hasESFAccess = (filtersData) => {
  if (!filtersData) return false;
  return filtersData.reduce((res, filter) => {
    if (
      (filter.kind === "Sites" && ESF_NATIONAL.includes(filter.name)) ||
      (filter.seo && filter.seo === 1)
    )
      return true;
    return res;
  }, false);
};

export const hasNationalESFAccess = (filtersData) => {
  if (!filtersData) return false;
  return filtersData.reduce((res, filter) => {
    if (filter.kind === "Sites" && ESF_NATIONAL.includes(filter.name))
      return true;
    return res;
  }, false);
};

export const findUrlSearchConsole = (url, kinds) => {
  const shortUrl = getShortUrl(url);
  const exist = kinds.find((kind) => kind.url === shortUrl);
  if (!exist) return false;
  return exist.searchConsole;
};
