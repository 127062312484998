import * as React from "react";
import { getSpecificities } from "../services/api";

const useGetAllFilters = (domain, perm, setFiltersData, setFilters, setSeo) => {
  React.useEffect(() => {
    const getFilters = async () => {
      const response = await getSpecificities(perm ? "admin" : domain);
      setFiltersData(response);
      const siteFilter = response.filter(
        (elmt) =>
          (!perm || elmt.domain_name === domain) && elmt.kind === "Sites"
      );
      const domainFilter = response.filter(
        (elmt) =>
          (!perm || elmt.domain_name === domain) &&
          elmt.kind === "Domaines skiable"
      );
      const massifFilter = response.filter(
        (elmt) =>
          (!perm || elmt.domain_name === domain) && elmt.kind === "Massif"
      );
      const regionFilter = response.filter(
        (elmt) =>
          (!perm || elmt.domain_name === domain) && elmt.kind === "Région"
      );
      const otherFilter = response.filter(
        (elmt) =>
          (!perm || elmt.domain_name === domain) &&
          elmt.kind !== "Région" &&
          elmt.kind !== "Massif" &&
          elmt.kind !== "Domaines skiable" &&
          elmt.kind !== "Sites"
      );
      const newFilters = [
        ...siteFilter,
        ...domainFilter,
        ...massifFilter,
        ...regionFilter,
        ...otherFilter,
      ];
      setFilters(newFilters);
      setSeo(newFilters[0].seo);
    };
    getFilters();
  }, [domain, perm, setFilters, setFiltersData, setSeo]);
};

export default useGetAllFilters;
