import * as React from "react";
import classnames from "classnames";
import SimpleArray from "./array";

const DetailPage = ({ paymentMethods, affiliation }) => {
  const [selected, setSelected] = React.useState("Affiliations");
  const details = [
    { name: "Affiliations", array: affiliation },
    { name: "Méthodes de paiement", array: paymentMethods },
  ];
  const array = details.find((detail) => detail.name === selected).array;

  return (
    <div className="sale-tunnel-origin-pages">
      <div className="sale-tunnel-origin-pages__buttons">
        {details.map((detail) => {
          const classes = classnames("sale-tunnel-origin-pages__button", {
            "sale-tunnel-origin-pages__button--on": selected === detail.name,
          });
          return (
            <button
              key={detail.name + "detail success"}
              className={classes}
              onClick={() => setSelected(detail.name)}
            >
              <span>{detail.name}</span>
            </button>
          );
        })}
      </div>
      <div className="sale-tunnel-origin-pages__content">
        <SimpleArray array={array} />
      </div>
    </div>
  );
};

export default DetailPage;
