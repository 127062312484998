import * as React from "react";
import Ranks from "./ranks/ranks";
import classnames from "classnames";
import { getHistoricalSeoData } from "../../../services/api";
import SeoStats from "./ranks/stats";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
import { useSEOContext } from "../../common/contexts/seo-context";
import { sortSeo } from "../../../utils/sort";

const SelectDate = ({ domain, dates, selectedFilter, device, perm, seo }) => {
  const {
    historicalData,
    setHistoricalData,
    historicalStats,
    setHistoricalStats,
    date,
    setDate,
    filters,
    selectedSort,
  } = useSEOContext();
  const [isLoading, setIsLoading] = React.useState(false);

  const getHistoricalData = async (_date) => {
    setIsLoading(true);
    try {
      const response = await getHistoricalSeoData({ date: _date }, domain);
      if (!response.data) {
        setHistoricalData(undefined);
        setIsLoading(false);
        return;
      }
      sortSeo(
        selectedSort,
        {
          keywords: response.data,
          visibility: response.visibility,
        },
        setHistoricalData,
        filters
      );
      setHistoricalStats(response.stats);
      setIsLoading(false);
      setTimeout(
        () =>
          document
            .getElementById("seo-historic")
            .scrollIntoView({ behavior: "smooth" }),
        500
      );
    } catch (e) {
      setIsLoading(false);
    }
  };

  const changeValue = (e) => {
    const index = dates.findIndex((elmt) => elmt === e.target.value);
    setDate(dates[index]);
    if (dates[index] === "") {
      setHistoricalData(undefined);
    } else {
      getHistoricalData(dates[index]);
    }
  };

  const classes = classnames("seo-select-date__label", {
    "seo-select-date__label--off": date === "",
    "seo-select-date__label--on": date !== "",
  });

  return (
    <>
      <label className={classes} label="Date de comparaison" title="Comparer">
        <select
          label="Date de comparaison"
          value={date}
          onChange={changeValue}
          className="seo-select-date"
        >
          {dates.map((elmt, index) => {
            return <option key={elmt + index + "date choice"}>{elmt}</option>;
          })}
        </select>
      </label>
      {isLoading && (
        <div className="seo-select-date__loader-bar">
          <LoaderBar className="loaderbar-valraiso-background" />
        </div>
      )}
      {historicalData && (
        <div className="seo seo__historic" id="seo-historic">
          <div className="seo__top">{date}</div>
          {historicalStats && historicalStats[selectedFilter] && (
            <SeoStats
              stats={historicalStats[selectedFilter]}
              device={device}
              data={{
                ...historicalData,
                keywords: historicalData.keywords[selectedFilter],
                visibility: historicalData.visibility[selectedFilter],
              }}
            />
          )}
          <Ranks
            data={{
              ...historicalData,
              keywords: historicalData.keywords[selectedFilter],
              visibility: historicalData.visibility
                ? historicalData.visibility[selectedFilter]
                : undefined,
            }}
            device={device}
            perm={perm}
            seo={seo}
          />
        </div>
      )}
    </>
  );
};

export default SelectDate;
