import * as React from "react";
import { login, domainAcceptableForToken } from "../services/api";
import { isESFDomain } from "../utils/seo";

const useConnection = (
  FIRST_PATH,
  setLoggedIn,
  setPerm,
  setDomain,
  setAuth,
  setESFDomain
) => {
  React.useEffect(() => {
    const checkLogin = async () => {
      if (FIRST_PATH === "/client") {
        window.localStorage.removeItem("password");
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("auth");
        return;
      }
      const storedPassword = window.localStorage.getItem("password");
      const storedDomain = window.localStorage.getItem("domain");
      if (storedPassword) {
        const response = await login(storedPassword, undefined);
        if (response.success) {
          setLoggedIn(true);
          setPerm(3);
          window.localStorage.removeItem("auth");
          window.localStorage.removeItem("token");
          if (storedDomain) setDomain(storedDomain);
          return;
        }
      }
      const storedToken = window.localStorage.getItem("token");
      if (storedToken) {
        const response = await login(undefined, undefined, storedToken);
        if (response.success) {
          setPerm(response.success);
          setDomain(response.domain);
          setESFDomain(isESFDomain(response.domain));
          setLoggedIn(true);
          window.localStorage.removeItem("auth");
          if (storedDomain) {
            const accepted = await domainAcceptableForToken(storedDomain);
            if (accepted) setDomain(storedDomain);
            else window.localStorage.setItem("domain", response.domain);
          } else {
            window.localStorage.setItem("domain", response.domain);
          }
          return;
        }
      }
      const storedAuth = window.localStorage.getItem("auth");
      if (storedAuth) {
        const response = await login(undefined, storedAuth);
        if (response.success) {
          setLoggedIn(true);
          setDomain(response.success);
          window.localStorage.setItem("domain", response.success);
          setESFDomain(isESFDomain(response.success));
        }
        setAuth(storedAuth);
      }
    };
    checkLogin();
  }, [FIRST_PATH, setAuth, setDomain, setLoggedIn, setPerm, setESFDomain]);
};

export default useConnection;
