import * as React from "react";
import Popup from "../../common/popup";
import { XAxis, YAxis, CartesianGrid, Tooltip, BarChart, Bar } from "recharts";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
import CustomTooltip from "./custom-tooltip";
import ExportButton from "../../common/export";
import { saveAdsChartAsCSV } from "../../../utils/saveAsCSV";

const DetailPopup = ({ setPopup, values, data, isLoading }) => {
  const buttons = [{ message: "FERMER", action: () => setPopup(false) }];
  const downloadFile = saveAdsChartAsCSV(data);

  return (
    <div className="settings-popup__back">
      <Popup setIsOpen={() => setPopup(false)} buttons={buttons}>
        {isLoading ? (
          <LoaderBar className="loaderbar-valraiso-background" />
        ) : (
          <>
            <div className="top-title">
              <span
                className="date-comparison__title"
                style={{ fontSize: "17px" }}
              >
                {data.length !== 0
                  ? `Présence de ${values.name} sur ${values.station} ces 60 derniersjours`
                  : `Aucune publicité présente sur les 60 derniers jours de ${values.station}`}
              </span>
              {data.length !== 0 && (
                <ExportButton downloadFile={downloadFile} />
              )}
            </div>
            {data.length !== 0 && (
              <BarChart
                className="seo-study-chart"
                width={(window.innerWidth * 70) / 100}
                height={(window.innerWidth * 30) / 100}
                data={data}
                syncId="anyId"
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  type="monotone"
                  dataKey="number"
                  stroke="var(--color-valraiso)"
                  fill="var(--color-valraiso)"
                />
              </BarChart>
            )}
          </>
        )}
      </Popup>
    </div>
  );
};

export default DetailPopup;
