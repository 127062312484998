import * as React from "react";
import { useNationalSEOContext } from "../../../common/contexts/national-seo-context";
import Badges from "./badges";
import Ranking from "./ranking";
import Stations from "./stations";

const NationalRanks = ({ data, device, perm }) => {
  const { kinds, setKinds, urls, setUrls } = useNationalSEOContext();

  return (
    <div className="seo-ranks">
      <Badges data={data} device={device} kinds={kinds["1"]} />
      <Stations data={data} device={device} kinds={kinds["1"]} />
      <Ranking
        data={data}
        device={device}
        kinds={kinds}
        setKinds={setKinds}
        perm={perm}
        urls={urls}
        setUrls={setUrls}
        seo={1}
      />
    </div>
  );
};

export default NationalRanks;
