import * as React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  Area,
} from "recharts";
import { useSEOAdsImpactContext } from "../common/contexts/seo-ads-impact";
import CustomTooltip from "./tooltip";
import { salesColors } from "../../data";

const AdsImpactChartSales = () => {
  const { data, breakdown, maxSales } = useSEOAdsImpactContext();

  return (
    <ComposedChart
      width={(window.innerWidth * 70) / 100}
      height={(window.innerWidth * 15) / 100}
      data={data}
      syncId="anyId"
      margin={{
        top: 10,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis
        yAxisId="leftArea"
        dataKey="sales"
        orientation="left"
        stroke="var(--color-front-light)"
        domain={[0, maxSales]}
        label={{
          value: "Ventes",
          angle: -90,
          position: "insideLeft",
          fill: "var(--color-front-light)",
        }}
      />
      <YAxis
        yAxisId="rightArea"
        dataKey="sales"
        orientation="right"
        stroke="var(--color-front-light)"
        domain={[0, maxSales]}
        label={{
          value: "Ventes",
          angle: 90,
          position: "insideRight",
          fill: "var(--color-front-light)",
        }}
      />
      <Tooltip
        content={<CustomTooltip adwords={false} breakdown={breakdown} />}
      />
      <defs>
        {breakdown.map((detail) => {
          return (
            <linearGradient
              key={"linear" + `color${detail}`}
              id={`color${detail}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="5%"
                stopColor={salesColors[detail]}
                stopOpacity={0.8}
              />
              <stop
                offset="100%"
                stopColor={salesColors[detail]}
                stopOpacity={0}
              />
            </linearGradient>
          );
        })}
      </defs>
      {breakdown.map((detail) => {
        return (
          <Area
            type="monotone"
            key={"area with detail" + detail}
            yAxisId="leftArea"
            dataKey={detail}
            stackId="area1"
            stroke={salesColors[detail]}
            fill={`url(#color${detail})`}
            fillOpacity={1}
          />
        );
      })}
    </ComposedChart>
  );
};

export default AdsImpactChartSales;
