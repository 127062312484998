import * as React from "react";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
import AdsImpactChart from "./chart";
import AdsImpactSelection from "./controlers";
import AdsImpactLegend from "./legend";
import { useSEOAdsImpactContext } from "../common/contexts/seo-ads-impact";
import useSetPage from "../../hooks/use-set-page";

const AdsImpact = ({ setPage }) => {
  const { groupDevices, isLoading, data } = useSEOAdsImpactContext();
  const legend = [{ label: "Visiteurs", color: "#1a73e8" }];
  if (!groupDevices) {
    legend.push({ label: "Adwords ordinateur", color: "#fdd868" });
    legend.push({ label: "Adwords téléphone", color: "#fbbc04" });
  } else {
    legend.push({ label: "Adwords", color: "#fbbc04" });
  }
  useSetPage(setPage, "Impact des adwords");

  return (
    <div className="seo-ads-impact">
      <AdsImpactSelection />
      {isLoading && <LoaderBar className="loaderbar-valraiso-background" />}
      <AdsImpactChart />
      {data.length !== 0 && (
        <div className="seo-ads-impact__legend">
          {legend.map((line) => {
            return (
              <AdsImpactLegend
                key={line.label + "legend"}
                color={line.color}
                label={line.label}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AdsImpact;
