import * as React from "react";
import Popup from "../../../../common/popup";
import { getShortUrl } from "../../../../../utils/seo";
import * as Icons from "../../../../icons";

const RankInfosPopup = ({
  rank,
  coords,
  setIsOpen,
  perm,
  setChangeSettings,
  keyword,
}) => {
  return (
    <Popup
      className="seo-ranks__ranking__popup seo-popup-rank-infos--left"
      style={coords}
      buttons={[]}
      setIsOpen={setIsOpen}
    >
      {perm === 3 && (
        <button
          className="seo-popup__settings"
          onClick={() => {
            setChangeSettings(true);
          }}
        >
          <Icons.Settings className="seo-popup__close__icon" />
        </button>
      )}
      <div className="seo-popup-rank-infos--flex">
        <span className="seo-popup-keyword__title">
          {rank.shortUrl === null ? getShortUrl(rank.url) : rank.shortUrl}
          <br />
          {rank.shortUrl !== null &&
            rank.kind !== rank.kindGroup &&
            (rank.kindGroup === "Concurrent" ||
              rank.kindGroup === "Intermediaire") &&
            rank.kind}
        </span>
        Position SEO : {rank.rank}
        <br />
        Position dans la page : {rank.data.pixel} pixels
        <br />
        <a href={rank.url} className="seo-popup-rank-infos--urls">
          {rank.url}
        </a>
        <br />
        {rank.data.siteLinks[0] && (
          <span className=" seo-popup-rank-infos--flex">
            Liens du site :
            <br />
            {rank.data.siteLinks.map((elmt) => {
              return (
                <span
                  key={"infos" + keyword.keyword + elmt.url + elmt.rank + 1}
                >
                  {elmt.rank}:{" "}
                  <a href={elmt.url} className="seo-popup-rank-infos--urls">
                    {elmt.url}
                  </a>
                  <br />
                  <br />
                </span>
              );
            })}
          </span>
        )}
      </div>
    </Popup>
  );
};

export default RankInfosPopup;
