import * as React from "react";

const SaleTunnelTop = () => {
  return (
    <div className="date-comparison__top">
      <span className="date-comparison__title">Tunnel de vente</span>
    </div>
  );
};

export default SaleTunnelTop;
