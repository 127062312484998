import * as React from "react";
import * as Icons from "../../../icons";
import KeywordPopup from "./popups/keyword";
import AdsBadges from "./ads-badge";
import GmapsBadge from "./gmaps-badge";
import GmbBadge from "./gmb-badge";
import PaaBadge from "./paa-badge";
import { findUrlSearchConsole } from "../../../../utils/seo";
import Tooltip from "@valraiso-esf/esf-ui/es/tooltip";

const KeywordButton = ({ elmt, visibility, device, kinds, biggestVolume }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef("keyword");

  const onClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", onClickOutside);
    return () => {
      document.body.removeEventListener("click", onClickOutside);
    };
  }, []);

  const badges = elmt.keyword;

  const ranks = elmt[device].ranks ? elmt[device].ranks : [];
  const gmb = ranks.filter((obj) => obj.type === 1)[0];
  const gmaps = ranks.filter((obj) => obj.type === 2)[0];
  const paa = ranks.filter((obj) => obj.type === 3)[0];
  const warning =
    gmb &&
    gmb.data &&
    ((gmb.data.rating !== undefined && Number(gmb.data.rating) < 3) ||
      (gmb.data.website &&
        findUrlSearchConsole(gmb.data.website, kinds) !== elmt.searchConsole));

  const size =
    elmt.volume === 0
      ? 0
      : Math.pow(2, Math.log10(elmt.volume / biggestVolume)) * 90;

  return (
    <div className="seo-ranks__line seo-ranks__keywords__line" ref={ref}>
      <span
        className="seo-ranks__keywords__line__volume"
        style={{
          width: `calc(${size}%)`,
        }}
      />
      <button
        className="seo-ranks__keywords__keyword"
        onClick={() => setIsOpen(elmt.keyword)}
      >
        {elmt.keyword ? elmt.keyword.replace(` ${elmt.searchConsole}`, "") : ""}
      </button>
      <div className="seo-ranks__badges--small">
        {window.innerWidth < 695 && badges && (
          <div className="seo-ranks__line seo-ranks__badges__line">
            {warning && (
              <Tooltip
                outerClassName="seo-ranks__badges__warning"
                title="Google My Business noté moins de 3 étoiles / lien différent"
                position="right"
              >
                <Icons.Warning />
              </Tooltip>
            )}
            <PaaBadge value={paa} />
            <GmapsBadge value={gmaps} />
            <GmbBadge value={gmb} />
            <AdsBadges value={elmt[device].ads} keyword={elmt.keyword} />
          </div>
        )}
      </div>
      {isOpen === elmt.keyword && (
        <KeywordPopup
          coords={{ left: "100%" }}
          setIsOpen={setIsOpen}
          value={elmt}
          visibility={visibility}
        />
      )}
    </div>
  );
};

export default KeywordButton;
