import { getOldData } from "../services/api";
import { toDateString } from "./dates";
import { subYears } from "date-fns";
import { filterSourcesAndAffiliations } from "./seo-sea";
import { getDataStats } from "../components/performances/date-comparison/date-comparison";

const getMetrics = (value) => {
  const { visitors, events, bounce_rate, visit_duration } = value.name;
  return [{ visitors, events, bounce_rate, visit_duration }];
};

const getTunnel = (value) => {
  return {
    data: value.data,
    affiliation: value.name.affiliation,
    execCodes: value.execCodes,
    products: value.products,
  };
};

const filterCopy = (array, selectedDomain) => {
  const data = array.domains.find((domain) => domain.name === selectedDomain);
  return {
    comparative: data.data,
    domains: [data],
  };
};

const switchToDomain = async (
  _selectedFilter,
  _datePeriod,
  value,
  analysisContext,
  trackEvent
) => {
  const {
    setGlobalLoading,
    setSaleTunnelDetailWasOpen,
    setSaleTunnelDetailOpen,
    setOrderLoading,
    setAbortIncomes,
    setSelectedProduct,
    setApiOff,
    setDomainsData,
    setDevices,
    setCountry,
    setSources,
    devices,
    country,
    sources,
    setAffiliations,
    setSeoSeaData,
    setValues,
    setValuesComparison,
    setTunnel,
    setDomainsIncomes,
    comparisonPeriod,
  } = analysisContext;
  const metrics = getMetrics(value);
  const tunnel = getTunnel(value);

  setGlobalLoading(true);
  const body = {
    kind: _selectedFilter.kind,
    name: _selectedFilter.name,
    dateTo: _datePeriod.end,
    dateFrom: _datePeriod.start,
    filters: _selectedFilter.filters.filter[0]
      ? _selectedFilter.filters.filter
      : undefined,
  };
  trackEvent("Analysis context", { props: { context: JSON.stringify(body) } });
  setSaleTunnelDetailWasOpen("");
  setSaleTunnelDetailOpen(false);
  setOrderLoading(false);
  setAbortIncomes(false);
  setSelectedProduct("Tout produit");
  const [response] = await Promise.all([
    getOldData({
      ...body,
      dateTo: toDateString(
        subYears(new Date(_datePeriod.end), comparisonPeriod.end)
      ),
      dateFrom: toDateString(
        subYears(new Date(_datePeriod.start), comparisonPeriod.start)
      ),
    }),
  ]).catch(() => {
    setApiOff(true);
    setGlobalLoading(false);
  });
  setDomainsData(metrics);
  setDevices(filterCopy(devices, _selectedFilter.name));
  setCountry(filterCopy(country, _selectedFilter.name));
  const dataSource = filterCopy(sources, _selectedFilter.name);
  setSources(dataSource);
  setAffiliations(tunnel.affiliation);
  setSeoSeaData(filterSourcesAndAffiliations(dataSource, tunnel));
  const dateComparativeRes = getDataStats(metrics, response);
  setValues(dateComparativeRes);
  const shouldCompare = dateComparativeRes[0].old !== 0 ? true : false;
  if (!shouldCompare) {
    setTimeout(() => {
      setValuesComparison(false);
    }, 500);
  }
  setTunnel(tunnel);
  setDomainsIncomes([value]);
  setApiOff(false);
  setGlobalLoading(false);
};

export default switchToDomain;
