import * as React from "react";
import useSetPage from "../../hooks/use-set-page";
import NationalSEOContextProvider from "../common/contexts/national-seo-context";
import NationalSeoOservatory from "./detail/national-index";

const NationalSeo = ({ perm, setPage }) => {
  useSetPage(setPage, "Référencement national");

  return (
    <NationalSEOContextProvider>
      <NationalSeoOservatory perm={perm} />
    </NationalSEOContextProvider>
  );
};

export default NationalSeo;
