import * as React from "react";
import { getAuthLink } from "../services/api";

const useGetAuthLink = (domain, setMyAuth, setIsLoading, auth, myAuth) => {
  React.useEffect(() => {
    const exec = async () => {
      const resp = await getAuthLink(domain);
      setMyAuth(resp.auth);
    };
    if (!auth) {
      setIsLoading(true);
      exec();
      setIsLoading(false);
    }
  }, [auth, domain, myAuth, setIsLoading, setMyAuth]);
};

export default useGetAuthLink;
