import classnames from "classnames";
import incomeFormat from "../../../utils/incomeFormat";
import { divNumber } from "../../../utils/reduce";

const ComparativeLineSites = ({
  value,
  index,
  values,
  onClick,
  total,
  sites,
  product,
}) => {
  const classes = classnames("comparative-line__cell__spe", {
    "comparative-line__cell__spe__clickable": onClick,
    "comparative-line__cell__spe--big": !sites,
  });
  const lineClasses = classnames("comparative-line__row", {
    "comparative-line__row--odd": Number(index.replace("#", "")) % 2 === 1,
  });
  const visitors = sites ? value.name.visitors : value.visitors;
  const productIndex = sites
    ? value.products.findIndex((_product) => _product === product)
    : -1;
  const price = sites
    ? productIndex === -1
      ? 0
      : value.data[productIndex][3].price
    : value.price;
  const number = sites
    ? productIndex === -1
      ? 0
      : value.data[productIndex][3].number
    : value.number;
  const conversion =
    sites && productIndex !== -1 ? value.name.conversion[productIndex] : {};

  return (
    <div className={lineClasses}>
      <span className="comparative-line__cell__num">{index}</span>
      <span className={classes} onClick={onClick && onClick}>
        {sites ? value.name.domain_name : value.name}
      </span>
      <span
        className="comparative-line__cell__vu"
        percentage={
          values && values[0].new
            ? ` (${divNumber(visitors, values[0].new)}%)`
            : ""
        }
      >
        {visitors}
      </span>
      {sites && (
        <>
          <span className="comparative-line__cell__tdc">{`${conversion.tdC}%`}</span>
          <span
            className="comparative-line__cell__tdp comparative-line__cell__tdp__data"
            success={`${conversion.tdS}%`}
            failure={`${conversion.tdF}%`}
          >
            {" "}
            /{" "}
          </span>
        </>
      )}
      <span className="comparative-line__cell__nbr-command">{number}</span>
      <span
        className="comparative-line__cell__ca"
        percentage={`(${divNumber(price, total)}%)`}
      >
        {incomeFormat(price)}
      </span>
    </div>
  );
};

export default ComparativeLineSites;
