import * as React from "react";
import { DateRangePicker } from "react-date-range";

const CustomPeriod = ({ state, setState, className }) => {
  const updatePeriod = (item) => {
    setState([item.selection]);
  };

  return (
    <div className={className}>
      <DateRangePicker
        className="date-range"
        onChange={(item) => updatePeriod(item)}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        maxDate={new Date()}
        direction="horizontal"
        staticRanges={[]}
        editableDateInputs={true}
        inputRanges={[]}
      />
    </div>
  );
};

export default CustomPeriod;
