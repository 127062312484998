export const getSubDomain = (url) => {
  const subDomainRegex = /\/\/[a-zA-Z0-9.-]+/;
  const res = subDomainRegex.exec(url);
  if (res == null) return url;
  return res[0].slice(2);
};

export const isAlpeDhuez = (url) => {
  const subDomain = getSubDomain(url);
  if (
    subDomain === "reservation.alpedhuez.com" ||
    subDomain === "accommodation.alpedhuez.com"
  )
    return true;
  return false;
};
