import * as React from "react";

const useSetPage = (setPage, name) => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    setPage(name);
  }, [setPage, name]);
};

export default useSetPage;
