import * as React from "react";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
import useSetPage from "../../hooks/use-set-page";
import useGetAuthLink from "../../hooks/use-get-auth-link";

const Plausible = ({ auth, domain, setPage }) => {
  const [myAuth, setMyAuth] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  useSetPage(setPage, "Données Plausible");
  useGetAuthLink(domain, setMyAuth, setIsLoading, auth, myAuth);

  if (!auth && !myAuth) return null;

  return (
    <>
      {isLoading ? (
        <LoaderBar className="loaderbar-valraiso-background" />
      ) : (
        <div className="plausible-dashboard">
          <iframe
            plausible-embed="true"
            src={`https://plausible.io/share/${domain}?auth=${
              myAuth
                ? myAuth
                : auth.length >= 23 && auth.startsWith("ng")
                ? auth.slice(2)
                : auth
            }&embed=true&theme=system&background=transparent`}
            frameBorder="0"
            loading="lazy"
            title="Plausible"
          ></iframe>
          <script async src="https://plausible.io/js/embed.host.js"></script>
        </div>
      )}
    </>
  );
};

export default Plausible;
