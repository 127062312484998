import classnames from "classnames";
import * as Icons from "../../icons";

const Checkbox = ({
  label,
  className,
  onChange,
  onValueChange,
  value,
  disabled,
  required,
  checked,
  inputProps,
}) => {
  const _onChange = (e) => {
    onChange?.(e);
    onValueChange?.(e.target.value);
  };

  const classes = classnames("checkbox", {
    [className]: className,
  });

  return (
    <label className={classes}>
      <input
        type="checkbox"
        className="checkbox__input"
        onChange={_onChange}
        value={value}
        disabled={disabled}
        required={required}
        checked={checked}
        {...inputProps}
      />
      <div className="checkbox__div">
        <Icons.CheckCheckbox className="checkbox__check" />
      </div>
      <span className="checkbox__label">{label}</span>
    </label>
  );
};

export default Checkbox;
