import * as React from "react";
import KeywordButton from "./keyword";

const Keywords = ({ data, device, kinds }) => {
  const biggestVolume =
    data &&
    data.keywords &&
    data.keywords[0] &&
    data.keywords.reduce(
      (max, keyword) => (max < keyword.volume ? keyword.volume : max),
      1
    );
  return (
    <div className="seo-ranks__keywords">
      <span className="seo-ranks__top__line">Mots clés</span>
      {data &&
        data.keywords &&
        data.keywords[0] &&
        data.keywords.map((elmt, index) => {
          if (!elmt.keyword) {
            return null;
          }
          return (
            <KeywordButton
              key={elmt.keyword + elmt + "keywords"}
              elmt={elmt}
              visibility={
                data.visibility ? data.visibility[device][index] : undefined
              }
              device={device}
              kinds={kinds}
              biggestVolume={biggestVolume}
            />
          );
        })}
    </div>
  );
};

export default Keywords;
