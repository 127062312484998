import * as React from "react";
import { removeOldAlert } from "../../services/api";
import { useSettingsContext } from "../common/contexts/settings-context";
import Popup from "../common/popup";

const RemovePopup = () => {
  const {
    removePopup: values,
    setRemovePopup,
    mail,
    alerts,
    setAlerts,
  } = useSettingsContext();
  const removeAlert = () => {
    removeOldAlert({ ...values, mail });
    setAlerts(
      alerts.filter(
        (alert) =>
          !(
            alert.keyword === values.keyword &&
            alert.filter === values.filter &&
            alert.device === values.device
          )
      )
    );
    setRemovePopup(undefined);
  };

  const buttons = [
    { message: "ANNULER", action: () => setRemovePopup(undefined) },
    { message: "SUPPRIMER", action: removeAlert },
  ];

  return (
    <div className="settings-popup__back">
      <Popup setIsOpen={() => setRemovePopup(undefined)} buttons={buttons}>
        <span>
          <b>Êtes-vous sûr de vouloir supprimer l'alerte</b>
          <br />
          <br />
          {values.keyword} ({values.filter})
          <br />
          <br />
        </span>
      </Popup>
    </div>
  );
};

export default RemovePopup;
