import React from "react";

const CustomTooltip = ({ active, payload, adwords, breakdown }) => {
  if (!active || !payload || !payload[0]) return null;

  const sales = !payload?.[0].payload.sales ? "0" : payload?.[0].payload.sales;

  return (
    <div className="seo-ads-chart__tooltip">
      <div className="seo-ads-chart__tooltip__top">
        <b>infos:</b>
        <span>{payload?.[0].payload.date}</span>
      </div>
      {adwords && "adwords: " + payload?.[0].payload.all}
      {!adwords && "ventes: " + sales}
      {!adwords &&
        breakdown.map((detail) => {
          return (
            <span key={"tooltip" + detail} style={{ color: "grey" }}>
              {detail}: {payload?.[0].payload[detail]}
            </span>
          );
        })}
      <br />
      {adwords && "visiteurs: " + payload?.[0].payload.visitors}
    </div>
  );
};

export default CustomTooltip;
