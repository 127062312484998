import * as React from "react";
import classnames from "classnames";

const SettingsSelect = ({ className, value, onChange, options }) => {
  const classes = classnames("settings-select", className);

  return (
    <select
      label="Date de comparaison"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={classes}
    >
      {options.map((elmt, index) => {
        return <option key={elmt + index + "date choice"}>{elmt}</option>;
      })}
    </select>
  );
};

export default SettingsSelect;
