import * as React from "react";
import * as Icons from "../icons";
import Input from "@valraiso-esf/esf-ui/es/input";
import Button from "@valraiso-esf/esf-ui/es/button";
import { login } from "../../services/api";
import { Navigate } from "react-router-dom";
import { FIRST_PATH } from "../app";
import { useAppContext } from "../common/contexts/app-context";

const Login = () => {
  const { loggedIn, setLoggedIn, setPerm } = useAppContext();
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState();
  if (loggedIn) {
    if (FIRST_PATH === "/" || FIRST_PATH === "/statistics/analysis")
      return <Navigate to="/statistics/plausible" />;
    return <Navigate to={FIRST_PATH} />;
  }

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const response = await login(password, undefined);
      if (response.success) {
        setLoggedIn(true);
        window.localStorage.setItem("password", password);
        setPerm(3);
      } else {
        setError(response.error.message);
      }
    } catch (e) {
      setError(e.message);
    }
  };

  const updatePassword = (e) => {
    const { value } = e.target;
    setPassword(value);
    if (!value.trim()) {
      setError("Le mot de passe ne peut pas être vide");
    }
  };

  return (
    <div className="login">
      <form className="login__inner" onSubmit={submitForm}>
        <div className="login__header">
          <Icons.PeekPerformances className="login--peekperformances" />
        </div>
        <Input
          type="password"
          name="password"
          label="Mot de passe :"
          value={password}
          onChange={updatePassword}
          error={error}
          required
        />
        <Button type="submit" className="button-valraiso-background">
          Connexion
        </Button>
      </form>
    </div>
  );
};

export default Login;
