import * as React from "react";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
import Array from "../common/array";
import AddNewActorPopup from "./popups/add-popup";
import DetailPopup from "./popups/detail-popup";
import CompetitionStudyFilters from "./index-filters";
import useSetPage from "../../hooks/use-set-page";
import { useSEOStudyContext } from "../common/contexts/seo-study-context";
import { getStationHirtoricalAds } from "../../services/api";

const CompetitionStudy = ({ setPage }) => {
  const { competitor, clients, isLoading, data, setData, selectedFilter } =
    useSEOStudyContext();
  const [device, setDevice] = React.useState("Ordinateur");
  const [popup, setPopup] = React.useState(undefined);
  const [values, setValues] = React.useState({
    ...competitor,
    station: "",
    filter: "fr",
    device: "desktop",
  });
  const [adsIsLoading, setAdsIsLoading] = React.useState(true);
  const [adsData, setAdsData] = React.useState([]);

  useSetPage(setPage, "Étude de la concurrence");

  const englishDevice = device === "Ordinateur" ? "desktop" : "mobile";

  return (
    <div className="seo-study" id="national-study">
      {!clients[0] && <LoaderBar className="loaderbar-valraiso-background" />}
      {clients[0] && (
        <>
          <CompetitionStudyFilters
            competitor={competitor}
            device={device}
            setDevice={setDevice}
            data={data[englishDevice]}
            setPopup={setPopup}
            clients={clients}
          />
          {!competitor ? null : isLoading ? (
            <LoaderBar className="loaderbar-valraiso-background" />
          ) : (
            <>
              <Array
                values={data[englishDevice]}
                setValues={(e) => setData({ ...data, [englishDevice]: e })}
                className="seo-study__array"
                onDetailClick={async (line) => {
                  setValues({
                    ...competitor,
                    station: line[0].value,
                    filter: selectedFilter,
                    device: englishDevice,
                  });
                  setPopup("detail");
                  setAdsIsLoading(true);
                  const chartData = await getStationHirtoricalAds({
                    ...competitor,
                    station: line[0].value,
                    filter: selectedFilter,
                    device: englishDevice,
                  });
                  setAdsData(chartData);
                  setAdsIsLoading(false);
                }}
              />
              {!data[englishDevice][0]
                ? "Ce concurrent est inactif d'après les derniers relevés"
                : ""}
            </>
          )}
          {popup === "add" && <AddNewActorPopup setPopup={setPopup} />}
          {popup === "detail" && (
            <DetailPopup
              setPopup={setPopup}
              values={values}
              data={adsData}
              isLoading={adsIsLoading}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CompetitionStudy;
