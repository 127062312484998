import * as Icons from "../../../icons";
import { useAnalysisContext } from "../../../common/contexts/analysis-context";
import DetailPage from "./page";

const SaleTunnelOriginDetail = ({ className, product }) => {
  const { setSaleTunnelDetailOpen: setIsOpen } = useAnalysisContext();
  const paymentMethods = product[0]
    ? product[3].detail
      ? product[3].detail
      : []
    : [];
  const affiliation = product[0]
    ? product[3].affiliation
      ? product[3].affiliation
      : []
    : [];

  return (
    <div className={className}>
      <button
        className="sale-tunnel-origin__close__button"
        onClick={() => setIsOpen(false)}
      >
        <Icons.CloseBySide className="sale-tunnel-origin__close__icon" />
      </button>
      <DetailPage paymentMethods={paymentMethods} affiliation={affiliation} />
    </div>
  );
};

export default SaleTunnelOriginDetail;
