import * as React from "react";
import * as Icons from "../../icons";
import Period from "./period";
import AllFilters from "./all-filters";
import { useAnalysisContext } from "../../common/contexts/analysis-context";
import { toDateString } from "../../../utils/dates";
import { addDays } from "date-fns";

const Toolbar = ({ globalLoading, filters }) => {
  const {
    setDatePeriod,
    updateAllPerfomancesData,
    selectedFilter,
    datePeriod,
  } = useAnalysisContext();
  const [period, setPeriod] = React.useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [periodOpen, setPeriodOpen] = React.useState(false);
  const ref = React.useRef();

  const onClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setPeriodOpen(false);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", onClickOutside);
    return () => {
      document.body.removeEventListener("click", onClickOutside);
    };
  }, []);

  const updatePeriodSelection = async () => {
    setPeriodOpen(false);
    const start = toDateString(addDays(period[0].startDate, 1));
    const end = toDateString(addDays(period[0].endDate, 1));
    setDatePeriod({ start, end });
    updateAllPerfomancesData(selectedFilter, { end, start });
  };

  const updatePeriodSelectionAuto = async (start, end, name) => {
    setPeriodOpen(false);
    setDatePeriod({ start, end, name });
    updateAllPerfomancesData(selectedFilter, { end, start });
  };

  return (
    <div className="toolbar__back">
      <div className="toolbar">
        <AllFilters globalLoading={globalLoading} filters={filters} />
        <div ref={ref} className="toolbar__period__zone">
          <label className="toolbar__period">
            <Icons.Calendar className="toolbar__filters__button__icon" />
            <button
              disabled={globalLoading}
              className="toolbar__filters__button"
              onClick={() => setPeriodOpen(!periodOpen)}
            >
              {datePeriod.name && datePeriod.name === "Aujourd'hui"
                ? datePeriod.name
                : `${datePeriod.start}  -  ${datePeriod.end}`}
            </button>
          </label>
          {periodOpen && (
            <Period
              state={period}
              setState={setPeriod}
              updatePeriodSelection={updatePeriodSelection}
              updatePeriodSelectionAuto={updatePeriodSelectionAuto}
              globalLoading={globalLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
