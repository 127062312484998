import * as React from "react";
import * as Icons from "../icons";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
import { useAnalysisContext } from "../common/contexts/analysis-context";

const ApiOff = ({ globalLoading }) => {
  const {
    updateAllPerfomancesData,
    selectedFilter,
    datePeriod,
    setGlobalLoading,
  } = useAnalysisContext();

  const retryAPI = async () => {
    setGlobalLoading(true);
    await updateAllPerfomancesData(selectedFilter, datePeriod);
    setGlobalLoading(false);
  };

  return (
    <div className="api-off">
      <div className="api-off__popup">
        {globalLoading && (
          <LoaderBar className="loaderbar-valraiso-background" />
        )}
        <Icons.UnHappy className="api-off__popup__icon" />
        <span className="api-off__popup__title">TROP DE REQUÊTES</span>
        <span>Les autres onglets restent accessibles</span>
        <span>
          Veuillez{" "}
          <button className="api-off__popup__button" onClick={retryAPI}>
            réessayer
          </button>{" "}
          plus tard
        </span>
      </div>
    </div>
  );
};

export default ApiOff;
