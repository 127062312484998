import * as React from "react";
import Ranks from "./ranks/ranks";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
import SeoStats from "./ranks/stats";
import SeoOff from "../../page-handler/seo-off";
import SelectDate from "./select-date";
import SeoObservatoryFilters from "./index-filters";
import { useSEOContext } from "../../common/contexts/seo-context";

const SeoObservatory = ({ domain, perm, seo }) => {
  const { isLoading, seoOff, stats, data, selectedFilter, dates } =
    useSEOContext();
  const [device, setDevice] = React.useState("Ordinateur");

  return (
    <>
      <div className="seo">
        {isLoading ? (
          <LoaderBar className="loaderbar-valraiso-background" />
        ) : seoOff ? (
          <SeoOff />
        ) : (
          <>
            <SeoObservatoryFilters
              domain={domain}
              device={device}
              setDevice={setDevice}
            />
            {stats && (
              <SeoStats
                stats={stats[selectedFilter]}
                device={device === "Ordinateur" ? "desktop" : "mobile"}
                data={{
                  ...data,
                  keywords: data.keywords[selectedFilter],
                  visibility: data.visibility[selectedFilter],
                }}
              />
            )}
            <Ranks
              data={{
                ...data,
                keywords: data.keywords[selectedFilter],
                visibility: data.visibility
                  ? data.visibility[selectedFilter]
                  : undefined,
              }}
              device={device === "Ordinateur" ? "desktop" : "mobile"}
              perm={perm}
              seo={seo}
            />
          </>
        )}
      </div>
      {dates && dates[1] && (
        <SelectDate
          domain={domain}
          dates={dates}
          selectedFilter={selectedFilter}
          device={device === "Ordinateur" ? "desktop" : "mobile"}
          perm={perm}
          seo={seo}
        />
      )}
    </>
  );
};

export default SeoObservatory;
