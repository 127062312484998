import * as React from "react";
import * as Icons from "../../icons";
import Filter from "./filter";
import getCountryName, { getIsoName } from "../../../data";

const FilterSuggestion = ({
  defaultValue,
  icon,
  globalLoading,
  value,
  onRemove,
  array,
  updateFilter,
  type,
  isCountry = false,
}) => {
  const [open, setOpened] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const ref = React.useRef();

  const searched = array.filter((option) => {
    const val = inputValue
      .toLocaleLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const cmp = option.name
      .toLocaleLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    if (cmp.replace(" ", "").includes(val.replace(" ", ""))) return true;
    return false;
  });

  const onEnter = (key) => {
    if (!searched[0] || key !== "Enter") return;
    setOpened(false);
    const name = isCountry ? getIsoName(searched[0].name) : searched[0].name;
    updateFilter(type, name);
  };

  const onClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setOpened(false);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", onClickOutside);
    return () => {
      document.body.removeEventListener("click", onClickOutside);
    };
  }, []);

  return (
    <div ref={ref}>
      <Filter
        defaultValue={defaultValue}
        Icon={icon}
        disabled={globalLoading}
        value={value}
        onRemove={onRemove}
        open={open}
        setOpened={setOpened}
      >
        <div className="all-filters__choices">
          <label
            className="admin-filters__searchbar"
            style={!searched[0] ? { border: "none" } : undefined}
          >
            <Icons.Search className="filter__button__icon" />
            <input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={
                searched && searched[0] && searched[0].name
                  ? `ex: ${searched[0].name}`
                  : ""
              }
              onKeyDown={(e) => onEnter(e.key)}
              className="admin-filter__input"
            ></input>
          </label>
          <div className="filter-scroll">
            {searched.map((elmt) => {
              return (
                <button
                  className="all-filters__choice"
                  disabled={globalLoading}
                  key={elmt.name + "choice"}
                  onClick={() => {
                    setOpened(false);
                    const name = isCountry ? getIsoName(elmt.name) : elmt.name;
                    updateFilter(type, name);
                  }}
                >
                  {getCountryName(elmt.name)}
                </button>
              );
            })}
          </div>
        </div>
      </Filter>
    </div>
  );
};

export default FilterSuggestion;
