import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./page-handler/login";
import MainPage from "./page-handler/main-page";
import Error from "./page-handler/error";
import Client from "./page-handler/client";
import useConnection from "../hooks/use-connection";
import { useAppContext } from "./common/contexts/app-context";
// import PopupFront from "./common/popup-front";

export const FIRST_PATH = window.location.pathname;

const App = () => {
  const {
    setLoggedIn,
    setPerm,
    setDomain,
    setESFDomain,
    setAuth,
    // popupInfo,
    // setPopupInfo,
  } = useAppContext();

  useConnection(
    FIRST_PATH,
    setLoggedIn,
    setPerm,
    setDomain,
    setAuth,
    setESFDomain
  );

  return (
    <div className="app">
      {/* <PopupFront popupInfo={popupInfo} setPopupInfo={setPopupInfo} /> */}
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Login />} />
          <Route path="/">
            <Route path="statistics/*" element={<MainPage />} />
            <Route path="client" element={<Client />} />
            <Route path="*" element={<Error />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
