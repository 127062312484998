import * as React from "react";
import { ComparisonBloc, NoComparisonBloc } from "./bloc";
import * as Icons from "../../icons";

const ComparisonBlocs = ({ values, compare }) => {
  if (!values[0]) {
    return <></>;
  }
  const shouldCompare = values[0].old !== 0 ? true : false;

  const stats = [
    "Visiteurs uniques",
    "Pages visitées",
    "Taux de rebond",
    "Durée de visite",
  ];

  const iconsList = [
    <Icons.Visitors className="date-no-comparison-bloc-icon" />,
    <Icons.Pages className="date-no-comparison-bloc-icon" />,
    <Icons.BounceRate className="date-no-comparison-bloc-icon" />,
    <Icons.VisitDuration className="date-no-comparison-bloc-icon" />,
  ];

  return (
    <>
      <div className="date-comparison-blocs">
        {compare &&
          stats.map((stat, index) => {
            const value = values[index];
            return (
              <ComparisonBloc
                key={stat}
                name={stat}
                value={value}
                icon={iconsList[index]}
              />
            );
          })}
        {!compare &&
          stats.map((stat, index) => {
            const value = values[index];
            return (
              <NoComparisonBloc
                key={stat}
                name={stat}
                value={value}
                icon={iconsList[index]}
              />
            );
          })}
      </div>
      {!shouldCompare && (
        <div className="date-comparison-blocs__message">
          Aucune donnée pour la période de comparaison
        </div>
      )}
    </>
  );
};

export default ComparisonBlocs;
