import { post, get, _delete } from "../utils/fetcher.js";

const isProd = process.env.NODE_ENV === "production";
const api = isProd
  ? "https://plausible.valraiso.net/api"
  : "http://localhost:9030/api";

const getHeaders = () => {
  return {
    authorization: window.localStorage.getItem("password"),
    auth: window.localStorage.getItem("auth"),
    token: window.localStorage.getItem("token"),
  };
};

export const login = async (password, auth, token) => {
  const endpoint = `${api}/login/connect-to-stats`;
  return post(endpoint, { password, auth, token });
};

export const getSpecificities = async (domain) => {
  const endpoint = `${api}/sites/specificity`;
  const body = { domain };
  return post(endpoint, body, undefined, getHeaders());
};

export const getData = async (body) => {
  const endpoint = `${api}/sites/group`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getOldData = async (body) => {
  const endpoint = `${api}/sites/old-group`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getDetail = async (body) => {
  const endpoint = `${api}/sites/data`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getSpecificIncomes = async (body) => {
  const endpoint = `${api}/income/specific`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getAuthLink = async (domain) => {
  const endpoint = `${api}/shared-links/auth/${domain}`;
  return get(endpoint, undefined, getHeaders());
};

export const getLocalSeoData = async (domain) => {
  const endpoint = `${api}/seo/${domain}`;
  return get(endpoint, undefined, getHeaders());
};

export const addSiteType = async (body) => {
  const endpoint = `${api}/seo/url/add`;
  return post(endpoint, body, undefined, getHeaders());
};

export const updateSiteType = async (body) => {
  const endpoint = `${api}/seo/url/update`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getNationalSeoData = async (body) => {
  const endpoint = `${api}/seo/get-data`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getNationalFilters = async () => {
  const endpoint = `${api}/seo/get-filters`;
  return get(endpoint, undefined, getHeaders());
};

export const getMetaData = async (url) => {
  const endpoint = `${api}/seo/meta`;
  return post(endpoint, { url }, undefined, getHeaders());
};

export const getHistoricalSeoData = async (body, domain) => {
  const endpoint = `${api}/seo/historical/${domain}`;
  return post(endpoint, body, undefined, getHeaders());
};

export const addNewAlert = async (body) => {
  const endpoint = `${api}/settings/add`;
  return post(endpoint, body, undefined, getHeaders());
};

export const removeOldAlert = async (body) => {
  const endpoint = `${api}/settings/remove`;
  return _delete(endpoint, body, undefined, getHeaders());
};

export const updateOldAlert = async (body) => {
  const endpoint = `${api}/settings/update-alert`;
  return post(endpoint, body, undefined, getHeaders());
};

export const updateEmail = async (body) => {
  const endpoint = `${api}/settings/update-mail`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getAlerts = async () => {
  const endpoint = `${api}/settings/get`;
  return get(endpoint, undefined, getHeaders());
};

export const addAllKeywordsAlerts = async (body) => {
  const endpoint = `${api}/settings/add-all`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getMonitorankKeywords = async () => {
  const endpoint = `${api}/seo/monitorank/get-keywords`;
  return get(endpoint, undefined, getHeaders());
};

export const getSearchConsoleKeywords = async () => {
  const endpoint = `${api}/seo/monitorank/get-search-console`;
  return get(endpoint, undefined, getHeaders());
};

export const changeAllKeywordsAlerts = async (body) => {
  const endpoint = `${api}/settings/change-all`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getEveryPossibleKewords = async () => {
  const endpoint = `${api}/settings/get-keywords`;
  return get(endpoint, undefined, getHeaders());
};

export const getSitesTypes = async (seo) => {
  const endpoint = `${api}/study/get-kinds/${seo}`;
  return get(endpoint, undefined, getHeaders());
};

export const getStudyData = async (body) => {
  const endpoint = `${api}/study/client`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getStationHirtoricalAds = async (body) => {
  const endpoint = `${api}/study/historical`;
  return post(endpoint, body, undefined, getHeaders());
};

export const updateUserAlertType = async (body) => {
  const endpoint = `${api}/settings/update-type`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getSEOClients = async () => {
  const endpoint = `${api}/study/get-clients`;
  return get(endpoint, undefined, getHeaders());
};

export const getClientFilters = async (seo) => {
  const endpoint = `${api}/study/get-filters/${seo}`;
  return get(endpoint, undefined, getHeaders());
};

export const getSeoSeaRate = async (body) => {
  const endpoint = `${api}/sites/search-engines-rate`;
  return post(endpoint, body, undefined, getHeaders());
};

export const domainAcceptableForToken = async (domain) => {
  const endpoint = `${api}/sites/is-acceptable/${domain}`;
  return get(endpoint, undefined, getHeaders());
};

export const getAdsSalesStudy = async (body) => {
  const endpoint = `${api}/study/ads-sales`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getAllAccess = async (domain) => {
  const endpoint = `${api}/sites/all-access/${domain}`;
  return get(endpoint, undefined, getHeaders());
};

export const getProductsIncomes = async (body) => {
  const endpoint = `${api}/income/products`;
  return post(endpoint, body, undefined, getHeaders());
};
