import * as React from "react";
import classnames from "classnames";
import { isAlpeDhuez } from "../../../../../utils/seo-type";
import { getShortUrl } from "../../../../../utils/seo";
import { getSubDomain } from "../../../../../utils/seo-sites";

const RankGlimpse = ({
  choosenName,
  choosenKind,
  choosenKindName,
  choosenKindGroup,
  choosenKindGroupName,
  choosenIconUrl,
  searchConsoleCmp,
  url,
  subDomain,
  searchConsole,
}) => {
  const rank = {
    url: subDomain ? getSubDomain(url) : getShortUrl(url),
    name: choosenName === "" ? undefined : choosenName,
    kind: choosenKind === "Créer un groupe" ? choosenKindName : choosenKind,
    kindGroup:
      choosenKindGroup === "Créer un type"
        ? choosenKindGroupName
        : choosenKindGroup,
    searchConsole,
  };

  const classes = classnames("seo-ranks__ranking__button", {
    "seo-ranks__ranking__button--esf": rank.kindGroup === "ESF",
    "seo-ranks__ranking__button--alpedhuez": isAlpeDhuez(rank.url),
  });

  return (
    <div className="seo-popup-glimpse">
      <span>Aperçu</span>
      <div className="seo-ranks__ranking__button__zone">
        <button
          className={classes}
          precision={
            rank.kindGroup === "ESF" && searchConsoleCmp !== rank.searchConsole
              ? rank.name
              : ""
          }
        >
          {rank.kindGroup === "ESF" &&
          (rank.kind === "ESF national" || rank.searchConsole !== null)
            ? "ESF"
            : choosenIconUrl === "" &&
              rank.kindGroup &&
              rank.kindGroup !== "ESF"
            ? rank.name
              ? rank.name
              : rank.kind
            : ""}

          {choosenIconUrl !== "" && (
            <img
              src={choosenIconUrl}
              alt={rank.kind}
              style={
                window.innerWidth < 695
                  ? {
                      width: "55px",
                      height: "17px",
                      objectFit: "scale-down",
                    }
                  : {
                      width: "60px",
                      height: "20px",
                      objectFit: "scale-down",
                    }
              }
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default RankGlimpse;
