import { searchEngines } from "../data";
import { roundReducer, roundReducer3 } from "./reduce";

const calcSeoSea = (sources, seoSeaData, products) => {
  const searchEnginesSources = sources.filter((source) =>
    searchEngines.includes(source.name)
  );
  return products.map((product, index) => {
    const adsNumber = roundReducer(seoSeaData[index], "number");
    const adsPrice = roundReducer(seoSeaData[index], "price");
    const searchEnginePrice = roundReducer3(
      searchEnginesSources,
      "data",
      product,
      "price"
    );
    const searchEngineNumber = roundReducer3(
      searchEnginesSources,
      "data",
      product,
      "number"
    );
    seoSeaData[index][0].price =
      searchEnginePrice - adsPrice < 0 ? 0 : searchEnginePrice - adsPrice;
    seoSeaData[index][0].number =
      searchEngineNumber - adsNumber < 0 ? 0 : searchEngineNumber - adsNumber;
    return seoSeaData[index];
  });
};

export const updateSeoSeaIncomes = async (
  setApiOff,
  setLoading,
  loading,
  params,
  charged,
  setCharged,
  getIncome,
  setRemoveLoading
) => {
  const { sources, seoSeaData, setSeoSeaData, tunnel } = params;

  try {
    if (!charged.includes("Sources")) {
      const chargedSources = await getIncome("Sources");
      setSeoSeaData(
        calcSeoSea(chargedSources.comparative, seoSeaData, tunnel.products)
      );
      setCharged([...charged, "Sources", "SEO / SEA"]);
      setRemoveLoading("SEO / SEA");
      setApiOff(false);
    } else {
      setSeoSeaData(
        calcSeoSea(sources.comparative, seoSeaData, tunnel.products)
      );
      setCharged([...charged, "SEO / SEA"]);
    }
    setApiOff(false);
  } catch (e) {
    setApiOff(true);
  }
  setLoading({ ...loading, Sites: false });
};
