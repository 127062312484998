import * as Icons from "../../icons";
import classnames from "classnames";
import { useAnalysisContext } from "../../common/contexts/analysis-context";

const Line = ({ value, index = 1 }) => {
  const cellClasses = classnames("sale-tunnel-error__cell", {
    "sale-tunnel-error__line--odd": index % 2 === 0,
  });
  const numbersClasses = classnames("sale-tunnel-error__cell__numbers", {
    "sale-tunnel-error__line--odd": index % 2 === 0,
  });

  return (
    <div className="sale-tunnel-error__stats">
      <div className="sale-tunnel-error__line">
        <span className={cellClasses}>{value.name}</span>
        <span className={numbersClasses}>{value.visitors}</span>
        <span className={numbersClasses}>
          {value.percentage}
          {value.percentage !== "% " && "%"}
        </span>
      </div>
    </div>
  );
};

const SaleTunnelErrorDetail = ({ className }) => {
  const { tunnel, setSaleTunnelDetailOpen: setIsOpen } = useAnalysisContext();

  const errorType = [
    ...new Set(
      tunnel.execCodes
        .map((error) => {
          return error.type;
        })
        .filter((elmt) => elmt)
    ),
  ];

  return (
    <div className={className}>
      <span className="comparative-select__button">
        Détail des erreurs (tout produit)
      </span>
      <button
        className="sale-tunnel-error__close__button"
        onClick={() => setIsOpen(false)}
      >
        <Icons.CloseBySide className="sale-tunnel-error__close__icon" />
      </button>
      <div className="sale-tunnel-error__tab">
        <div className="sale-tunnel-error__group">
          <Line
            value={{
              name: "",
              percentage: "% ",
              visitors: "Tentatives",
            }}
          />
        </div>
        <div className="sale-tunnel-error__stats-zone">
          {errorType.map((elmt) => {
            const array = tunnel.execCodes
              .filter((obj) => obj.type === elmt)
              .sort((a, b) => {
                return -(a.visitors - b.visitors);
              });
            return (
              <div className="sale-tunnel-error__group" key={elmt}>
                <span className="sale-tunnel-error__group__title">{elmt}</span>
                {array.map((element, index) => {
                  return (
                    <Line
                      value={element}
                      key={element + index + "error"}
                      index={index}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SaleTunnelErrorDetail;
