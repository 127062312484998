import * as Icons from "../../icons";
import Card from "../../common/card";
import { useAnalysisContext } from "../../common/contexts/analysis-context";

const SaleTunnelAbortDetail = ({ className, data }) => {
  const { setSaleTunnelDetailOpen: setIsOpen } = useAnalysisContext();
  return (
    <div className={className}>
      <span className="comparative-select__button">Détail des abandons</span>
      <button
        className="sale-tunnel-error__close__button"
        onClick={() => setIsOpen(false)}
      >
        <Icons.CloseBySide className="sale-tunnel-error__close__icon" />
      </button>
      <div className="sale-tunnel-abort">
        <div className="sale-tunnel-abort__content">
          {data &&
            data.map((abortDetail) => (
              <Card
                key={abortDetail.name}
                value={abortDetail}
                style={{ color: "gray" }}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default SaleTunnelAbortDetail;
