import * as Icons from "../icons";

const SeoOff = () => {
  return (
    <div className="api-off">
      <div className="api-off__popup">
        <Icons.UnHappy className="api-off__popup__icon" />
        <span className="api-off__popup__title">Pas de données</span>
        <span>Les autres onglets restent accessibles</span>
        <span>Veuillez réessayer plus tard</span>
      </div>
    </div>
  );
};

export default SeoOff;
