import * as React from "react";
import Filter from "../../common/filter/filter";

const Filters = ({ defaultValue, filters, icon, callBack, lock }) => {
  const [open, setOpened] = React.useState(false);
  const ref = React.useRef();

  const onClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setOpened(false);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", onClickOutside);
    return () => {
      document.body.removeEventListener("click", onClickOutside);
    };
  }, []);

  return (
    <div ref={ref}>
      <Filter
        defaultValue={defaultValue}
        Icon={icon}
        onClick={() => setOpened(!open)}
        open={open && lock !== true}
        setOpened={setOpened}
      >
        <div className="all-filters__choices">
          {filters.map((elmt) => {
            return (
              <button
                className="all-filters__choice"
                key={elmt.name + "choice"}
                onClick={() => {
                  setOpened(false);
                  callBack(elmt);
                }}
              >
                {elmt.name}
              </button>
            );
          })}
        </div>
      </Filter>
    </div>
  );
};

export default Filters;
