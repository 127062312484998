import classnames from "classnames";
import incomeFormat from "../../../../utils/incomeFormat";

const Line = ({ value, index = 1 }) => {
  const cellClasses = classnames("sale-tunnel-origin__cell", {
    "sale-tunnel-error__line--odd": index % 2 === 0,
  });
  const numbersClasses = classnames("sale-tunnel-origin__cell__numbers", {
    "sale-tunnel-error__line--odd": index % 2 === 0,
  });

  return (
    <div className="sale-tunnel-origin__stats">
      <div className="sale-tunnel-origin__line">
        <span className={cellClasses}>{value.name}</span>
        <span className={numbersClasses}>
          {typeof value.price === typeof ""
            ? value.price
            : `${incomeFormat(Math.round(value.price))}€`}
        </span>
        <span className={numbersClasses}>
          {value.price === "Revenus" ? "Commandes" : value.number}
        </span>
      </div>
    </div>
  );
};

const SimpleArray = ({ array }) => {
  return (
    <div className="sale-tunnel-origin__tab">
      <div className="sale-tunnel-origin__group">
        <Line
          value={{
            name: "",
            number: "Commandes ",
            price: "Revenus",
          }}
        />
        {array.map((line, index) => {
          return (
            <Line
              value={line}
              key={line.name + index + "origin"}
              index={index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SimpleArray;
