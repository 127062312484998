import incomeFormat from "./incomeFormat";
import { divNumber } from "./reduce";

const filtersCSV = (filters, name, selectedProduct) => {
  const firstLine = [
    [
      "Rang",
      name,
      "Visiteurs",
      "Taux de conversion (%)",
      "Commandes",
      "Chiffre d'affaire (€)",
    ],
  ];
  const output = filters.map((filter, index) => {
    const { number, price } =
      filter.data && filter.data[selectedProduct]
        ? filter.data[selectedProduct]
        : { number: 0, price: 0 };
    return [
      index,
      filter.name,
      filter.visitors,
      divNumber(number, filter.visitors),
      number,
      price ? incomeFormat(Math.round(price)) : 0,
    ];
  });
  const blob = new Blob([
    firstLine
      .concat(output)
      .map((line) => {
        return line.join(",");
      })
      .join("\n"),
  ]);
  const fileDownloadUrl = URL.createObjectURL(blob);
  return fileDownloadUrl;
};

const seoSeaCSV = (seoSea) => {
  const firstLine = [
    [
      "Rang",
      "SEO / SEA",
      "Visiteurs",
      "Taux de conversion",
      "Succes (%)",
      "Echecs (%)",
      "Commandes",
      "Chiffre d'affaire (€)",
    ],
  ];
  const output = seoSea.map((data, index) => {
    const number = data.number ? data.number : 0;
    return [
      index,
      data.name,
      data.visitors,
      divNumber(number, data.visitors),
      "-",
      "-",
      number,
      data.price ? incomeFormat(Math.round(data.price)) : 0,
    ];
  });
  const blob = new Blob([
    firstLine
      .concat(output)
      .map((line) => {
        return line.join(",");
      })
      .join("\n"),
  ]);
  const fileDownloadUrl = URL.createObjectURL(blob);
  return fileDownloadUrl;
};

const affiliationsOrSeoSeaCSV = (origins) => {
  const firstLine = [
    ["Rang", "Affiliations", "Visiteurs", "Commandes", "Chiffre d'affaire (€)"],
  ];
  const output = origins.map((data, index) => {
    return [
      index,
      data.name,
      data.visitors,
      data.number ? data.number : 0,
      data.price ? incomeFormat(Math.round(data.price)) : 0,
    ];
  });
  const blob = new Blob([
    firstLine
      .concat(output)
      .map((line) => {
        return line.join(",");
      })
      .join("\n"),
  ]);
  const fileDownloadUrl = URL.createObjectURL(blob);
  return fileDownloadUrl;
};

const sitesCSV = (domainsData, selectedProduct) => {
  const firstLine = [
    [
      "Rang",
      "Sites",
      "Visiteurs",
      "Taux de conversion (%)",
      "Succès (%)",
      "Échecs (%)",
      "Commandes",
      "Chiffre d'affaire (€)",
    ],
  ];
  const output = domainsData.map((domainData, index) => {
    const productIndex = domainData.products.findIndex(
      (product) => product === selectedProduct
    );
    const conversion = domainData.name.conversion[productIndex]
      ? domainData.name.conversion[productIndex]
      : { tcC: 0, tdS: 0, tdF: 0 };
    return [
      index,
      domainData.name.domain_name,
      domainData.name.visitors,
      productIndex === -1 ? 0 : conversion.tdC,
      productIndex === -1 ? 0 : conversion.tdS,
      productIndex === -1 ? 0 : conversion.tdF,
      productIndex === -1 ? 0 : domainData.data[productIndex][3].number,
      productIndex === -1
        ? 0
        : incomeFormat(domainData.data[productIndex][3].price),
    ];
  });
  const blob = new Blob([
    firstLine
      .concat(output)
      .map((line) => {
        return line.join(",");
      })
      .join("\n"),
  ]);
  const fileDownloadUrl = URL.createObjectURL(blob);
  return fileDownloadUrl;
};

const saveAsCSV = (
  selectedDetail,
  country,
  devices,
  sources,
  domainsIncomes,
  affiliations,
  seoSeaData,
  selectedProduct
) => {
  if (selectedDetail === "Pays")
    return filtersCSV(country.comparative, "Pays", selectedProduct);
  if (selectedDetail === "Appareils")
    return filtersCSV(devices.comparative, "Appareils", selectedProduct);
  if (selectedDetail === "Sources")
    return filtersCSV(sources.comparative, "Sources", selectedProduct);
  if (selectedDetail === "Affiliations")
    return affiliationsOrSeoSeaCSV(affiliations);
  if (selectedDetail === "SEO / SEA") return seoSeaCSV(seoSeaData);
  return sitesCSV(domainsIncomes, selectedProduct);
};

export const saveArrayAsCSV = (data) => {
  if (!data[0]) return "";

  const firstLine = data[0]
    .map((row) => {
      return row.name;
    })
    .join(",");
  const output = data.map((line) => {
    const values = line.map((row) => {
      return row.value;
    });
    return values.join(",");
  });

  const blob = new Blob([firstLine + "\n" + output.join("\n")]);
  const fileDownloadUrl = URL.createObjectURL(blob);
  return fileDownloadUrl;
};

export const saveAdsChartAsCSV = (data) => {
  if (!data[0]) return "";

  const firstLine = "Date,Nombre de pubs,Mots-clés";
  const output = data.map((line) => {
    const values = [line.date, line.number, line.keywords.join("/")];
    return values.join(",");
  });

  const blob = new Blob([firstLine + "\n" + output.join("\n")]);
  const fileDownloadUrl = URL.createObjectURL(blob);
  return fileDownloadUrl;
};

export default saveAsCSV;
