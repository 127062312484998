import * as React from "react";
import { Navigate } from "react-router-dom";
import useClientLogin from "../../hooks/use-client";
import { useAppContext } from "../common/contexts/app-context";

const Client = () => {
  const { loggedIn, setLoggedIn, setDomain, setAuth, setPerm, setESFDomain } =
    useAppContext();

  useClientLogin(setLoggedIn, setDomain, setAuth, setPerm, setESFDomain);

  if (!loggedIn) return <Navigate to="/" />;

  return <Navigate to="/statistics/plausible" />;
};

export default Client;
