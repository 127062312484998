import * as React from "react";
import { getLocalSeoData } from "../../../services/api";

const SEOContext = React.createContext();

export const useSEOContext = () => {
  const context = React.useContext(SEOContext);
  if (!context)
    throw new Error("useSEOContext must be used within a SEOContextProvider");
  return context;
};

const SEOContextProvider = (props) => {
  const { domain } = props ? props : {};

  const [isLoading, setIsLoading] = React.useState();
  const [data, setData] = React.useState({
    keywords: {},
    visibility: {},
  });
  const [kinds, setKinds] = React.useState();
  const [filters, setFilters] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState("");
  const [urls, setUrls] = React.useState({});
  const [stats, setStats] = React.useState();
  const [sync, setSync] = React.useState();
  const [seoOff, setSeoOff] = React.useState(false);
  const [dates, setDates] = React.useState([]);
  const [historicalData, setHistoricalData] = React.useState();
  const [historicalStats, setHistoricalStats] = React.useState();
  const [date, setDate] = React.useState("");
  const [selectedSort, setSelectedSort] = React.useState("Aucun tri");

  React.useEffect(() => {
    const exec = async () => {
      setIsLoading(true);
      setDate("");
      setDates([]);
      setHistoricalData(undefined);
      setHistoricalStats(undefined);
      const response = await getLocalSeoData(domain);
      if (!response.data) {
        setIsLoading(false);
        setSeoOff(true);
        return;
      }
      setSelectedFilter(response.filters[0]);
      setData({
        keywords: response.data,
        visibility: response.visibility,
      });
      setFilters(response.filters);
      setKinds(response.kinds);
      setUrls(response.urls);
      setStats(response.stats);
      setSync(response.sync);
      setDates(["", ...response.dates]);
      setSelectedSort("Aucun tri");
      setIsLoading(false);
    };
    exec();
  }, [domain]);

  return (
    <SEOContext.Provider
      value={{
        isLoading,
        data,
        kinds,
        filters,
        selectedFilter,
        urls,
        stats,
        sync,
        seoOff,
        dates,
        historicalData,
        historicalStats,
        date,
        selectedSort,
        setSelectedFilter,
        setKinds,
        setUrls,
        setDate,
        setHistoricalData,
        setHistoricalStats,
        setSelectedSort,
        setData,
      }}
      {...props}
    />
  );
};

export default SEOContextProvider;
