export const filterAlerts = (alerts, searchedValue) => {
  return alerts.filter((alert) => {
    const val = searchedValue
      .toLocaleLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const cmp = alert.keyword
      .toLocaleLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    if (cmp.replace(" ", "").includes(val.replace(" ", ""))) return true;
    return false;
  });
};

export const filterKeywords = (keywords, filter) => {
  return keywords.reduce((acc, data) => {
    if (data.filter !== filter) return acc;
    return [...acc, data.keyword];
  }, []);
};
