import * as React from "react";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
import Card from "../../common/card";
import SaleTunnelSlider from "./slider";
import SaleTunnelErrorDetail from "./error-detail";
import SaleTunnelOriginDetail from "./origin-detail/origin-detail";
import classnames from "classnames";
import SaleTunnelIncome from "./income";
import SaleTunnelTop from "./top";
import SaleTunnelAbortDetail from "./abort-detail";
import { useAnalysisContext } from "../../common/contexts/analysis-context";
import { divNumber } from "../../../utils/reduce";

const DETAIL = ["origin", "abort", "error"];

const SaleTunnel = ({ globalLoading }) => {
  const {
    firstTimeOpen,
    values,
    saleTunnelDetailOpen: isOpen,
    saleTunnelDetailWasOpen: wasOpen,
    tunnel,
    selectedProduct,
    datePeriod,
  } = useAnalysisContext();

  const classes = classnames("sale-tunnel-error", {
    "sale-tunnel-error--open": firstTimeOpen > 0 && isOpen === "error",
    "sale-tunnel-error--close":
      !isOpen && firstTimeOpen > 1 && wasOpen === "error",
  });
  const originClasses = classnames("sale-tunnel-origin", {
    "sale-tunnel-origin--open": firstTimeOpen > 0 && isOpen === "origin",
    "sale-tunnel-origin--close":
      !isOpen && firstTimeOpen > 1 && wasOpen === "origin",
  });

  const abortClasses = classnames("sale-tunnel-error", {
    "sale-tunnel-error--open": firstTimeOpen > 0 && isOpen === "abort",
    "sale-tunnel-error--close":
      !isOpen && firstTimeOpen > 1 && wasOpen === "abort",
  });

  const index = tunnel.products.findIndex(
    (product) => product === selectedProduct
  );
  const product = tunnel.data[0] ? tunnel.data[index] : [];
  const total = values[0] ? values[0].new : 0;

  return (
    <div className="sale-tunnel">
      {globalLoading && (
        <div className="loaderbar">
          <LoaderBar className="loaderbar-valraiso-background" />
        </div>
      )}
      <SaleTunnelTop />
      <div className="sale-tunnel__switch-direction">
        <div className="sale-tunnel__schema">
          <div className="sale-tunnel__background-stick"></div>
          <Card
            value={{
              name: "Visiteurs uniques",
              number: total,
              percentage: total === 0 ? "♾️" : 100,
            }}
            bottomColor={"var(--color-pos)"}
            style={{ color: "var(--color-light-front)" }}
          />
          {product.map((goal, index) => {
            if (index > 2) return null;
            const diviser = index === 0 ? total : product[index - 1].number;
            return (
              <Card
                key={"tunnel" + goal.name + "card"}
                value={{
                  ...goal,
                  percentage: divNumber(goal.number, diviser),
                }}
                style={{ color: "var(--color-light-front)" }}
                bottomColor={"var(--color-pos)"}
              />
            );
          })}
          <div className="sale-tunnel__schema__end">
            {product[0] &&
              DETAIL.map((detail, index) => (
                <SaleTunnelIncome
                  key={"tunnel" + detail + "detail"}
                  value={product[index + 3].price}
                  set={detail}
                >
                  <Card
                    value={{
                      ...product[index + 3],
                      percentage: divNumber(
                        product[index + 3].number,
                        product[2].number
                      ),
                    }}
                    style={{ color: "var(--color-light-front)" }}
                    bottomColor={
                      index === 0 ? "var(--color-pos)" : "var(--color-neg)"
                    }
                  />
                </SaleTunnelIncome>
              ))}
          </div>
          <div className="sale-tunnel-error__hide-zone">
            <SaleTunnelErrorDetail className={classes} />
            <SaleTunnelOriginDetail
              className={originClasses}
              product={product}
            />
            <SaleTunnelAbortDetail
              className={abortClasses}
              data={product[0] ? product[4].detail : []}
              dateStart={datePeriod.start}
            />
          </div>
        </div>
        <SaleTunnelSlider values={values} product={product} isOpen={isOpen} />
      </div>
    </div>
  );
};

export default SaleTunnel;
