import * as React from "react";
import DateComparison from "./date-comparison/date-comparison";
import Comparative from "./comparative/comparative";
import SaleTunnel from "./sale-tunnel/sale-tunnel";
import { useAnalysisContext } from "../common/contexts/analysis-context";
import Feedback from "../common/feedback";

const Statistics = ({
  setDomain,
  domain,
  perm,
  filtersData,
  setApiOff,
  globalLoading,
}) => {
  const { datePeriod, feedbackDone } = useAnalysisContext();
  const [incomeLoading, setIncomeLoading] = React.useState({
    Sites: false,
    Pays: false,
    Sources: false,
    Appareils: false,
    "SEO / SEA": false,
  });
  const [startIncomeLoading, setStartIncomeLoading] = React.useState(0);
  const [removeLoading, setRemoveLoading] = React.useState([]);

  const switchLoading = () => {
    const removedLoading = incomeLoading;
    for (const value of removeLoading) {
      removedLoading[value] = false;
    }
    setRemoveLoading([]);
    setIncomeLoading({ ...removedLoading });
  };

  if (removeLoading[0]) switchLoading();

  const showTunnel = filtersData[0]
    ? filtersData[
        filtersData.findIndex(
          (obj) => obj.name === domain && obj.kind === "Sites"
        )
      ].orderTypeId !== 0
    : false;

  return (
    <div className="statistics">
      <DateComparison
        globalLoading={globalLoading}
        firstDatePeriod={datePeriod}
      />
      {showTunnel && (
        <SaleTunnel
          incomeLoading={incomeLoading}
          setIncomeLoading={setIncomeLoading}
          setStartIncomeLoading={setStartIncomeLoading}
          removeLoading={removeLoading}
          setRemoveLoading={setRemoveLoading}
          setApiOff={setApiOff}
          globalLoading={globalLoading}
        />
      )}
      <Comparative
        datePeriod={datePeriod}
        loading={incomeLoading}
        setLoading={setIncomeLoading}
        startIncomeLoading={startIncomeLoading}
        setStartIncomeLoading={setStartIncomeLoading}
        removeLoading={removeLoading}
        setRemoveLoading={setRemoveLoading}
        perm={perm}
        filtersData={filtersData}
        setApiOff={setApiOff}
        globalLoading={globalLoading}
        setDomain={setDomain}
      />
      {!feedbackDone && <Feedback />}
    </div>
  );
};

export default Statistics;
