import * as React from "react";
import classnames from "classnames";
import Popup from "../../../../common/popup";
import { filterKindGroupAndKind, getShortUrl } from "../../../../../utils/seo";
import Select from "@valraiso-esf/esf-ui/es/select";
import Input from "@valraiso-esf/esf-ui/es/input";
import { addType, getSubDomain } from "../../../../../utils/seo-sites";
import Checkbox from "../../../../common/select-multiple/checkbox";
import RankGlimpse from "./glimpse";
import {
  getKindGroup,
  getKindGroupName,
  getKind,
  getKindName,
} from "../../../../../utils/seo-set_kind";

const SetKindPopup = ({
  rank,
  coords,
  setIsOpen,
  kinds,
  setKinds,
  kind,
  kindGroup,
  name = "",
  iconUrl = "",
  setChangeSettings,
  perm,
  setUrls,
  urls,
  seo,
  searchConsoleCmp,
}) => {
  const [choosenKindGroup, setChoosenKindGroup] = React.useState(
    getKindGroup(kindGroup, kinds, seo)
  );
  const [choosenKind, setChoosenKind] = React.useState(
    getKind(kindGroup, kind, kinds, seo)
  );
  const [choosenKindGroupName, setChoosenKindGroupName] = React.useState(
    getKindGroupName(kindGroup, kinds, seo)
  );
  const [choosenKindName, setChoosenKindName] = React.useState(
    getKindName(kindGroup, kind, kinds, seo)
  );
  const [choosenName, setChoosenName] = React.useState(name);
  const [choosenIconUrl, setChoosenIconUrl] = React.useState(
    urls[seo][choosenKind]
      ? urls[seo][choosenKind]
      : urls.all[choosenKind]
      ? urls.all[choosenKind]
      : iconUrl
  );
  const [subDomain, setSubDomain] = React.useState(
    rank.shortUrl !== null && getShortUrl(rank.url) !== rank.shortUrl
  );

  const modificationButtons = [
    {
      message: "Annuler",
      action: () => {
        setIsOpen(false);
        setChangeSettings(false);
      },
    },
    {
      message: "Valider",
      action: () =>
        addType(
          subDomain,
          choosenName,
          choosenKind,
          choosenKindName,
          choosenKindGroup,
          choosenKindGroupName,
          choosenIconUrl,
          iconUrl,
          seo,
          kinds,
          rank,
          setIsOpen,
          setChangeSettings,
          kindGroup,
          kind,
          setKinds,
          urls,
          setUrls
        ),
    },
  ];

  const updateKindGroups = (e) => {
    setChoosenKindGroup(e);
    if (e === "Créer un type") {
      setChoosenKind("Créer un groupe");
      return;
    }
    if (e === "ESF" || e === "Office de tourisme / Mairie") {
      setChoosenKind(e);
      return;
    }
    const thisKind = kinds[seo].find((elmt) => elmt.kindGroup === e);
    setChoosenKind(thisKind.kind);
    setChoosenIconUrl(
      urls[seo][thisKind.kind]
        ? urls[seo][thisKind.kind]
        : urls.all[thisKind.kind]
        ? urls.all[thisKind.kind]
        : ""
    );
    setChoosenKindGroup(e);
  };

  const updateKind = (e) => {
    setChoosenKind(e);
    if (urls[seo][e]) {
      setChoosenIconUrl(urls[seo][e]);
    } else if (urls.all[e]) {
      setChoosenIconUrl(urls.all[e]);
    } else {
      setChoosenIconUrl("");
    }
  };

  const { kindGroupsPropositions, kindsPropositions } = filterKindGroupAndKind(
    kinds[seo],
    choosenKindGroup
  );
  const classes = classnames("seo-popup-rank-infos--urls", {
    "seo-popup-rank-infos--urls--off": !subDomain,
  });

  return (
    <Popup
      className="seo-ranks__ranking__popup"
      style={coords}
      buttons={modificationButtons}
      setIsOpen={(bool) => {
        setIsOpen(bool);
        setChangeSettings(bool);
      }}
    >
      <span className="seo-popup--bold">
        Attribuer un groupe au domaine <br />
        <div className="seo-popup__set__kind">
          <a
            href={rank.url}
            className="seo-popup-rank-infos--urls"
            target="_blank"
          >
            <span className={classes}>
              {getSubDomain(rank.url).replace(getShortUrl(rank.url), "")}
            </span>
            <span className="seo-popup-rank-infos--urls">
              {getShortUrl(rank.url)}
            </span>
          </a>
          <Checkbox
            className="seo-popup__set__kind__checkbox"
            label="Sous-domaines"
            checked={subDomain}
            onChange={() => setSubDomain(!subDomain)}
          />
        </div>
      </span>
      <span className="seo-popup-rank-infos--normal seo-popup-rank-infos--flex seo-popup-rank-infos--left seo-popup-set-kind--gap">
        <div className="seo-popup__set__kind">
          <Select
            label="Type"
            value={choosenKindGroup}
            onValueChanged={updateKindGroups}
            className="seo-popup-set-kind__input"
          >
            {kinds &&
              kindGroupsPropositions.map((elmt) => {
                return <option key={elmt + "choice"}>{elmt}</option>;
              })}
            <option>Créer un type</option>
          </Select>
          {choosenKindGroup === "Créer un type" && (
            <Input
              type="text"
              value={choosenKindGroupName}
              onChange={(e) => setChoosenKindGroupName(e.target.value)}
              className="seo-popup-set-kind__input"
              placeholder="Nom du type"
            />
          )}
        </div>
        <div className="seo-popup__set__kind">
          {choosenKindGroup !== "Office de tourisme / Mairie" &&
            choosenKindGroup !== "ESF" && (
              <Select
                label="Groupe"
                value={choosenKind}
                onValueChanged={updateKind}
                className="seo-popup-set-kind__input"
              >
                {kindsPropositions.map((elmt) => {
                  return <option key={elmt + "choice"}>{elmt}</option>;
                })}
                <option>Créer un groupe</option>
              </Select>
            )}
          {choosenKind === "Créer un groupe" && (
            <Input
              type="text"
              value={choosenKindName}
              onChange={(e) => setChoosenKindName(e.target.value)}
              className="seo-popup-set-kind__input"
              placeholder="Nom du groupe"
            />
          )}
        </div>
        <div className="seo-popup__set__kind">
          <Input
            label="Nom abrégé"
            type="text"
            value={choosenName}
            onChange={(e) => {
              if (e.target.value.length > 12) return;
              setChoosenName(e.target.value);
            }}
            className="seo-popup-set-kind__input"
          />
          {perm === 3 && (
            <Input
              label="Url de l'icon"
              type="text"
              value={choosenIconUrl}
              onChange={(e) => setChoosenIconUrl(e.target.value)}
              className="seo-popup-set-kind__input"
            />
          )}
        </div>
        <RankGlimpse
          choosenName={choosenName}
          choosenKind={choosenKind}
          choosenKindName={choosenKindName}
          choosenKindGroup={choosenKindGroup}
          choosenKindGroupName={choosenKindGroupName}
          choosenIconUrl={choosenIconUrl}
          searchConsoleCmp={searchConsoleCmp}
          url={rank.url}
          subDomain={subDomain}
          searchConsole={rank.searchConsole}
        />
      </span>
    </Popup>
  );
};

export default SetKindPopup;
