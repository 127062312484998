import * as React from "react";
import classnames from "classnames";

const SettingsInput = ({
  value,
  onChange,
  className,
  placeholder,
  options = [],
}) => {
  const [width, setWidth] = React.useState(0);
  const span = React.useRef();
  const classes = classnames("settings-input", className);

  const searched = options.filter((option) => {
    const val = value
      .toLocaleLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const cmp = option
      .toLocaleLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    if (cmp.replace(" ", "").startsWith(val.replace(" ", ""))) return true;
    return false;
  });

  const onEnter = (key) => {
    if (!searched[0] || key !== "Enter") return;
    onChange(searched[0]);
  };

  React.useEffect(() => {
    setWidth(span.current.offsetWidth);
  }, [value]);

  return (
    <label className={classes} proposition={searched[0]}>
      <span ref={span} className="settings-input__span">
        {value}
      </span>
      <input
        value={value}
        style={{ width: `calc(${width}px + 10px)` }}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        onKeyDown={(e) => onEnter(e.key)}
      />
      <div className="settings-input__border" />
    </label>
  );
};

export default SettingsInput;
