import * as React from "react";
import Popup from "../../common/popup";
import Select from "@valraiso-esf/esf-ui/es/select";
import Input from "@valraiso-esf/esf-ui/es/input";
import { useSEOStudyContext } from "../../common/contexts/seo-study-context";

const alphaSort = (array) => {
  array.sort((a, b) => {
    var textA = a.toUpperCase();
    var textB = b.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
};

const AddNewActorPopup = ({ setPopup }) => {
  const { kinds, setCompetitor } = useSEOStudyContext();
  const [type, setType] = React.useState("");
  const [group, setGroup] = React.useState("");
  const [url, setUrl] = React.useState("");

  const addCompetitor = async () => {
    setCompetitor({
      type: url !== "" ? "url" : group !== "" ? "kind" : "kind_group",
      name: url !== "" ? url : group !== "" ? group : type,
    });
    setPopup(undefined);
  };

  const buttons = [
    { message: "ANNULER", action: () => setPopup(undefined) },
    { message: "ANALYSER", action: addCompetitor },
  ];

  const types = [
    "",
    ...new Set(
      kinds.map((kind) => {
        return kind.kindGroup;
      })
    ),
  ];
  const groups = [
    "",
    ...new Set(
      kinds.reduce((acc, kind) => {
        if (type === "" || kind.kindGroup === type) return [...acc, kind.kind];
        return acc;
      }, [])
    ),
  ];
  alphaSort(types);
  alphaSort(groups);

  return (
    <div className="settings-popup__back">
      <Popup setIsOpen={() => setPopup(undefined)} buttons={buttons}>
        (1 séléction minimum)
        <Select
          label="Type"
          value={type}
          onValueChanged={setType}
          className="seo-popup-set-kind__input"
        >
          {types.map((kindGroup) => {
            return (
              <option key={kindGroup + "select choice"}>{kindGroup}</option>
            );
          })}
        </Select>
        <Select
          label="Groupe"
          value={group}
          onValueChanged={setGroup}
          className="seo-popup-set-kind__input"
        >
          {groups.map((kind) => {
            return <option key={kind + "select choice"}>{kind}</option>;
          })}
        </Select>
        <Input
          label="Url"
          className="seo-popup-set-kind__input"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </Popup>
    </div>
  );
};

export default AddNewActorPopup;
