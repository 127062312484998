import * as React from "react";
import * as Icons from "../../../icons";
import classnames from "classnames";
import GmapsPopup from "./popups/gmaps";

const GmapsBadge = ({ value }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef();
  const classes = classnames(
    "seo-ranks__badges__button",
    "seo-ranks__badges__button__gmaps",
    {
      "seo-ranks__badges--off": !value,
      "seo-ranks__badges--on": value,
      "seo-ranks__badges__ads--on": value && isOpen,
    }
  );

  const onClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", onClickOutside);
    return () => {
      document.body.removeEventListener("click", onClickOutside);
    };
  }, []);

  return (
    <div className="seo-ranks__badge" ref={ref}>
      <button className={classes} onClick={() => setIsOpen(!isOpen)}>
        <Icons.Gmap className="seo-ranks__badges__gmap__icon" />
      </button>
      <GmapsPopup
        coords={{ left: "40px" }}
        value={value}
        setIsOpen={isOpen ? setIsOpen : undefined}
      />
    </div>
  );
};

export default GmapsBadge;
