import * as React from "react";
import Popup from "../../../../common/popup";

const GmbPopup = ({ coords, value, setIsOpen }) => {
  return (
    <Popup
      className="seo-ranks__ranking__popup seo-popup-rank-infos--left seo-popup-badges"
      style={coords}
      buttons={[]}
      setIsOpen={setIsOpen}
    >
      <div className="seo-popup-rank-infos--flex">
        <span className="seo-popup-keyword__title">
          Google My Business <br />
          {value && value.keyword_name}
        </span>
        {value && value.data && (
          <span>
            {value.data.title === "" ? "Titre inconnu" : value.data.title}
            <br />
            {value.data.rating && (
              <>
                {value.data.rating} étoiles <br />
              </>
            )}
            {value.data.reviews && (
              <>
                {value.data.reviews} commentaires
                <br />
              </>
            )}
            <span className="seo-popup-rank-infos--urls">
              {value.data.website}
            </span>
          </span>
        )}
      </div>
    </Popup>
  );
};

export default GmbPopup;
