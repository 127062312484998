import * as React from "react";
import classnames from "classnames";
import Button from "@valraiso-esf/esf-ui/es/button";
import * as Icons from "../icons";

const Popup = ({ setIsOpen, className, style, buttons = [], children }) => {
  const ref = React.useRef();
  const classes = classnames("seo-popup", className);

  const closePopup = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  return (
    <div
      className={setIsOpen ? "seo-popup__back" : ""}
      onClick={onClickOutside}
    >
      <div ref={ref} className={classes} style={style}>
        {setIsOpen && (
          <button className="seo-popup__close" onClick={closePopup}>
            <Icons.Close className="seo-popup__close__icon" />
          </button>
        )}
        {children}
        <div className="seo-popup__buttons">
          {buttons.map((button, index) => {
            return (
              <Button
                iconLeft={index === 0 ? <Icons.Close /> : null}
                color={index === 0 ? "secondary" : "primary"}
                key={
                  String(index) + String(button.message) + String(button.action)
                }
                onClick={button.action}
                className={index === 0 ? "" : "button-valraiso-background"}
              >
                {button.message}
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Popup;
