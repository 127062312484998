import * as React from "react";
import { subDays } from "date-fns";
import { toDateString } from "../../../utils/dates";
import { getAdsSalesStudy } from "../../../services/api";

const SEOAdsImpactContext = React.createContext();

export const useSEOAdsImpactContext = () => {
  const context = React.useContext(SEOAdsImpactContext);
  if (!context)
    throw new Error(
      "useSEOAdsImpactContext must be used within a SEOAdsImpactContextProvider"
    );
  return context;
};

const SEOAdsImpactContextProvider = (props) => {
  const { domain, seo } = props ? props : {};
  const [filters, setFilters] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [groupDevices, setGroupDevices] = React.useState(false);
  const [groupFilters, setGroupFilters] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [period, setPeriod] = React.useState({
    startDate: toDateString(subDays(new Date(), 30)),
    endDate: toDateString(new Date()),
  });
  const [oldPeriod, setOldPeriod] = React.useState({
    startDate: toDateString(subDays(new Date(), 30)),
    endDate: toDateString(new Date()),
  });
  const [calendarPeriod, setCalendarPeriod] = React.useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [newPeriod, setNewPeriod] = React.useState(true);
  const [clicCalendar, setClicCalendar] = React.useState(0);
  const [breakdown, setBreakdown] = React.useState([]);
  const [maxSales, setMaxSales] = React.useState(0);
  const ref = React.useRef();

  const getData = async (reqPeriod) => {
    setIsLoading(true);
    setIsOpen(false);
    setNewPeriod(false);
    const body = {
      domain: domain,
      seo,
      dateFrom: reqPeriod.startDate,
      dateTo: reqPeriod.endDate,
    };
    setOldPeriod({ ...reqPeriod });
    const {
      filters: newFilters,
      breakdown: newBreakdown,
      data: newData,
      maxSales: newMaxSales,
    } = await getAdsSalesStudy(body);
    setFilters(newFilters);
    setBreakdown(newBreakdown);
    setData(newData);
    setMaxSales(newMaxSales);
    setIsLoading(false);
  };

  const updatePeriod = (e) => {
    const newDate = {
      startDate: toDateString(e[0].startDate, 1),
      endDate: toDateString(e[0].endDate, 1),
    };
    setPeriod(newDate);
    setNewPeriod(
      oldPeriod.startDate !== newDate.startDate ||
        oldPeriod.endDate !== newDate.endDate
    );
    if (clicCalendar == 1) getData(newDate);
    setClicCalendar((clicCalendar + 1) % 2);
    setCalendarPeriod(e);
  };

  const onClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setIsOpen(false);
      setClicCalendar(0);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", onClickOutside);
    return () => {
      document.body.removeEventListener("click", onClickOutside);
    };
  }, []);

  React.useEffect(() => {
    const exec = async () => {
      setIsLoading(true);
      setNewPeriod(false);
      const body = {
        domain: domain,
        seo,
        dateFrom: period.startDate,
        dateTo: period.endDate,
      };
      setOldPeriod({ ...period });
      const {
        filters: newFilters,
        breakdown: newBreakdown,
        data: newData,
        maxSales: newMaxSales,
      } = await getAdsSalesStudy(body);
      setFilters(newFilters);
      setBreakdown(newBreakdown);
      setData(newData);
      setMaxSales(newMaxSales);
      setIsLoading(false);
    };
    exec();
  }, [domain, seo]);

  return (
    <SEOAdsImpactContext.Provider
      value={{
        filters,
        setFilters,
        data,
        setData,
        groupDevices,
        setGroupDevices,
        groupFilters,
        setGroupFilters,
        isLoading,
        setIsLoading,
        isOpen,
        setIsOpen,
        period,
        updatePeriod,
        ref,
        calendarPeriod,
        newPeriod,
        setNewPeriod,
        setOldPeriod,
        getData,
        breakdown,
        maxSales,
      }}
      {...props}
    />
  );
};

export default SEOAdsImpactContextProvider;
