import * as React from "react";
import * as Icons from "../icons";
import classnames from "classnames";

const MenuButton = ({
  label,
  onClick,
  selected,
  Icon,
  displayLabel = true,
  detailArrow = false,
}) => {
  const ref = React.useRef();
  const classes = classnames("menu-button", {
    "menu-button--on": selected,
  });

  const onClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      onClick(true);
    }
  };

  React.useEffect(() => {
    if (!detailArrow) return;
    document.body.addEventListener("click", onClickOutside);
    return () => {
      document.body.removeEventListener("click", onClickOutside);
    };
  }, []);

  return (
    <label className={classes} ref={ref} onClick={onClick}>
      <Icon className="menu-button__icon" />
      {label && displayLabel && (
        <>
          <button className="menu-button__button">
            {label}
            {detailArrow && <Icons.Next className="menu-button__icon__arrow" />}
          </button>
        </>
      )}
    </label>
  );
};

export default MenuButton;
