import * as React from "react";
import * as Icons from "../icons";
import SettingsInput from "../common/settings/input";
import SettingsAlertArray from "./alert-array";
import { updateEmail, updateUserAlertType } from "../../services/api";
import RemovePopup from "./remove-popup";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
import Toast from "@valraiso-esf/esf-ui/es/toast";
import SwitchButton from "../common/switch-button";
import { filterAlerts } from "../../utils/alerts";
import useSetPage from "../../hooks/use-set-page";
import { useSettingsContext } from "../common/contexts/settings-context";

const SettingsPage = ({ setPage }) => {
  const {
    alerts,
    mail,
    setMail,
    firstMail,
    setFirstMail,
    removePopup,
    isLoading,
    searchedValue,
    alertType,
    setAlertType,
  } = useSettingsContext();

  useSetPage(setPage, "Paramètres");

  const updateMail = () => {
    setFirstMail(mail);
    updateEmail({ mail });
  };

  const updateAlertType = (e) => {
    setAlertType(e);
    updateUserAlertType({ type: e });
  };

  const showedAlerts = filterAlerts(alerts, searchedValue);

  return (
    <div className="settings">
      <Toast.Zone>
        <div className="settings-alerts">
          {isLoading ? (
            <LoaderBar className="loaderbar-valraiso-background" />
          ) : (
            <>
              {removePopup && <RemovePopup />}
              <span className="date-comparison__title">
                Paramétrage des alertes
              </span>
              <div className="settings-alerts__top">
                <SettingsInput
                  value={mail}
                  onChange={setMail}
                  placeholder="e-mail"
                />
                {mail !== firstMail && (
                  <button
                    className="settings-alerts__array__line__action"
                    onClick={updateMail}
                  >
                    <Icons.Check />
                  </button>
                )}
              </div>
              <SwitchButton
                value={alertType}
                values={["Quotidien", "Hebdomadaire"]}
                onValueChanged={updateAlertType}
                className="settings-alerts__alert-type"
              />
              <SettingsAlertArray showedAlerts={showedAlerts} />
            </>
          )}
        </div>
      </Toast.Zone>
    </div>
  );
};

export default SettingsPage;
