import * as React from "react";
import Toast from "@valraiso-esf/esf-ui/es/toast";
import NavigationTop from "./navigation";
import { Navigate } from "react-router-dom";
import Plausible from "./plausible";
import SeoObservatory from "../seo-observatory";
import NationalSeo from "../seo-observatory/national-index";
import TopScroller from "./top-scroller";
import { Routes, Route } from "react-router-dom";
import Analysis from "./analysis";
import SettingsPage from "../settings";
import CompetitionStudy from "../seo-study";
import AnalysisContextProvider from "../common/contexts/analysis-context";
import useGetAllFilters from "../../hooks/use-filters";
import SettingsContextProvider from "../common/contexts/settings-context";
import SEOStudyContextProvider from "../common/contexts/seo-study-context";
import { hasESFAccess, hasNationalESFAccess } from "../../utils/seo";
import { useAppContext } from "../common/contexts/app-context";
import SEOAdsImpactContextProvider from "../common/contexts/seo-ads-impact";
import AdsImpact from "../seo-ads-impact";
import Menu from "./menu";

const MainPage = () => {
  const {
    loggedIn,
    setLoggedIn,
    domain,
    setDomain,
    perm,
    auth,
    globalLoading,
    setGlobalLoading,
    apiOff,
    setApiOff,
    setSeo,
    seo,
    trackEvent,
    menuOpen,
    setMenuOpen,
    page,
    setPage,
  } = useAppContext();
  const [filtersData, setFiltersData] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [firstData, setFirstData] = React.useState(true);

  useGetAllFilters(domain, perm, setFiltersData, setFilters, setSeo);

  if (!loggedIn) {
    return <Navigate to="/" />;
  }

  const isESF = hasESFAccess(filtersData);
  const isNationalESF = hasNationalESFAccess(filtersData);
  const domainHasSEO = filters[0] && filters[0].seo >= 1;

  return (
    <>
      <AnalysisContextProvider
        setApiOff={setApiOff}
        setGlobalLoading={setGlobalLoading}
        domain={domain}
        trackEvent={trackEvent}
      >
        <Toast.Zone>
          <NavigationTop
            setLoggedIn={setLoggedIn}
            setPage={setPage}
            page={page}
            perm={perm}
            setDomain={setDomain}
            filtersData={filtersData}
            filters={filters}
            firstData={firstData}
            setFirstData={setFirstData}
          />
          <div className="site-container">
            <Menu
              menuOpen={menuOpen}
              setPage={setPage}
              setMenuOpen={setMenuOpen}
              page={page}
              filters={filters}
              filtersData={filtersData}
              firstData={firstData}
              setFirstData={setFirstData}
              perm={perm}
            />
            <div className="page-container">
              <span className="navigation__title">{page}</span>
              {page !== "Données Plausible" && <TopScroller />}
              <Routes>
                <Route
                  path="analysis"
                  element={
                    <Analysis
                      setDomain={setDomain}
                      domain={domain}
                      perm={perm}
                      filtersData={filtersData}
                      setApiOff={setApiOff}
                      apiOff={apiOff}
                      filters={filters}
                      setPage={setPage}
                      globalLoading={globalLoading}
                    />
                  }
                />
                {domainHasSEO && (
                  <Route
                    path="seo-station"
                    element={
                      <SeoObservatory
                        domain={domain}
                        perm={perm}
                        setPage={setPage}
                        filters={filters}
                      />
                    }
                  />
                )}
                <Route
                  path="plausible"
                  element={
                    <Plausible auth={auth} domain={domain} setPage={setPage} />
                  }
                />
                {isNationalESF && (
                  <Route
                    path="seo-national"
                    element={<NationalSeo perm={perm} setPage={setPage} />}
                  />
                )}
                {domainHasSEO && (
                  <Route
                    path="settings"
                    element={
                      <SettingsContextProvider perm={perm}>
                        <SettingsPage setPage={setPage} />
                      </SettingsContextProvider>
                    }
                  />
                )}
                {isESF && (
                  <Route
                    path="study"
                    element={
                      <SEOStudyContextProvider>
                        <CompetitionStudy setPage={setPage} />
                      </SEOStudyContextProvider>
                    }
                  />
                )}
                {perm === 3 && (
                  <Route
                    path="ads"
                    element={
                      <SEOAdsImpactContextProvider domain={domain} seo={seo}>
                        <AdsImpact setPage={setPage} />
                      </SEOAdsImpactContextProvider>
                    }
                  />
                )}
              </Routes>
            </div>
          </div>
        </Toast.Zone>
      </AnalysisContextProvider>
    </>
  );
};

export default MainPage;
