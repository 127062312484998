export const roundReducer = (array, param) => {
  return Math.round(
    array.reduce(
      (total, data) => (data[param] ? total + data[param] : total),
      0
    )
  );
};

export const roundReducer3 = (array, param, param2, param3) => {
  return Math.round(
    array.reduce(
      (total, data) =>
        data[param]
          ? data[param][param2]
            ? data[param][param2][param3]
              ? total + data[param][param2][param3]
              : total
            : total
          : total,
      0
    )
  );
};

export const roundNumber = (number, diviser, coma = 1) => {
  return !diviser || number === undefined
    ? 0
    : Math.round(((number * 100) / diviser) * coma) / coma;
};

export const divNumber = (dividend, diviser) => {
  if (dividend === 0) return 0;
  if (diviser === 0) return "♾️";
  const div = Math.round((dividend * 100) / diviser);
  if (div >= 2) return div;
  return Math.round(((dividend * 100) / diviser) * 10) / 10;
};
