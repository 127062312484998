import * as React from "react";
import Popup from "../../../../common/popup";
import classnames from "classnames";
import { getMetaData } from "../../../../../services/api";
import { getShortUrl } from "../../../../../utils/seo";

const AdsHour = ({ values }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const getMeta = async (elmt) => {
    if (elmt.meta) return;
    setIsLoading(true);
    const { metaImage, metaTitle } = await getMetaData(elmt.url);
    elmt.meta = { metaImage, metaTitle };
    setIsLoading(false);
  };

  return (
    <span className="seo-popup-ads">
      {values.ads.map((elmt) => {
        const shortUrl =
          elmt.shortUrl === null ? getShortUrl(elmt.url) : elmt.shortUrl;
        return (
          <span
            key={"ads" + elmt.url + elmt.rank}
            style={{ position: "relative" }}
            onMouseEnter={() => getMeta(elmt)}
          >
            {elmt.rank}.{" "}
            <a
              href={elmt.url}
              target="_blank"
              rel="noopener noreferrer"
              className="seo-popup-rank-infos--urls seo-popup-ads__link"
            >
              {shortUrl}
            </a>
            <div
              className="seo-popup-ads__link__preview"
              style={
                !(elmt.meta && elmt.meta.metaImage !== "fail")
                  ? { padding: "8px" }
                  : {}
              }
            >
              {isLoading && ""}
              {elmt.meta && elmt.meta.metaImage !== "fail" && (
                <img
                  src={elmt.meta.metaImage}
                  alt={elmt.shortUrl}
                  style={{
                    width: "50px",
                    height: "30px",
                    objectFit: "fill",
                  }}
                  className="seo-popup-ads__link__preview__image"
                />
              )}
              <a
                href={elmt.url}
                target="_blank"
                rel="noopener noreferrer"
                className="seo-popup-rank-infos--urls seo-popup-ads__link seo-popup-rank-infos--normal"
              >
                {elmt.url}
              </a>
            </div>
            <br />
            <br />
          </span>
        );
      })}
    </span>
  );
};

const AdsPopup = ({ coords, value, keyword, setIsOpen }) => {
  const [selected, setSelected] = React.useState(9);

  React.useEffect(() => {
    if (value) setSelected(value[0].saveHour);
  }, [value]);

  return (
    <Popup
      className="seo-ranks__ranking__popup seo-popup-rank-infos--left seo-popup-badges seo-popup-ads__popup__overflow"
      style={coords}
      buttons={[]}
      setIsOpen={setIsOpen}
    >
      <div className="seo-popup-rank-infos--flex">
        <span className="seo-popup-keyword__title">
          AdWords Google
          <br />
          {keyword}
        </span>
        {value && (
          <div className="seo-ranks__ads">
            {value.map((elmt) => {
              const classes = classnames("seo-ranks__ads__button", {
                "seo-ranks__ads__button--selected": elmt.saveHour === selected,
              });
              return (
                <button
                  key={"button" + elmt.saveDate + elmt.saveHour}
                  className={classes}
                  onClick={() => setSelected(elmt.saveHour)}
                  date={elmt.saveDate}
                >
                  {elmt.saveHour}h
                </button>
              );
            })}
          </div>
        )}
        {value && value.find((obj) => obj.saveHour === selected) && (
          <AdsHour values={value.find((obj) => obj.saveHour === selected)} />
        )}
      </div>
    </Popup>
  );
};

export default AdsPopup;
