import React from "react";

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div className="seo-study-chart__tooltip">
        <span className="seo-study-chart__tooltip__date">
          {payload?.[0].payload.date}
        </span>
        <span className="seo-study-chart__tooltip__number">
          {payload?.[0].payload.number} pubs
        </span>
        {payload &&
          payload?.[0].payload.keywords &&
          payload[0].payload.keywords.map((keyword) => {
            return (
              <span
                key={payload?.[0].payload.date + keyword}
                className="seo-study-chart__tooltip__keyword"
              >
                - {keyword}
              </span>
            );
          })}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
