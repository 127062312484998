import * as React from "react";
import Plausible from "plausible-tracker";
import { getAllAccess } from "../../../services/api";

const AppContext = React.createContext();

export const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (!context)
    throw new Error("useAppContext must be used within a AppContextProvider");
  return context;
};

const AppContextProvider = (props) => {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [page, setPage] = React.useState("Données Plausible");
  const [domain, setDomain] = React.useState("esf-meribel.com");
  const [ESFDomain, setESFDomain] = React.useState(true);
  const [perm, setPerm] = React.useState(0);
  const [auth, setAuth] = React.useState();
  const [globalLoading, setGlobalLoading] = React.useState(false);
  const [apiOff, setApiOff] = React.useState(false);
  const [seo, setSeo] = React.useState(0);
  const [allAccess, setAllAccess] = React.useState([]);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const plausible = Plausible({
    domain: "peekperformances.valraiso.net",
  });
  const [popupInfo, setPopupInfo] = React.useState({
    opened: false,
    content: <div>coucou</div>,
  });
  const [opinionSent, setOpinionSent] = React.useState(false);
  const menuRef = React.useRef();
  const { enableAutoPageviews, enableAutoOutboundTracking, trackEvent } =
    Plausible();
  enableAutoPageviews();
  enableAutoOutboundTracking();

  React.useEffect(() => {
    const exec = async () => {
      if (perm !== 3) return;
      const access = await getAllAccess(domain);
      setAllAccess(access);
    };
    exec();
  }, [domain]);

  if (!plausible) console.warn("Error plausible");

  return (
    <AppContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        domain,
        setDomain,
        perm,
        setPerm,
        auth,
        setAuth,
        globalLoading,
        setGlobalLoading,
        apiOff,
        setApiOff,
        ESFDomain,
        setESFDomain,
        seo,
        setSeo,
        trackEvent,
        allAccess,
        menuOpen,
        setMenuOpen,
        menuRef,
        page,
        setPage,
        popupInfo,
        setPopupInfo,
        opinionSent,
        setOpinionSent,
      }}
      {...props}
    />
  );
};

export default AppContextProvider;
