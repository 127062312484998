import * as React from "react";
import * as Icons from "../../../icons";
import classnames from "classnames";
import RankButton from "./rank-button";
import { getSiteKind } from "../../../../utils/seo-sites";

const Ranking = ({
  data,
  device,
  kinds,
  setKinds,
  perm,
  urls,
  setUrls,
  seo,
}) => {
  const [number, setNumber] = React.useState(20);
  const [scrollPos, setScrollPos] = React.useState(0);

  React.useEffect(() => {
    if (
      !data ||
      !data.keywords ||
      !data.keywords[0] ||
      !data.keywords[0][device] ||
      !data.keywords[0][device].ranks
    )
      return;
    const newData = [...data.keywords];
    const sortedArray = newData.sort((a, b) => {
      if (!a[device].ranks[0] && !b[device].ranks[0]) return 0;
      if (!a[device].ranks[0]) return 100;
      if (!b[device].ranks[0]) return -100;
      const aList = a[device].ranks.filter((obj) => obj.rank < 21);
      const bList = b[device].ranks.filter((obj) => obj.rank < 21);
      return -(aList[aList.length - 1].rank - bList[bList.length - 1].rank);
    });
    const line = sortedArray[0][device].ranks.filter((obj) => obj.rank < 21);
    setNumber(line[line.length - 1].rank);
  }, [data, device]);

  const updateScrollPos = (e) => {
    const element = document.getElementById("scrollZone");
    let lastScrollTop = 0;
    if (element.scrollLeft < lastScrollTop) {
      setScrollPos(1);
      return;
    }
    lastScrollTop = element.scrollLeft <= 0 ? 0 : element.scrollLeft;
    if (element.scrollLeft === 0) {
      setScrollPos(0);
    } else if (
      element.scrollLeft + element.offsetWidth >=
      element.scrollWidth
    ) {
      setScrollPos(2);
    } else {
      setScrollPos(1);
    }
  };

  const previousButton = () => {
    const element = document.getElementById("scrollZone");
    const size = window.innerWidth < 695 ? 66 : 90;
    if (element.scrollLeft - size < 0) element.scrollLeft = 0;
    element.scrollLeft = element.scrollLeft - size;
  };

  const nextButton = () => {
    const element = document.getElementById("scrollZone");
    const size = window.innerWidth < 695 ? 66 : 90;
    if (element.scrollLeft + element.offsetWidth + size > element.scrollWidth)
      element.scrollLeft = element.scrollWidth - element.offsetWidth;
    element.scrollLeft = element.scrollLeft + size;
  };

  const previousClasses = classnames("seo-ranks__ranking__previous", {
    "seo-ranks__ranking__previous--max": scrollPos === 0,
  });
  const nextClasses = classnames("seo-ranks__ranking__next", {
    "seo-ranks__ranking__next--max": scrollPos === 2,
  });

  return (
    <div className="seo-ranks__ranking__zone" id="rankingZone">
      <button className={previousClasses} onClick={previousButton}>
        <Icons.Previous />
      </button>
      <button className={nextClasses} onClick={nextButton}>
        <Icons.Next />
      </button>
      <div
        className="seo-ranks__ranking"
        onScroll={(e) => updateScrollPos(e)}
        id="scrollZone"
      >
        {data &&
          data.keywords &&
          data.keywords[0] &&
          data.keywords[0][device] &&
          data.keywords[0][device].ranks && (
            <div className="seo-ranks__line seo-ranks__ranking__line seo-ranks__top__line">
              {[...Array(number).keys()].map((index) => {
                return (
                  <span
                    key={"num ranking" + index}
                    className="seo-ranks__ranking__numbers"
                  >
                    {index + 1}
                  </span>
                );
              })}
            </div>
          )}
        {data &&
          data.keywords &&
          data.keywords.map((keyword, lineIndex) => {
            if (!keyword[device] || !keyword[device].ranks) return null;
            return (
              <div
                key={keyword.keyword + "rankingline" + lineIndex}
                className="seo-ranks__line seo-ranks__ranking__line"
              >
                {keyword[device].ranks.map((rank) => {
                  if (rank.type !== "natural" && rank.type !== 0) return null;
                  const exist = getSiteKind(rank, kinds, seo);
                  return (
                    <RankButton
                      key={lineIndex + "ranking" + rank.rank}
                      rank={{
                        ...rank,
                        ...exist,
                      }}
                      keyword={keyword}
                      kinds={kinds}
                      setKinds={setKinds}
                      lineIndex={lineIndex}
                      perm={perm}
                      urls={urls}
                      setUrls={setUrls}
                      length={data.keywords.length - 1}
                      maxNumber={number}
                      seo={seo}
                    />
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Ranking;
