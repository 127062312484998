import * as React from "react";
import { getSpecificIncomes } from "../../../services/api";
import ComparativeLine from "./line";
import ComparativeLineSites from "./line-sites";
import ComparativeSelect from "./select";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
import ComparativeLineName from "./line-name";
import saveAsCSV from "../../../utils/saveAsCSV.js";
import ExportButton from "../../common/export";
import { useAnalysisContext } from "../../common/contexts/analysis-context";
import { updateSeoSeaIncomes } from "../../../utils/incomes";
import { useAppContext } from "../../common/contexts/app-context";
import { isESFDomain } from "../../../utils/seo";
import switchToDomain from "../../../utils/switch-to-domain";
import { addIncomeToFilter } from "../../../utils/comparative-incomes";
import { getIsoName } from "../../../data";

const EQUIVALENT = {
  Appareils: "device",
  Pays: "country",
  Sources: "source",
};

const Comparative = ({
  datePeriod,
  loading,
  setLoading,
  startIncomeLoading,
  setStartIncomeLoading,
  removeLoading,
  setRemoveLoading,
  setApiOff,
  globalLoading,
  setDomain,
  perm,
  filtersData,
}) => {
  const {
    selectedFilter,
    setSelectedFilter,
    country,
    devices,
    sources,
    setCountry,
    setDevices,
    setSources,
    updateAllPerfomancesData,
    selectedDetail,
    seoSeaData,
    values,
    firstTimeOpen,
    setFirstTimeOpen,
    charged,
    setCharged,
    updateSelectedDetail,
    tunnel,
    selectedProduct,
    domainsIncomes,
  } = useAnalysisContext();
  const analysisContext = useAnalysisContext();
  const { setESFDomain, trackEvent } = useAppContext();

  const updateFilter = (type, name) => {
    const index = selectedFilter.filters.type.findIndex(
      (elmt) => elmt === type
    );
    let filtersType = selectedFilter.filters.type;
    let filtersName = selectedFilter.filters.name;
    let filtersFilter = selectedFilter.filters.filter;
    if (index !== -1) {
      filtersType.splice(index, 1);
      filtersName.splice(index, 1);
      filtersFilter.splice(index, 1);
    }
    filtersType.push(type);
    filtersName.push(name);
    filtersFilter.push(type + name);
    setSelectedFilter({
      ...selectedFilter,
      filters: {
        type: filtersType,
        name: filtersName,
        filter: filtersFilter,
      },
    });
    updateAllPerfomancesData(
      {
        ...selectedFilter,
        filters: {
          type: filtersType,
          name: filtersName,
          filter: filtersFilter,
        },
      },
      datePeriod
    );
  };

  const getIncome = async (_selectedDetail) => {
    const toRemove = _selectedDetail ? _selectedDetail : selectedDetail;
    const list =
      toRemove === "Appareils"
        ? devices
        : toRemove === "Sources"
        ? sources
        : country;
    const fct =
      toRemove === "Appareils"
        ? setDevices
        : toRemove === "Sources"
        ? setSources
        : setCountry;
    const body = {
      kind: selectedFilter.kind,
      name: selectedFilter.name,
      dateTo: datePeriod.end,
      dateFrom: datePeriod.start,
      filters: selectedFilter.filters.filter[0]
        ? selectedFilter.filters.filter
        : undefined,
      type: EQUIVALENT[toRemove],
    };
    try {
      const response = await getSpecificIncomes(body);
      const detailResponse = addIncomeToFilter(list, response, fct);
      setRemoveLoading([...removeLoading, toRemove]);
      setApiOff(false);
      return detailResponse;
    } catch (e) {
      setApiOff(true);
    }
  };

  const clickOnSite = (value) => {
    trackEvent("Domain selection", { props: { name: value.name.domain_name } });
    setDomain(value.name.domain_name);
    window.localStorage.setItem("domain", value.name.domain_name);
    setESFDomain(isESFDomain(value.name.domain_name));
    switchToDomain(
      {
        name: value.name.domain_name,
        kind: "Sites",
        filters: {
          name: [],
          type: [],
          filter: [],
        },
      },
      datePeriod,
      value,
      analysisContext,
      trackEvent
    );
    setSelectedFilter({
      name: value.name.domain_name,
      kind: "Sites",
      filters: {
        name: [],
        type: [],
        filter: [],
      },
    });
  };

  devices.comparative.sort((a, b) => {
    return -(a.visitors - b.visitors);
  });
  country.comparative.sort((a, b) => {
    return -(a.visitors - b.visitors);
  });
  sources.comparative.sort((a, b) => {
    return -(a.visitors - b.visitors);
  });

  const productIndex = tunnel.products.findIndex(
    (product) => product === selectedProduct
  );
  const comparativeLines = [
    {
      detail: "Pays",
      array: country.comparative,
      filter: "visit:country==",
    },
    {
      detail: "Appareils",
      array: devices.comparative,
      filter: "visit:device==",
    },
    {
      detail: "Sources",
      array: sources.comparative,
      filter: "visit:source==",
    },
    {
      detail: "SEO / SEA",
      array: seoSeaData[productIndex],
    },
  ];
  const total = tunnel.data[0] ? tunnel.data[productIndex][3].price : 1;
  const affiliations = tunnel.affiliation[0]
    ? tunnel.affiliation[productIndex]
    : [];
  const downloadFile = saveAsCSV(
    selectedDetail,
    country,
    devices,
    sources,
    domainsIncomes,
    affiliations,
    seoSeaData[productIndex],
    selectedProduct
  );

  return (
    <div className="comparative">
      <div className="comparative__content">
        {globalLoading && (
          <div className="loaderbar">
            <LoaderBar className="loaderbar-valraiso-background" />
          </div>
        )}
        <div className="top-title">
          <span className="date-comparison__title">Détail comparatif</span>
          <ExportButton
            downloadFile={downloadFile}
            className="comparative__download"
          />
        </div>
        <div className="comparative__detail">
          <ComparativeSelect
            setSelectedDetail={updateSelectedDetail}
            selectedDetail={selectedDetail}
          />
          <ComparativeLineName
            value={selectedDetail}
            charged={charged}
            setCharged={setCharged}
            onClick={getIncome}
            loading={loading}
            setLoading={setLoading}
            firstTimeOpen={firstTimeOpen}
            setFirstTimeOpen={setFirstTimeOpen}
            updateSeoSeaIncomes={() =>
              updateSeoSeaIncomes(
                setApiOff,
                setLoading,
                loading,
                analysisContext,
                charged,
                setCharged,
                getIncome,
                setRemoveLoading
              )
            }
            startIncomeLoading={startIncomeLoading}
            setStartIncomeLoading={setStartIncomeLoading}
          />
          {values[0] &&
            comparativeLines.map((comparativeLine) => {
              if (
                selectedDetail !== comparativeLine.detail ||
                !comparativeLine.array[0]
              )
                return null;
              return comparativeLine.array.map((value, index) => (
                <ComparativeLine
                  key={index + value.name + comparativeLine.detail}
                  value={value}
                  index={`#${index + 1}`}
                  values={values}
                  selectedDetail={selectedDetail}
                  total={total}
                  selectedProduct={selectedProduct}
                  onClick={
                    comparativeLine.filter
                      ? () => {
                          const name =
                            comparativeLine.detail === "Pays"
                              ? getIsoName(value.name)
                              : value.name;
                          updateFilter(comparativeLine.filter, name);
                        }
                      : undefined
                  }
                />
              ));
            })}
          {values[0] &&
            selectedDetail === "Sites" &&
            domainsIncomes[0] &&
            domainsIncomes.map((value, index) => {
              return (
                <ComparativeLineSites
                  key={index + value.name.domain_name + "sites"}
                  value={value}
                  index={`#${index + 1}`}
                  values={values}
                  total={total}
                  sites={true}
                  product={selectedProduct}
                  onClick={
                    perm < 2 ||
                    (perm === 2 &&
                      !filtersData.find(
                        (elmt) =>
                          elmt.kind === "Sites" && elmt.name === value.name
                      ))
                      ? undefined
                      : () => clickOnSite(value)
                  }
                />
              );
            })}
          {values[0] &&
            selectedDetail === "Affiliations" &&
            affiliations[0] &&
            affiliations.map((value, index) => {
              return (
                <ComparativeLineSites
                  key={index + value.name + "affiliations"}
                  value={value}
                  index={`#${index + 1}`}
                  values={values}
                  sites={false}
                  total={total}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Comparative;
