import * as React from "react";
import Popup from "../../../../common/popup";

const GmapsPopup = ({ coords, value, setIsOpen }) => {
  return (
    <Popup
      className="seo-ranks__ranking__popup seo-popup-rank-infos--left seo-popup-badges"
      style={coords}
      buttons={[]}
      setIsOpen={setIsOpen}
    >
      <div className="seo-popup-rank-infos--flex">
        <span className="seo-popup-keyword__title">
          Google Maps <br />
          {value && value.keyword_name}
        </span>
        {value &&
          value.data &&
          value.data.map((elmt) => {
            return (
              <span
                key={
                  "popup gmaps urls" +
                  elmt.url +
                  elmt.rank +
                  value.keyword_name +
                  value.url_rank
                }
              >
                {elmt.rank}: {elmt.title}
                <br />
                <span className="seo-popup-rank-infos--urls">{elmt.url}</span>
                <br />
                <br />
              </span>
            );
          })}
      </div>
    </Popup>
  );
};

export default GmapsPopup;
