import { KEYWORDS } from "../data";

export const alphaSort = (array) => {
  return array.sort((a, b) => {
    var textA = a.toUpperCase();
    var textB = b.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
};

export const sortKeywords = (array) =>
  array
    .sort((a, b) => {
      var textA = a.keywords.keyword.toUpperCase();
      var textB = b.keywords.keyword.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    })
    .sort((a, b) => {
      const indexA = KEYWORDS.findIndex((elmt) =>
        a.keywords.keyword.startsWith(elmt)
      );
      const indexB = KEYWORDS.findIndex((elmt) =>
        b.keywords.keyword.startsWith(elmt)
      );
      const valueA = indexA === -1 ? 100 : indexA;
      const valueB = indexB === -1 ? 100 : indexB;
      return valueA - valueB;
    });

export const sortVolume = (array) =>
  array.sort((a, b) => {
    return b.keywords.volume - a.keywords.volume;
  });

export const sortSeo = (selectedSort, array, setArray, filters) => {
  const fct = selectedSort === "Volume" ? sortVolume : sortKeywords;
  const newArray = {};
  filters.forEach((filter) => {
    newArray[filter] = fct(
      array.keywords[filter].map((keyword, index) => {
        return {
          keywords: keyword,
          mobileVisibility: array.visibility
            ? array.visibility[filter].mobile[index]
            : null,
          desktopVisibility: array.visibility
            ? array.visibility[filter].desktop[index]
            : null,
        };
      })
    );
  });
  const keywords = {};
  const visibility = array.visibility ? { ...array.visibility } : undefined;
  filters.forEach((filter) => {
    keywords[filter] = newArray[filter].map((keyword) => {
      return keyword.keywords;
    });
    if (array.visibility) {
      visibility[filter].mobile = newArray[filter].map((keyword) => {
        return keyword.mobileVisibility;
      });
      visibility[filter].desktop = newArray[filter].map((keyword) => {
        return keyword.desktopVisibility;
      });
    }
  });
  setArray({ keywords, visibility });
};
