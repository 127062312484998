const incomeFormat = (income) => {
  return String(income)
    .split("")
    .reverse()
    .join("")
    .match(/.{1,3}/g)
    .join(" ")
    .split("")
    .reverse()
    .join("");
};

export default incomeFormat;
