import * as React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  Bar,
  Line,
} from "recharts";
import AdsImpactChartSales from "./sales-chart";
import { useSEOAdsImpactContext } from "../common/contexts/seo-ads-impact";
import CustomTooltip from "./tooltip";
import AdsImpactLegend from "./legend";
import { salesColors } from "../../data";

const AdsImpactChart = () => {
  const { data, groupDevices, groupFilters, filters, breakdown } =
    useSEOAdsImpactContext();
  const bars = groupDevices ? [""] : ["mobile", "desktop"];
  const filterArray = groupFilters ? ["all"] : filters;
  const colors = ["#fbbc04", "#fdd868"];

  if (data.length === 0) return null;

  const allBars = filterArray.reduce((acc, filter) => {
    const filterBars = bars.map((bar, index) => {
      return {
        key: filter + "bar" + index + "stack1" + bar,
        stackId: filter,
        dataKey: filter + bar,
        fill: colors[index],
      };
    });
    return [...acc, ...filterBars];
  }, []);

  return (
    <>
      <div className="seo-ads-impact__legend">
        {breakdown.map((detail) => {
          return (
            <AdsImpactLegend
              key={"legend" + detail}
              label={detail}
              color={salesColors[detail]}
            />
          );
        })}
      </div>
      <div className="seo-study-chart seo-ads-impact__chart">
        <AdsImpactChartSales />
        <ComposedChart
          width={(window.innerWidth * 70) / 100}
          height={(window.innerWidth * 20) / 100}
          data={data}
          syncId="anyId"
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          barCategoryGap={
            !groupFilters ? 0 : Math.round(window.innerWidth / 100) - 5
          }
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis
            yAxisId="leftLine"
            dataKey="visitors"
            orientation="left"
            stroke="#1a73e8"
            label={{
              value: "Visiteurs",
              angle: -90,
              position: "insideLeft",
              fill: "#1a73e8",
            }}
          />
          <YAxis
            yAxisId="rightBar"
            dataKey="all"
            orientation="right"
            stroke="#fbbc04"
            label={{
              value: "Adwords",
              angle: 90,
              position: "insideRight",
              fill: "#fbbc04",
            }}
          />
          <Tooltip content={<CustomTooltip adwords={true} />} />
          {allBars.map((bar) => {
            return (
              <Bar
                key={bar.key}
                stackId={bar.stackId}
                type="monotone"
                dataKey={bar.dataKey}
                fill={bar.fill}
                yAxisId="rightBar"
              />
            );
          })}
          <Line
            yAxisId="leftLine"
            type="monotone"
            dataKey="visitors"
            stroke="#1a73e8"
          />
        </ComposedChart>
      </div>
    </>
  );
};

export default AdsImpactChart;
