import * as React from "react";
import * as Icons from "../icons";
import classnames from "classnames";

const Array = ({
  values,
  setValues,
  className,
  onRankChange,
  onRankRemove,
  onDetailClick,
}) => {
  const [edition, setEdition] = React.useState(false);
  const classes = classnames("array", className);
  const lineClasses = classnames("array__line", {
    "array__line--edition": edition,
  });

  const changeRank = (rank, newRank) => {
    onRankChange?.({ rank, newRank });
    const firstRank = rank < newRank ? rank : newRank;
    const secondRank = rank < newRank ? newRank : rank;
    const newValues = [
      ...values.slice(0, firstRank),
      values[secondRank],
      values[firstRank],
      ...values.slice(secondRank + 1),
    ];
    setValues(newValues);
  };

  const removeRank = (rank) => {
    onRankRemove?.(rank);
    const newValues = [...values.slice(0, rank), ...values.slice(rank + 1)];
    setValues(newValues);
  };
  if (!values[0]) return null;

  return (
    <table className={classes} cellSpacing="0">
      <thead>
        <tr className="array__top-line array__line">
          {values[0].map((value, index) => {
            return (
              <th
                key={value.name + "array's head"}
                className="array__line__case"
              >
                {value.name}
                {index + 1 === values[0].length && (
                  <button
                    className="array__settings__button"
                    onClick={() => setEdition(!edition)}
                  >
                    <Icons.Settings />
                  </button>
                )}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {values.map((value, index) => {
          return (
            <tr
              key={value[0].value + value[0].name + "column"}
              className={lineClasses}
            >
              <th scope="row" className="array__line__case">
                {index !== 0 && (
                  <button
                    className="array__line__edition__top"
                    onClick={() => changeRank(index, index - 1)}
                  >
                    <Icons.ArrowDropUp />
                  </button>
                )}
                {value[0].value}
                {index + 1 !== values.length && (
                  <button
                    className="array__line__edition__bottom"
                    onClick={() => changeRank(index, index + 1)}
                  >
                    <Icons.ArrowDropDown />
                  </button>
                )}
              </th>
              {value.slice(1).map((bloc, _index) => {
                return (
                  <td
                    key={bloc.value + bloc.name + "case"}
                    className="array__line__case"
                  >
                    {bloc.value}
                    {(bloc.detail || true) && (
                      <button
                        className="array__line__case__see-more"
                        onClick={() => onDetailClick(value)}
                      >
                        <Icons.SeeMore />
                      </button>
                    )}
                    {_index + 2 === value.length && (
                      <button
                        className="array__settings__remove__button"
                        onClick={() => removeRank(index)}
                      >
                        <Icons.Remove />
                      </button>
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Array;
