import * as React from "react";
import * as Icons from "../icons";
import classnames from "classnames";
import AdminFilters from "../performances/toolbar/admin";
import Button from "@valraiso-esf/esf-ui/es/button";
import { useAppContext } from "../common/contexts/app-context";

const NavigationTop = ({ setLoggedIn, page, perm, setDomain, filtersData }) => {
  const { setMenuOpen, menuOpen, menuRef } = useAppContext();
  const classes = classnames("navigation__valraiso__menu", {
    "navigation__valraiso__menu--open": menuOpen,
  });

  return (
    <div className="navigation">
      <div className="navigation__menu__zone">
        <button
          className={classes}
          onClick={() => setMenuOpen(!menuOpen)}
          ref={menuRef}
        >
          {menuOpen ? <Icons.Close /> : <Icons.Menu />}
        </button>
        <span className="navigation__valraiso__menu__box" />

        <label className="navigation__title--menu">
          <Icons.PeekPerformances className="navigation__valraiso__logo" />
          <Icons.PeekPerformancesLogo className="navigation__valraiso__logo--without-name" />
        </label>
      </div>
      <div className="navigation__user">
        {perm > 0 && (
          <AdminFilters
            setDomain={setDomain}
            filtersData={filtersData}
            page={page}
          />
        )}
        <Button
          className="navigation__deconnexion"
          color="tertiary"
          variant="outlined"
          iconLeft={<Icons.Logout />}
          onClick={() => {
            setLoggedIn(false);
            window.localStorage.removeItem("password");
            window.localStorage.removeItem("auth");
            window.localStorage.removeItem("token");
          }}
        >
          Déconnexion
        </Button>
      </div>
    </div>
  );
};

export default NavigationTop;
