import * as React from "react";
import ReactSlider from "react-slider";

const SaleTunnelSlider = ({ values, product, isOpen }) => {
  const [pos, setPos] = React.useState([0, 4]);
  const [data, setData] = React.useState(0);

  const updatePos = (e) => {
    setPos(e);
  };

  React.useEffect(() => {
    if (!values || !values[0] || !product || !product[0]) return;
    const left = pos[0] === 0 ? values[0].new : product[pos[0] - 1].number;
    const right = product[pos[1] - 1].number;
    const percentage =
      right === 0
        ? 0
        : left !== 0
        ? Math.round(((right * 100) / left) * 10) / 10
        : "♾️";
    setData(percentage);
  }, [values, product, pos]);

  return (
    <div className="slider">
      <div className="slider__zone">
        <ReactSlider
          className="sale-tunnel-slider"
          marks
          min={0}
          max={4}
          thumbClassName="sale-tunnel-slider__thumb"
          trackClassName="sale-tunnel-slider__customSlider-track"
          ariaLabel={["Left", "Right"]}
          defaultValue={pos}
          value={pos}
          orientation={"horizontal"}
          pearling
          minDistance={1}
          onChange={updatePos}
          disabled={!!isOpen}
        />
      </div>
      <div className="slider__zone__vertical">
        <ReactSlider
          className="sale-tunnel-slider-2"
          marks
          min={0}
          max={4}
          thumbClassName="sale-tunnel-slider-2__thumb"
          trackClassName="sale-tunnel-slider-2__customSlider-track"
          ariaLabel={["Left", "Right"]}
          defaultValue={pos}
          value={pos}
          orientation={"vertical"}
          pearling
          minDistance={1}
          onChange={updatePos}
          disabled={!!isOpen}
        />
      </div>
      <div className="slider__data__center">
        <span className="slider__data">Taux de conversion </span>
        {data === "♾️" ? (
          <span className="slider__data__percentage">&#8734;%</span>
        ) : (
          <span className="slider__data__percentage">{data}%</span>
        )}
      </div>
    </div>
  );
};

export default SaleTunnelSlider;
