import * as React from "react";
import classnames from "classnames";

const SwitchButton = ({
  value,
  onValueChanged,
  values = ["black", "white"],
  className,
}) => {
  const classes = classnames("switch-button", className);
  const switchClasses = classnames("switch-button__back", {
    "switch-button__back--left": value === values[0],
    "switch-button__back--right": value === values[1],
  });

  return (
    <div className={classes}>
      <div className={switchClasses} />
      <button
        className="switch-button__button"
        onClick={() => onValueChanged(values[0])}
      >
        {values[0]}
      </button>
      <button
        className="switch-button__button"
        onClick={() => onValueChanged(values[1])}
      >
        {values[1]}
      </button>
    </div>
  );
};

export default SwitchButton;
