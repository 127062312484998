import * as React from "react";
import * as Icons from "../../icons";
import Filters from "./filters";
import FilterSuggestion from "../../common/filter/filter-suggestion";
import getCountryName from "../../../data";
import { useAnalysisContext } from "../../common/contexts/analysis-context";

const AllFilters = ({ globalLoading, filters }) => {
  const {
    setSelectedFilter,
    updateAllPerfomancesData,
    selectedFilter,
    datePeriod,
    country,
    sources,
    devices,
    tunnel,
    selectedProduct,
    setSelectedProduct,
  } = useAnalysisContext();

  const updateFilter = (type, name) => {
    console.log(type, name);
    const index = selectedFilter.filters.type.findIndex(
      (elmt) => elmt === type
    );
    let filtersType = selectedFilter.filters.type;
    let filtersName = selectedFilter.filters.name;
    let filtersFilter = selectedFilter.filters.filter;
    if (index !== -1) {
      filtersType.splice(index, 1);
      filtersName.splice(index, 1);
      filtersFilter.splice(index, 1);
    }
    filtersType.push(type);
    filtersName.push(name);
    filtersFilter.push(type + name);
    setSelectedFilter({
      ...selectedFilter,
      filters: {
        type: filtersType,
        name: filtersName,
        filter: filtersFilter,
      },
    });
    updateAllPerfomancesData(
      {
        ...selectedFilter,
        filters: {
          type: filtersType,
          name: filtersName,
          filter: filtersFilter,
        },
      },
      datePeriod
    );
  };

  const removeFilter = (index) => {
    let filtersType = selectedFilter.filters.type;
    let filtersName = selectedFilter.filters.name;
    let filtersFilter = selectedFilter.filters.filter;
    if (index !== -1) {
      filtersType.splice(index, 1);
      filtersName.splice(index, 1);
      filtersFilter.splice(index, 1);
    }
    setSelectedFilter({
      ...selectedFilter,
      filters: {
        type: filtersType,
        name: filtersName,
        filter: filtersFilter,
      },
    });
    updateAllPerfomancesData(
      {
        ...selectedFilter,
        filters: {
          type: filtersType,
          name: filtersName,
          filter: filtersFilter,
        },
      },
      datePeriod
    );
  };

  const updateSelection = (elmt) => {
    setSelectedFilter({
      ...selectedFilter,
      name: elmt.name,
      kind: elmt.kind,
    });
    updateAllPerfomancesData(
      {
        ...selectedFilter,
        name: elmt.name,
        kind: elmt.kind,
      },
      datePeriod
    );
  };

  const sourceFilter = selectedFilter.filters.type.findIndex(
    (elmt) => elmt === "visit:source=="
  );
  const countryFilter = selectedFilter.filters.type.findIndex(
    (elmt) => elmt === "visit:country=="
  );
  const deviceFilter = selectedFilter.filters.type.findIndex(
    (elmt) => elmt === "visit:device=="
  );
  const productFilter = tunnel.products.map((product) => {
    return { name: product };
  });

  return (
    <div className="all-filters">
      <div className="all-filters__buttons">
        <Filters
          defaultValue={selectedFilter.name}
          filters={filters}
          icon={Icons.Search}
          callBack={updateSelection}
        />
        <Filters
          defaultValue={selectedProduct}
          filters={productFilter}
          callBack={(e) => setSelectedProduct(e.name)}
          icon={Icons.Cart}
          lock={globalLoading}
        />
        <FilterSuggestion
          defaultValue="Pays"
          icon={Icons.Country}
          globalLoading={globalLoading}
          value={
            countryFilter !== -1
              ? getCountryName(selectedFilter.filters.name[countryFilter])
              : undefined
          }
          onRemove={() => removeFilter(countryFilter)}
          array={country.comparative}
          updateFilter={updateFilter}
          isCountry={true}
          type="visit:country=="
        />
        <FilterSuggestion
          defaultValue="Sources"
          icon={Icons.Sources}
          globalLoading={globalLoading}
          value={
            sourceFilter !== -1
              ? selectedFilter.filters.name[sourceFilter]
              : undefined
          }
          onRemove={() => removeFilter(sourceFilter)}
          array={sources.comparative}
          updateFilter={updateFilter}
          type="visit:source=="
        />
        <FilterSuggestion
          defaultValue="Appareils"
          icon={Icons.Devices}
          disabled={globalLoading}
          value={
            deviceFilter !== -1
              ? selectedFilter.filters.name[deviceFilter]
              : undefined
          }
          onRemove={() => removeFilter(deviceFilter)}
          array={devices.comparative}
          updateFilter={updateFilter}
          type="visit:device=="
        />
      </div>
    </div>
  );
};

export default AllFilters;
