import * as React from "react";
import * as Icons from "../icons";
import SettingsEditAlertLine from "./alert-edit-line";
import SettingsAlertLine from "./alert-line";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
// import SearchBar from "../common/search-bar";
import { useSettingsContext } from "../common/contexts/settings-context";

const SettingsAlertArray = ({ showedAlerts }) => {
  const { alerts, setAlerts } = useSettingsContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const addLine = () => {
    setAlerts([
      ...alerts,
      {
        keyword: "",
        filter: "",
        edit: true,
        new: true,
        device: "ordinateur",
      },
    ]);
    setTimeout(() => {
      const element = document.getElementById("settings-array");
      element.scrollTop = element.scrollHeight;
    }, 100);
  };

  return (
    <div className="settings-alerts__array__zone">
      <div className="settings-alerts__array">
        <SettingsAlertLine
          alert={{ keyword: "Mot-clé", filter: "Filtre", device: "Appareil" }}
          className="settings-alerts__array__title"
          editable={false}
          // search={
          //   <SearchBar
          //     value={searchedValue}
          //     onChange={(e) => setSearchedValue(e)}
          //     Icon={<Icons.Search />}
          //     className="settings-alerts__searchbar"
          //     options={alerts.map((alert) => {
          //       return alert.keyword;
          //     })}
          //   />
          // }
        />
        {isLoading && <LoaderBar className="settings-alerts__loaderbar" />}
      </div>
      <div className="settings-alerts__array__lines" id="settings-array">
        <div className="settings-alerts__array">
          {alerts.map((alert, index) => {
            const show = showedAlerts.find(
              (obj) =>
                alert.keyword === obj.keyword &&
                alert.filter === obj.filter &&
                obj.device === alert.device
            );
            if (!show) return null;
            if (alert.edit) {
              return (
                <SettingsEditAlertLine
                  key={
                    "alert" +
                    alert.keyword +
                    alert.filter +
                    alert.device +
                    index
                  }
                  alert={alert}
                  editable={true}
                  index={index}
                  setIsLoading={setIsLoading}
                />
              );
            }
            return (
              <SettingsAlertLine
                key={
                  "alert" + alert.keyword + alert.filter + alert.device + index
                }
                alert={alert}
                editable={true}
                index={index}
              />
            );
          })}
        </div>
      </div>
      <div className="settings-alerts__array">
        <div className="settings-alerts__array__line">
          <button
            className="settings-alerts__array__line__add"
            onClick={addLine}
          >
            <Icons.Add />
            Ajouter une ligne
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsAlertArray;
