import * as React from "react";
import Toolbar from "../performances/toolbar/toolbar";
import Statistics from "../performances/statistics";
import ApiOff from "./api-off";
import useSetPage from "../../hooks/use-set-page";

const Analysis = ({
  setDomain,
  domain,
  perm,
  filtersData,
  setApiOff,
  apiOff,
  filters,
  setPage,
  globalLoading,
}) => {
  useSetPage(setPage, "Analyse performances");

  return (
    <>
      {apiOff ? (
        <ApiOff globalLoading={globalLoading} />
      ) : (
        <>
          <Toolbar filters={filters} globalLoading={globalLoading} />
          <Statistics
            setDomain={setDomain}
            domain={domain}
            perm={perm}
            filtersData={filtersData}
            setApiOff={setApiOff}
            globalLoading={globalLoading}
          />
        </>
      )}
    </>
  );
};

export default Analysis;
