import getCountryName from "../../../data";
import classnames from "classnames";
import incomeFormat from "../../../utils/incomeFormat";
import { divNumber } from "../../../utils/reduce";

const ComparativeLine = ({
  value,
  index,
  values,
  onClick,
  selectedDetail,
  total,
  selectedProduct,
}) => {
  const isFilter =
    selectedDetail !== "Affiliations" &&
    selectedDetail !== "Sites" &&
    selectedDetail !== "SEO / SEA";

  const classes = classnames("comparative-line__cell__spe", {
    "comparative-line__cell__spe__clickable": onClick,
    "comparative-line__cell__spe--small": isFilter,
    "comparative-line__cell__spe--big": selectedDetail === "Affiliations",
  });
  const lineClasses = classnames("comparative-line__row", {
    "comparative-line__row--odd": Number(index.replace("#", "")) % 2 === 1,
  });
  const price = value.price
    ? value.price
    : value.data &&
      value.data[selectedProduct] &&
      value.data[selectedProduct].price
    ? value.data[selectedProduct].price
    : 0;
  const number = value.number
    ? value.number
    : value.data &&
      value.data[selectedProduct] &&
      value.data[selectedProduct].number
    ? value.data[selectedProduct].number
    : "-";

  return (
    <div className={lineClasses}>
      <span className="comparative-line__cell__num">{index}</span>
      <span className={classes} onClick={onClick && onClick}>
        {selectedDetail === "Pays" ? getCountryName(value.name) : value.name}
      </span>
      <span
        className="comparative-line__cell__vu"
        percentage={`(${divNumber(
          value.visitors,
          values[0] ? values[0].new : 0
        )}%)`}
      >
        {value.visitors}
      </span>
      {isFilter && (
        <span className="comparative-line__cell__tdc">
          {number !== "-" ? `${divNumber(number, value.visitors)}%` : "-"}
        </span>
      )}
      {(selectedDetail === "Sites" || selectedDetail === "SEO / SEA") && (
        <>
          <span className="comparative-line__cell__tdc">
            {number !== "-" ? `${divNumber(number, value.visitors)}%` : "-"}
          </span>
          <span
            className="comparative-line__cell__tdp comparative-line__cell__tdp__data"
            success={"-"}
            failure={"-"}
          >
            {" "}
            /{" "}
          </span>
        </>
      )}
      <span className="comparative-line__cell__nbr-command">{number}</span>
      <span
        className="comparative-line__cell__ca"
        percentage={price ? `(${divNumber(price, total)}%)` : ""}
      >
        {price ? incomeFormat(price) : "-"}
      </span>
    </div>
  );
};

export default ComparativeLine;
