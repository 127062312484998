import * as React from "react";
import classnames from "classnames";

const BUTTONS = [
  "Sites",
  "Pays",
  "Sources",
  "Appareils",
  "Affiliations",
  "SEO / SEA",
];

const ComparativeSelect = ({ setSelectedDetail, selectedDetail }) => {
  return (
    <div className="comparative-select">
      {BUTTONS.map((button) => {
        const classes = classnames("comparative-select__button", {
          "comparative-select__button--selected": button === selectedDetail,
        });
        return (
          <button
            key={"comparative" + button}
            className={classes}
            onClick={() => setSelectedDetail(button)}
          >
            {button}
          </button>
        );
      })}
    </div>
  );
};

export default ComparativeSelect;
