import * as React from "react";
import classnames from "classnames";
import { useAppContext } from "../common/contexts/app-context";

const UsersAccess = ({ setCopied, menuOpen }) => {
  const { allAccess } = useAppContext();

  const copyToClipboard = async (access) => {
    const { link, name } = access;
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(link);
    } else {
      document.execCommand("copy", true, link);
    }
    setCopied(name);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const classes = classnames("users-access__selector", {
    "users-access__selector--small": !menuOpen,
  });
  return (
    <div className={classes}>
      {allAccess.map((access) => {
        return (
          <button
            key={"access" + access.link}
            onClick={() => copyToClipboard(access)}
          >
            {access.name}
          </button>
        );
      })}
    </div>
  );
};

export default UsersAccess;
