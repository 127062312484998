import * as React from "react";
import { getNationalFilters, getNationalSeoData } from "../../../services/api";

const NationalSEOContext = React.createContext();

export const useNationalSEOContext = () => {
  const context = React.useContext(NationalSEOContext);
  if (!context)
    throw new Error(
      "useNationalSEOContext must be used within a NationalSEOContextProvider"
    );
  return context;
};

const NationalSEOContextProvider = (props) => {
  const [seoOff, setSeoOff] = React.useState(false);
  const [device, setDevice] = React.useState("Ordinateur");
  const [keyword, setKeyword] = React.useState({
    keyword: "esf",
    filter: "fr",
  });
  const [geograhicZone, setGeograhicZone] = React.useState("Alpes du Sud");
  const [isFiltersLoading, setIsFiltersLoading] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState();
  const [kinds, setKinds] = React.useState();
  const [specificities, setSpecificities] = React.useState();
  const [urls, setUrls] = React.useState({});
  const [keywordsList, setKeywordsList] = React.useState(["esf"]);
  const [stats, setStats] = React.useState([]);
  const [rankSync, setRankSync] = React.useState();

  React.useEffect(() => {
    const exec = async () => {
      setIsFiltersLoading(true);
      const response = await getNationalFilters();
      setKeywordsList(response.keywords);
      setSpecificities(response.regions);
      setKinds(response.kinds);
      setUrls(response.urls);
      setRankSync(response.rankSync);
      setIsFiltersLoading(false);
    };
    exec();
  }, []);

  React.useEffect(() => {
    const exec = async () => {
      setIsLoading(true);
      const response = await getNationalSeoData({
        keywordStart: keyword.keyword,
        filter: keyword.filter,
        region: geograhicZone,
      });
      if (!response.data) {
        setIsLoading(false);
        setSeoOff(true);
        return;
      }
      setData({ keywords: response.data });
      setStats(response.stats);
      setIsLoading(false);
      setSeoOff(false);
    };
    exec();
  }, [geograhicZone, keyword, setSeoOff]);

  return (
    <NationalSEOContext.Provider
      value={{
        isLoading,
        isFiltersLoading,
        seoOff,
        rankSync,
        geograhicZone,
        setGeograhicZone,
        specificities,
        keyword,
        setKeyword,
        keywordsList,
        device,
        setDevice,
        stats,
        data,
        kinds,
        setKinds,
        urls,
        setUrls,
      }}
      {...props}
    />
  );
};

export default NationalSEOContextProvider;
