import { searchEngines } from "../data";
import { roundNumber, roundReducer } from "./reduce";

export const filterSourcesAndAffiliations = (sources, tunnel) => {
  const allSearchEnginesVisitors = sources.comparative.reduce(
    (total, source) => {
      if (searchEngines.includes(source.name)) return total + source.visitors;
      return total;
    },
    0
  );
  return tunnel.affiliation.map((affiliations) => {
    const adWords = affiliations.filter(
      (affiliation) =>
        affiliation.name.includes("AdWords") ||
        affiliation.name.includes("Adwords")
    );
    const adWordsVisitors = adWords.reduce(
      (total, adWord) => total + adWord.visitors,
      0
    );
    return [
      {
        name: "Moteurs de recherche",
        visitors:
          allSearchEnginesVisitors - adWordsVisitors < 0
            ? 0
            : allSearchEnginesVisitors - adWordsVisitors,
      },
      ...adWords,
    ];
  });
};

export const filterSearchEngines = (sources) => {
  return sources.reduce((acc, source) => {
    if (searchEngines.includes(source.name)) return [...acc, source.name];
    return acc;
  }, []);
};

const addSearchEngineRate = (array, searchEnginesRate) => {
  array.forEach((elmt) => {
    elmt["conversion"] = {
      tdc: roundNumber(elmt.successNumber, elmt.visitors, 10),
      tdcS: elmt.success ? elmt.success : 0,
      tdcF: elmt.failure ? elmt.failure : 0,
    };
  });
  const totalSuccess = roundReducer(array, "success");
  const totalFailure = roundReducer(array, "failure");
  const totalBegin = roundReducer(array, "begin");
  array[0].conversion.tdcS =
    searchEnginesRate["MseM: Payment success"] - totalSuccess;
  array[0].conversion.tdcF =
    searchEnginesRate["MseM: Payment failure"] - totalFailure;
  array[0].begin = searchEnginesRate["MseM: Begin checkout"] - totalBegin;
  array.forEach((elmt) => {
    elmt.conversion.tdcS = roundNumber(elmt.conversion.tdcS, elmt.begin, 10);
    elmt.conversion.tdcF = roundNumber(elmt.conversion.tdcF, elmt.begin, 10);
  });
};

export const addIncomesToSearchEngines = (
  successData,
  seoSeaIncomes,
  seoSeaData,
  setSeoSeaData,
  hoteIdsRate,
  searchEnginesRate
) => {
  const array = seoSeaData.map((obj, index) => {
    if (index === 0) {
      return obj;
    }
    const exist = successData.find((adWord) => adWord.name === obj.name);
    if (!exist) return obj;
    return {
      ...obj,
      totalSuccess: exist.price,
      successNumber: exist.number,
      ...hoteIdsRate[exist.hoteId],
    };
  });
  const totalSuccessAds = roundReducer(array, "totalSuccess");
  const successNumberAds = roundReducer(array, "successNumber");
  array[0].successNumber =
    seoSeaIncomes.successNumber - successNumberAds < 0
      ? 0
      : seoSeaIncomes.successNumber - successNumberAds;
  array[0].totalSuccess =
    seoSeaIncomes.totalSuccess - totalSuccessAds < 0
      ? 0
      : seoSeaIncomes.totalSuccess - totalSuccessAds;
  addSearchEngineRate(array, searchEnginesRate);
  setSeoSeaData(array);
};

export const addIncomesToSearchEnginesWithSources = (
  successData,
  sources,
  seoSeaData,
  setSeoSeaData,
  hoteIdsRate,
  searchEnginesRate
) => {
  const sourcesSuccess = sources.reduce((total, data) => {
    if (!searchEngines.includes(data.name)) return total;
    return total + data.totalSuccess;
  }, 0);
  const sourcesNumber = sources.reduce((total, data) => {
    if (!searchEngines.includes(data.name)) return total;
    return total + data.successNumber;
  }, 0);
  const array = seoSeaData.map((obj, index) => {
    if (index === 0) return obj;
    const exist = successData.find((adWord) => adWord.name === obj.name);
    if (!exist) return obj;
    return {
      ...obj,
      totalSuccess: exist.price,
      successNumber: exist.number,
      ...hoteIdsRate[exist.hoteId],
    };
  });
  const totalSuccessAds = roundReducer(array, "totalSuccess");
  const successNumberAds = roundReducer(array, "successNumber");
  array[0].successNumber =
    sourcesNumber - successNumberAds < 0 ? 0 : sourcesNumber - successNumberAds;
  array[0].totalSuccess =
    sourcesSuccess - totalSuccessAds < 0 ? 0 : sourcesSuccess - totalSuccessAds;
  addSearchEngineRate(array, searchEnginesRate);
  setSeoSeaData(array);
};
