import * as React from "react";
import {
  getSEOClients,
  getClientFilters,
  getSitesTypes,
  getStudyData,
} from "../../../services/api";

const SEOStudyContext = React.createContext();

export const useSEOStudyContext = () => {
  const context = React.useContext(SEOStudyContext);
  if (!context)
    throw new Error(
      "useSEOStudyContext must be used within a SEOStudyContextProvider"
    );
  return context;
};

const SEOStudyContextProvider = (props) => {
  const [filters, setFilters] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState("fr");
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState({ mobile: [], desktop: [] });
  const [kinds, setKinds] = React.useState([]);
  const [competitor, setCompetitor] = React.useState();
  const [clients, setClients] = React.useState([]);
  const [selectedClient, setSelectedClient] = React.useState({});

  React.useEffect(() => {
    const exec = async () => {
      setClients([]);
      const allClients = await getSEOClients();
      const [allFilters, allKinds] = await Promise.all([
        getClientFilters(allClients[0].id),
        getSitesTypes(allClients[0].id),
      ]);
      setSelectedClient(allClients[0]);
      setFilters(allFilters);
      setKinds(allKinds);
      setTimeout(() => setClients(allClients), 2000);
    };
    exec();
  }, []);

  React.useEffect(() => {
    const exec = async () => {
      if (competitor === undefined) return;
      setIsLoading(true);
      const data = await getStudyData({
        ...competitor,
        filter: selectedFilter,
        seo: selectedClient.id,
      });
      setData({ mobile: data.mobile, desktop: data.desktop });
      setIsLoading(false);
    };
    exec();
  }, [competitor, selectedFilter, selectedClient.id]);

  return (
    <SEOStudyContext.Provider
      value={{
        competitor,
        filters,
        isLoading,
        data,
        kinds,
        clients,
        selectedFilter,
        selectedClient,
        setSelectedFilter,
        setCompetitor,
        setData,
        setSelectedClient,
        setFilters,
        setKinds,
      }}
      {...props}
    />
  );
};

export default SEOStudyContextProvider;
