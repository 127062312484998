export const getKindGroup = (kindGroup, kinds, seo) => {
  const proposition =
    kindGroup &&
    kinds &&
    kinds[seo][0] &&
    !kinds[seo].find((kind) => kind.kindGroup === kindGroup);

  return proposition
    ? "Créer un type"
    : kindGroup
    ? kindGroup
    : kinds && kinds[seo][0]
    ? kinds[seo][0].kindGroup
    : "";
};

export const getKindGroupName = (kindGroup, kinds, seo) => {
  const proposition =
    kindGroup &&
    kinds &&
    kinds[seo][0] &&
    !kinds[seo].find((kind) => kind.kindGroup === kindGroup);

  return proposition ? kindGroup : "";
};

export const getKind = (kindGroup, kind, kinds, seo) => {
  const proposition =
    kindGroup &&
    kind &&
    kinds &&
    kinds[seo][0] &&
    !kinds[seo].find(
      (elmt) => elmt.kindGroup === kindGroup && elmt.kind === kind
    );

  return proposition
    ? "Créer un groupe"
    : kind
    ? kind
    : kinds && kinds[seo][0]
    ? kinds[seo][0].kindGroup
    : "Créer un groupe";
};

export const getKindName = (kindGroup, kind, kinds, seo) => {
  const proposition =
    kindGroup &&
    kind &&
    kinds &&
    kinds[seo][0] &&
    !kinds[seo].find(
      (elmt) => elmt.kindGroup === kindGroup && elmt.kind === kind
    );

  return proposition
    ? kind
    : kind
    ? kind
    : kinds && kinds[seo][0]
    ? kinds[seo][0].kindGroup
    : "";
};
