import * as React from "react";
import * as Icons from "../../../icons";
import classnames from "classnames";
import SetKindPopup from "./popups/set-kind";
import RankInfosPopup from "./popups/rank-infos";
import { isAlpeDhuez } from "../../../../utils/seo-type";

const RankButton = ({
  rank,
  kinds,
  setKinds,
  lineIndex,
  perm,
  urls,
  setUrls,
  keyword,
  length,
  maxNumber,
  seo,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [coords, setCoords] = React.useState({ left: "0px", top: "0px" });
  const [changeSettings, setChangeSettings] = React.useState(false);
  const ref = React.useRef();

  const classes = classnames("seo-ranks__ranking__button", {
    "seo-ranks__ranking__button--esf": rank.kindGroup === "ESF",
    "seo-ranks__ranking__button--alpedhuez": isAlpeDhuez(rank.url),
  });

  const onClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", onClickOutside);
    return () => {
      document.body.removeEventListener("click", onClickOutside);
    };
  }, []);

  const getMousePosition = () => {
    const newCoordsY =
      lineIndex >= 6
        ? { bottom: "0px" }
        : length >= 6
        ? { top: `calc(${lineIndex} * var(--seo-ranks-height) * -1)` }
        : {
            bottom: `calc(${
              length - lineIndex
            } * var(--seo-ranks-height) * -1)`,
          };
    const newCoordsX =
      rank.rank > maxNumber - 6 ? { right: "75px" } : { left: "75px" };
    setCoords({ ...newCoordsX, ...newCoordsY });
  };
  const searchConsoleCmp = keyword.searchConsole;

  return (
    <div className="seo-ranks__ranking__button__zone" ref={ref}>
      <button
        className={classes}
        onClick={() => {
          setIsOpen(!isOpen);
          getMousePosition();
        }}
        precision={
          rank.kindGroup === "ESF" && searchConsoleCmp !== rank.searchConsole
            ? rank.name
            : ""
        }
      >
        {rank.kindGroup === "ESF" &&
        (rank.kind === "ESF national" || rank.searchConsole !== null)
          ? "ESF"
          : !urls[seo][rank.kind] &&
            !urls.all[rank.kind] &&
            rank.kindGroup &&
            rank.kindGroup !== "ESF"
          ? rank.name
            ? rank.name
            : rank.kind
          : ""}

        {(urls[seo][rank.kind] || urls.all[rank.kind]) && (
          <img
            src={
              urls[seo][rank.kind] ? urls[seo][rank.kind] : urls.all[rank.kind]
            }
            alt={rank.kind}
            style={
              window.innerWidth < 695
                ? {
                    width: "55px",
                    height: "17px",
                    objectFit: "scale-down",
                  }
                : {
                    width: "60px",
                    height: "20px",
                    objectFit: "scale-down",
                  }
            }
          />
        )}
        {(!rank.shortUrl || rank.shortUrl === null) && (
          <Icons.Unknown className="seo-ranks__ranking__button--unknown" />
        )}
      </button>
      {((rank.shortUrl === null && isOpen) || changeSettings) && (
        <SetKindPopup
          rank={rank}
          setIsOpen={setIsOpen}
          coords={coords}
          kinds={kinds}
          setKinds={setKinds}
          kind={rank.kind ? rank.kind : undefined}
          kindGroup={rank.kindGroup ? rank.kindGroup : undefined}
          name={rank.name ? rank.name : undefined}
          iconUrl={
            rank.kind
              ? urls[seo][rank.kind]
                ? urls[seo][rank.kind]
                : urls.all[rank.kind]
                ? urls.all[rank.kind]
                : undefined
              : undefined
          }
          setChangeSettings={setChangeSettings}
          perm={perm}
          urls={urls}
          setUrls={setUrls}
          seo={seo}
          searchConsoleCmp={searchConsoleCmp}
        />
      )}
      {rank.shortUrl !== null && isOpen && !changeSettings && (
        <RankInfosPopup
          rank={rank}
          setIsOpen={setIsOpen}
          coords={coords}
          perm={perm}
          setChangeSettings={setChangeSettings}
          keyword={keyword}
        />
      )}
    </div>
  );
};

export default RankButton;
