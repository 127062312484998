import * as React from "react";
import Popup from "../../../../common/popup";

const KeywordPopup = ({ coords, setIsOpen, value, visibility }) => {
  return (
    <Popup
      className="seo-ranks__ranking__popup seo-popup-rank-infos--left"
      style={coords}
      buttons={[]}
      setIsOpen={setIsOpen}
    >
      <div className="seo-popup-rank-infos--flex">
        <span className="seo-popup-keyword__title">
          <a
            href={`https://www.google.com/search?q=${value.keyword.replace(
              " ",
              "+"
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value.keyword}
          </a>
        </span>
        <div className="seo-popup-rank-infos--flex seo-popup-rank-infos--gap">
          <span>Volume de recherche: {value.volume}</span>
          {value.cpc ? <span>Coût par clic: {value.cpc}€</span> : null}
          {visibility && (
            <>
              <span>
                Visibilité <b>esf</b> locale: {visibility.esfNote}%
              </span>
              <span>
                Visibilité <b>famille esf</b>: {visibility.esfFamilleNote}%
              </span>
            </>
          )}
        </div>
      </div>
    </Popup>
  );
};

export default KeywordPopup;
