import * as React from "react";
import * as Icons from "../icons";
import Button from "@valraiso-esf/esf-ui/es/button";
import { useSEOAdsImpactContext } from "../common/contexts/seo-ads-impact";
import CustomPeriod from "../performances/toolbar/custom-period";
import Checkbox from "../common/select-multiple/checkbox";

const frDate = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleDateString("fr-fr");
};

const AdsImpactSelection = () => {
  const {
    isOpen,
    setIsOpen,
    period,
    updatePeriod,
    ref,
    calendarPeriod,
    data,
    newPeriod,
    getData,
    groupFilters,
    setGroupFilters,
  } = useSEOAdsImpactContext();

  return (
    <div className="seo-ads-impact__selection">
      <label className="toolbar__period seo-study-chart__container" ref={ref}>
        <Icons.Calendar className="toolbar__filters__button__icon" />
        <button
          className="toolbar__filters__button"
          onClick={() => setIsOpen(!isOpen)}
        >
          {frDate(period.startDate)} - {frDate(period.endDate)}
        </button>
        {isOpen && (
          <CustomPeriod
            state={calendarPeriod}
            setState={updatePeriod}
            className="seo-study-chart__chart"
            style={
              data.length === 0
                ? { top: "calc(100% + 2px)" }
                : { bottom: "calc(100% + 2px)" }
            }
          />
        )}
      </label>
      {newPeriod && (
        <Button
          onClick={() => getData(period)}
          className="button-valraiso-background"
        >
          Valider pour cette période
        </Button>
      )}
      <Checkbox
        label="Regroupement par filtres"
        checked={groupFilters}
        onChange={() => setGroupFilters(!groupFilters)}
      />
    </div>
  );
};

export default AdsImpactSelection;
