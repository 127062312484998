import * as React from "react";

export const NoComparisonBloc = ({ name, value, icon }) => {
  const newTime =
    name === "Durée de visite"
      ? value.new === 0
        ? "0min 0s"
        : `${Math.trunc(value.new / 60)}min ${value.new % 60}s`
      : value.new;

  return (
    <div className="date-no-comparison-bloc">
      {icon}
      <div className="date-no-comparison-bloc__content">
        <span className="date-no-comparison-bloc__title">{name}</span>
        <span className="date-no-comparison-bloc__number">
          {String(newTime)}
          {name === "Taux de rebond" && "%"}
        </span>
      </div>
    </div>
  );
};

export const ComparisonBloc = ({ name, value, icon }) => {
  const oldTime =
    name === "Durée de visite"
      ? `${Math.trunc(value.old / 60)}min ${value.old % 60}s`
      : value.old;
  const newTime =
    name === "Durée de visite"
      ? `${Math.trunc(value.new / 60)}min ${value.new % 60}s`
      : value.new;

  const styleUp = {
    color: "var(--color-pos)",
  };
  const styleDown = {
    color: "var(--color-neg)",
  };
  return (
    <div className="date-comparison-bloc">
      {icon}
      <div className="date-comparison-bloc__content">
        <span className="date-comparison-bloc__title">{name}</span>
        <span
          className="date-comparison-bloc__stat"
          style={
            value.percentage < 0 ||
            (name === "Taux de rebond" && Math.round(value.percentage) > 0)
              ? styleDown
              : styleUp
          }
        >
          {value.percentage === "♾️"
            ? "♾️"
            : Math.round(value.percentage) >= 0
            ? `+${Math.round(value.percentage)}%`
            : `${Math.round(value.percentage)}%`}
        </span>
        <span className="date-comparison-bloc__numbers">
          {(isNaN(oldTime) && name !== "Durée de visite") ||
          oldTime === "NaNmin NaNs"
            ? "?"
            : oldTime}
          {"->"}
          {(isNaN(newTime) && name !== "Durée de visite") ||
          newTime === "NaNmin NaNs"
            ? "?"
            : newTime}
        </span>
      </div>
    </div>
  );
};
