import * as React from "react";
import classnames from "classnames";
import * as Icons from "../icons";
import css from "./feedback.module.css";
import { useAppContext } from "../common/contexts/app-context";

const FEELINGS = [
  { label: "Top !", value: 4, icon: Icons.Excited, color: css.green },
  { label: "Bien", value: 3, icon: Icons.Satisfied, color: css.blue },
  { label: "OK", value: 2, icon: Icons.Neutral, color: css.yellow },
  { label: "Bof", value: 1, icon: Icons.Stressed, color: css.orange },
  { label: "Déçu(e)", value: 0, icon: Icons.Dissatisfied, color: css.red },
];

const Feeling = ({ label, icon: Icon, color, selected, onClick }) => {
  return (
    <div
      className={classnames(css.sentiment, color, {
        [css.selected]: selected,
      })}
      onClick={onClick}
    >
      <Icon />
      <span>{label}</span>
    </div>
  );
};

const initialState = {
  comment: "",
  rating: -1,
};

const Feedback = ({
  className,
  title = "Que pensez vous du filtre par produits ?",
  detail = "Le filtre par produit est en phase de test. Dites nous comment l'améliorer pour qu'il vous soit plus utile !",
}) => {
  const {
    trackEvent,
    opinionSent: sent,
    setOpinionSent: setSent,
  } = useAppContext();
  const [open, setOpen] = React.useState(false);
  const [state, dispatch] = React.useState(initialState);
  const classes = classnames(css.feedbackContainer, className, {
    [css.containerOpened]: open,
  });
  return (
    <div className={classes}>
      <div
        className={classnames(css.feedbackButtonContainer, {
          "--sent": sent,
        })}
        onClick={!sent ? () => setOpen(!open) : undefined}
      >
        <Icons.ExcitedGradient />
        {!sent ? "Un avis ?" : "Merci !"}
      </div>
      <div className={css.feedback}>
        <div className={css.title}>{title}</div>
        <div className={css.subtitle}>{detail}</div>
        <div className={css.sentiments}>
          {FEELINGS.map((feeling) => (
            <Feeling
              key={"feeling" + feeling.value}
              onClick={() => dispatch({ ...state, rating: feeling.value })}
              label={feeling.label}
              icon={feeling.icon}
              selected={state.rating === feeling.value}
              color={feeling.color}
            />
          ))}
        </div>
        {state.rating !== -1 && (
          <div className={css.commentSend}>
            <textarea
              placeholder="Un commentaire ou une suggestion ?"
              onChange={(e) => dispatch({ ...state, comment: e.target.value })}
            />
            <button
              onClick={() => {
                setOpen(false);
                setSent(true);
                window.localStorage.setItem("feedback", true);
                trackEvent("FeedBack - Tunnel by products", { props: state });
              }}
              className={css.submit}
            >
              Envoyer
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Feedback;
