import * as React from "react";
import * as Icons from "../icons";
import classnames from "classnames";
import { useSettingsContext } from "../common/contexts/settings-context";

const SettingsAlertLine = ({ alert, editable, className, index, search }) => {
  const { setRemovePopup, alerts, setAlerts } = useSettingsContext();
  const classes = classnames("settings-alerts__array__line", className);

  const startEdition = () => {
    const newAlerts = [...alerts];
    newAlerts[index].edit = true;
    setAlerts(newAlerts);
  };

  return (
    <div className={classes}>
      <span className="settings-alerts__array__line__keyword">
        {alert.keyword}
        {search !== undefined && search}
      </span>
      <span className="settings-alerts__array__line__filter">
        {alert.filter}
      </span>
      <div className="settings-alerts__array__line__others">
        <span className="settings-alerts__array__line__device">
          {alert.device}
        </span>
        <div className="settings-alerts__array__line__actions">
          {editable ? (
            <div className="settings-alerts__array__line__action__wrapper">
              <button
                className="settings-alerts__array__line__action"
                onClick={startEdition}
              >
                <Icons.Edit />
              </button>
              <button
                className="settings-alerts__array__line__action"
                onClick={() => setRemovePopup(alert)}
              >
                <Icons.Remove />
              </button>
            </div>
          ) : (
            <div className="settings-alerts__array__line__action__wrapper">
              <button
                className="settings-alerts__array__line__action settings-alerts__array__line__action--off"
                onClick={() => {}}
              />
              <button
                className="settings-alerts__array__line__action settings-alerts__array__line__action--off"
                onClick={() => {}}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsAlertLine;
