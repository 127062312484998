export const addIncomeToFilter = (data, res, setData) => {
  const comparative = data.comparative.map((type) => {
    const exist = res.comparative.find((elmt) => elmt.name === type.name);
    if (!exist) return { ...type, data: {} };
    return { ...type, data: exist.data };
  });
  const domains = data.domains.map((domain, index) => {
    return {
      ...domain,
      data: domain.data.map((type) => {
        const exist = res.domains[index].data.find(
          (elmt) => elmt.name === type.name
        );
        if (!exist) return { ...type, data: {} };
        return { ...type, data: exist.data };
      }),
    };
  });
  setData({ comparative, domains });
  return { comparative, domains };
};
