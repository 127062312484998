import * as React from "react";
import classnames from "classnames";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
import NationalRanks from "./ranks/national-ranks";
import SeoStats from "./ranks/stats";
import SeoOff from "../../page-handler/seo-off";
import { useNationalSEOContext } from "../../common/contexts/national-seo-context";
import NationalSeoObservatoryFilters from "./national-index-filters";

const NationalSeoObservatory = ({ perm }) => {
  const { isLoading, isFiltersLoading, seoOff, stats, data, device } =
    useNationalSEOContext();

  const classes = classnames("seo", {
    "seo--loading": isLoading || isFiltersLoading,
  });

  return (
    <>
      {seoOff ? (
        <SeoOff />
      ) : (
        <div className={classes} id="national-observatory">
          {isLoading || isFiltersLoading ? (
            <LoaderBar className="loaderbar-valraiso-background" />
          ) : (
            <div className="seo__index">
              <NationalSeoObservatoryFilters />
              <SeoStats
                stats={stats}
                device={device === "Ordinateur" ? "desktop" : "mobile"}
                data={data}
              />
              <NationalRanks
                data={data}
                device={device === "Ordinateur" ? "desktop" : "mobile"}
                perm={perm}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default NationalSeoObservatory;
