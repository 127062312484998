import * as React from "react";
import { useSEOContext } from "../../../common/contexts/seo-context";
import Badges from "./badges";
import Keywords from "./keywords";
import Ranking from "./ranking";

const Ranks = ({ data, device, perm, seo }) => {
  const { kinds, setKinds, urls, setUrls } = useSEOContext();
  return (
    <div className="seo-ranks">
      {data && data.keywords && data.keywords[0] ? (
        <>
          <Badges data={data} device={device} kinds={kinds[seo]} />
          <Keywords data={data} device={device} kinds={kinds[seo]} />
          <Ranking
            data={data}
            device={device}
            kinds={kinds}
            setKinds={setKinds}
            perm={perm}
            urls={urls}
            setUrls={setUrls}
            seo={seo}
          />
        </>
      ) : (
        <span
          style={{
            alignText: "center",
            width: "100%",
            position: "relative",
            zIndex: "1",
            marginTop: "-50px",
          }}
        >
          Aucune donnée trouvée
        </span>
      )}
    </div>
  );
};

export default Ranks;
