import * as React from "react";
import * as Icons from "../icons";
import classnames from "classnames";

const ExportButton = ({ downloadFile, className }) => {
  const classes = classnames("export-button", className);

  return (
    <a download={"data.csv"} href={downloadFile} className={classes}>
      <Icons.Download />
      Exporter
    </a>
  );
};

export default ExportButton;
