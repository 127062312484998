import Tooltip from "@valraiso-esf/esf-ui/es/tooltip";
import * as React from "react";
import * as Icons from "../../icons";
import classnames from "classnames";
import incomeFormat from "../../../utils/incomeFormat";
import { useAnalysisContext } from "../../common/contexts/analysis-context";

const SaleTunnelIncome = ({ value, children, set, loadData }) => {
  const {
    saleTunnelDetailOpen: isOpen,
    setSaleTunnelDetailOpen: setIsOpen,
    setSaleTunnelDetailWasOpen: setWasOpen,
    setFirstTimeOpen,
    firstTimeOpen,
    orderLoading: isLoading,
  } = useAnalysisContext();

  const hideMessage =
    set === "error"
      ? "Cacher le détail des erreurs"
      : set === "abort"
      ? "Cacher le détail des abandons"
      : "Cacher le détail des méthodes de paiement";
  const showMessage =
    set === "error"
      ? "Afficher le détail des erreurs"
      : set === "abort"
      ? "Afficher le détail des abandons"
      : "Afficher le détail des méthodes de paiement";

  const classes = classnames("sale-tunnel-income--before-loading__icon__zone", {
    "sale-tunnel-income--loading__icon__zone": firstTimeOpen === 0,
  });

  const animClasses = classnames("sale-tunnel-income--before-loading", {
    "sale-tunnel-income--end-loading": firstTimeOpen === 0 && value !== false,
  });

  return (
    <div className="sale-tunnel-income__zone__inverse">
      <div className="sale-tunnel-income__zone">
        {children}
        <Tooltip
          title={
            firstTimeOpen >= 0
              ? isOpen === set
                ? hideMessage
                : showMessage
              : undefined
          }
          position="bottom"
          className="tooltip"
        >
          <button
            className="sale-tunnel-income"
            disabled={isLoading}
            onClick={() => {
              if (firstTimeOpen === 0 && value === false) {
                return;
              }
              if (firstTimeOpen === -1) {
                loadData();
              }
              if (isOpen === set && firstTimeOpen > 0) {
                setIsOpen(false);
              } else if (firstTimeOpen >= 0) {
                setIsOpen(set);
              }
              setWasOpen(set);
              setFirstTimeOpen(firstTimeOpen + 1);
            }}
          >
            {firstTimeOpen >= 0 && value !== false && (
              <div className="sale-tunnel-income__loader">
                <Icons.Euro className="sale-tunnel-income__icon" />
                <span>{incomeFormat(value)}</span>
              </div>
            )}
            {firstTimeOpen <= 0 && (
              <div className={animClasses}>
                <div className={classes}>
                  <Icons.HourGlass className="sale-tunnel-income--before-loading__icon" />
                </div>
                <span className="sale-tunnel-income--before-loading__span">
                  Charger les revenus
                </span>
              </div>
            )}
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default SaleTunnelIncome;
