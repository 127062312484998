import * as React from "react";
import Button from "@valraiso-esf/esf-ui/es/button";
import SettingsSelect from "../common/settings/select";
import { saveArrayAsCSV } from "../../utils/saveAsCSV";
import ExportButton from "../common/export";
import SwitchButton from "../common/switch-button";
import Select from "@valraiso-esf/esf-ui/es/select";
import { getClientFilters, getSitesTypes } from "../../services/api";
import { useSEOStudyContext } from "../common/contexts/seo-study-context";

const CompetitionStudyFilters = ({
  competitor,
  device,
  setDevice,
  data,
  setPopup,
  clients,
}) => {
  const {
    selectedFilter,
    setSelectedFilter,
    selectedClient,
    setSelectedClient,
    filters,
    setCompetitor,
    setFilters,
    setKinds,
  } = useSEOStudyContext();
  const [filtersLoading, setFiltersLoading] = React.useState(false);
  const downloadFile = saveArrayAsCSV(data);

  const updateSelectedClient = async (e) => {
    const thisClient = clients.find((client) => client.name === e);
    setFiltersLoading(true);
    setCompetitor(undefined);
    setSelectedClient(thisClient);
    const [allFilters, allKinds] = await Promise.all([
      getClientFilters(thisClient.id),
      getSitesTypes(thisClient.id),
    ]);
    setSelectedFilter(allFilters[0]);
    setFilters(allFilters);
    setKinds(allKinds);
    setFiltersLoading(false);
  };

  return (
    <>
      {clients.length === 1 ? (
        <span className="seo-study__unique__select">{clients[0].name}</span>
      ) : (
        <SettingsSelect
          options={clients.map((client) => {
            return client.name;
          })}
          value={selectedClient.name}
          onChange={updateSelectedClient}
          className="seo-study__select"
        />
      )}
      {competitor && (
        <div className="top-title">
          <span className="seo-study__title">Étude de "{competitor.name}"</span>
          <ExportButton downloadFile={downloadFile} />
        </div>
      )}
      <div className="seo-study__all-buttons">
        <Button
          onClick={() => setPopup("add")}
          disabled={filtersLoading}
          className="button-valraiso-background"
        >
          Sélectionner un concurrent
        </Button>
        {competitor && (
          <div className="seo-study__switch-buttons">
            <Select
              label="Filtres"
              value={selectedFilter}
              onValueChanged={setSelectedFilter}
              className="seo-popup-set-kind__input"
            >
              {filters.map((elmt, index) => {
                return (
                  <option key={elmt + "select filter" + index}>{elmt}</option>
                );
              })}
            </Select>
            <SwitchButton
              value={device}
              values={["Ordinateur", "Téléphone"]}
              onValueChanged={setDevice}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CompetitionStudyFilters;
