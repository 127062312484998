import * as React from "react";
import classnames from "classnames";
import Toast from "@valraiso-esf/esf-ui/es/toast";
import MenuButton from "./menu-button";
import * as Icons from "../icons";
import { useNavigate } from "react-router-dom";
import { useAnalysisContext } from "../common/contexts/analysis-context";
import { hasESFAccess, hasNationalESFAccess } from "../../utils/seo";
import UsersAccess from "./users-access";
import { useAppContext } from "../common/contexts/app-context";

const Menu = ({
  menuOpen,
  setPage,
  setMenuOpen,
  page,
  filters,
  filtersData,
  firstData,
  setFirstData,
  perm,
}) => {
  const { updateAllPerfomancesData, selectedFilter, datePeriod } =
    useAnalysisContext();
  const { menuRef } = useAppContext();
  const navigate = useNavigate();
  const [copied, setCopied] = React.useState(false);
  const [accessOpen, setAccessOpen] = React.useState(false);
  const ref = React.useRef();

  const isESF = hasESFAccess(filtersData);
  const isNationalESF = hasNationalESFAccess(filtersData);
  const domainHasSEO = filters[0] && filters[0].seo >= 1;

  const classes = classnames("navigation-menu__pages__hider", {
    "navigation-menu--open": menuOpen,
    "navigation-menu--close": !menuOpen,
  });

  const buttons = [
    {
      name: "Données Plausible",
      perm: true,
      icon: Icons.Plausible,
      path: "plausible",
    },
    {
      name: "Analyse performances",
      perm: true,
      icon: Icons.Stats,
      path: "analysis",
    },
    {
      name: "Référencement",
      perm: domainHasSEO,
      icon: Icons.Seo,
      path: "seo-station",
    },
    {
      name: "Référencement national",
      perm: isNationalESF,
      icon: Icons.NationalSeo,
      path: "seo-national",
    },
    {
      name: "Étude de la concurrence",
      perm: isESF,
      icon: Icons.CompetitorStudy,
      path: "study",
    },
    {
      name: "Impact des adwords",
      perm: perm === 3,
      icon: Icons.CompetitorStudy,
      path: "ads",
    },
    {
      name: "Paramétrage",
      perm: domainHasSEO,
      icon: Icons.Settings,
      path: "settings",
    },
  ];

  const onClickOutside = (e) => {
    if (
      window.innerWidth < 695 &&
      !ref.current.contains(e.target) &&
      !menuRef.current.contains(e.target)
    ) {
      setMenuOpen(false);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", onClickOutside);
    return () => {
      document.body.removeEventListener("click", onClickOutside);
    };
  }, []);

  return (
    <div className={classes} ref={ref}>
      <div className="navigation-menu">
        <div className="navigation-menu__pages">
          {buttons.map((elmt) => {
            if (!elmt.perm) return null;
            return (
              <MenuButton
                key={elmt.name + "menu button"}
                label={elmt.name}
                onClick={() => {
                  if (elmt.name === "Analyse performances" && firstData) {
                    setFirstData(false);
                    updateAllPerfomancesData(selectedFilter, datePeriod);
                  }
                  setMenuOpen(false);
                  navigate(`/statistics/${elmt.path}`);
                  setPage(elmt.name);
                }}
                Icon={elmt.icon}
                selected={page === elmt.name}
                displayLabel={menuOpen}
              />
            );
          })}
        </div>
        {perm === 3 && (
          <div>
            <MenuButton
              label={"Changer d'accès"}
              onClick={(out) => {
                if (out === true) setAccessOpen(false);
                else setAccessOpen(!accessOpen);
              }}
              Icon={Icons.Copy}
              selected={false}
              displayLabel={menuOpen}
              detailArrow={true}
            />
          </div>
        )}
        {copied !== false && (
          <Toast seconds={1.5} variant="success">
            {`Accès ${copied} copié !`}
          </Toast>
        )}
      </div>
      {accessOpen && <UsersAccess setCopied={setCopied} menuOpen={menuOpen} />}
    </div>
  );
};

export default Menu;
