import * as React from "react";
import { subYears } from "date-fns";
import ComparisonBlocs from "./blocs";
import { getOldData } from "../../../services/api";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
import * as Icons from "../../icons";
import { useAnalysisContext } from "../../common/contexts/analysis-context";
import { toDateString } from "../../../utils/dates";

const CHOICES = ["1 an avant", "2 ans avant", "3 ans avant"];

export const getDataStats = (domainsData, domainsCmpData) => {
  const { visitors, events, bounceRate, visitDuration } = domainsCmpData;
  const [VU, VT, TR, DV] = domainsData
    ? [
        domainsData.reduce((total, data) => total + data.visitors, 0),
        domainsData.reduce((total, data) => total + data.events, 0),
        domainsData.reduce(
          (total, data) => total + data.bounce_rate * data.visitors,
          0
        ),
        domainsData.reduce(
          (total, data) => total + data.visit_duration * data.visitors,
          0
        ),
      ]
    : [0, 0, 0, 0, 0, 0, 0, 0];

  const values = [
    {
      old: visitors,
      new: VU,
      percentage: visitors !== 0 ? ((VU - visitors) * 100) / visitors : "♾️",
    },
    {
      old: events,
      new: VT,
      percentage: events !== 0 ? ((VT - events) * 100) / events : "♾️",
    },
    {
      old: visitors === 0 ? "♾️" : Math.round(bounceRate / visitors),
      new: VU === 0 ? "♾️" : Math.round(TR / VU),
      percentage:
        bounceRate !== 0 ? ((TR - bounceRate) * 100) / bounceRate : "♾️",
    },
    {
      old: visitors === 0 ? 0 : Math.round(visitDuration / visitors),
      new: VU === 0 ? 0 : Math.round(DV / VU),
      percentage:
        visitDuration !== 0
          ? ((DV - visitDuration) * 100) / visitDuration
          : "♾️",
    },
  ];
  return values;
};

const DateComparison = ({ globalLoading, firstDatePeriod }) => {
  const {
    domainsData,
    selectedFilter,
    values,
    setValues,
    comparisonPeriod,
    setComparisonPeriod,
    valuesComparison,
  } = useAnalysisContext();
  const [loaderBar, setLoaderBar] = React.useState(false);
  const [comparison, setComparison] = React.useState(CHOICES[0]);
  const [comparisonOpen, setComparisonOpen] = React.useState(false);
  const ref = React.useRef();

  const onClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setComparisonOpen(false);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", onClickOutside);
    return () => {
      document.body.removeEventListener("click", onClickOutside);
    };
  }, []);

  const updateComparisonPeriod = async (e, index) => {
    setLoaderBar(true);
    e.stopPropagation();
    setComparisonOpen(false);
    setComparison(e.target.value);
    setComparisonPeriod({
      start: index + 1,
      end: index + 1,
    });
    const body = {
      name: selectedFilter.name,
      kind: selectedFilter.kind,
      dateTo: toDateString(subYears(new Date(firstDatePeriod.end), index + 1)),
      dateFrom: toDateString(
        subYears(new Date(firstDatePeriod.start), index + 1)
      ),
      filters: selectedFilter.filters.filter[0]
        ? selectedFilter.filters.filter
        : undefined,
    };
    const response = await getOldData({
      ...body,
      dateTo: toDateString(
        subYears(new Date(firstDatePeriod.end), comparisonPeriod.end)
      ),
      dateFrom: toDateString(
        subYears(new Date(firstDatePeriod.start), comparisonPeriod.start)
      ),
    });
    const dateComparativeRes = getDataStats(domainsData, response);
    setValues(dateComparativeRes);
    setLoaderBar(false);
  };

  return (
    <div className="date-comparison">
      <div className="date-comparison__select-comparison" ref={ref}>
        Comparaison
        <button
          className="date-comparison__select-comparison__button"
          onClick={() => setComparisonOpen(!comparisonOpen)}
          disabled={loaderBar}
        >
          {comparison ? <span>{comparison}</span> : undefined}
          <Icons.ExpandDown className="date-comparison__select-comparison__button__icon" />
        </button>
        {comparisonOpen && (
          <div className="date-comparison__select-comparison__options">
            {CHOICES.map((choice, index) => {
              return (
                <button
                  key={choice}
                  value={choice}
                  index={index}
                  className="date-comparison__select-comparison__option"
                  onClick={(e) => updateComparisonPeriod(e, index)}
                >
                  {choice}
                </button>
              );
            })}
          </div>
        )}
      </div>
      {(loaderBar || globalLoading) && (
        <div className="loaderbar">
          <LoaderBar className="loaderbar-valraiso-background" />
        </div>
      )}
      <ComparisonBlocs values={values} compare={valuesComparison} />
    </div>
  );
};

export default DateComparison;
