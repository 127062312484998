import * as React from "react";
import { getAlerts, getEveryPossibleKewords } from "../../../services/api";

const SettingsContext = React.createContext();

export const useSettingsContext = () => {
  const context = React.useContext(SettingsContext);
  if (!context)
    throw new Error(
      "useSettingsContext must be used within a SettingsContextProvider"
    );
  return context;
};

const SettingsContextProvider = (props) => {
  const { perm } = props ? props : {};
  const [alerts, setAlerts] = React.useState([]);
  const [keywords, setKeywords] = React.useState([]);
  const [mail, setMail] = React.useState("");
  const [firstMail, setFirstMail] = React.useState("");
  const [removePopup, setRemovePopup] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchedValue, setSearchedValue] = React.useState("");
  const [alertType, setAlertType] = React.useState("Quotidien");
  const [filters, setFilters] = React.useState([]);

  React.useEffect(() => {
    const exec = async () => {
      setIsLoading(true);
      const [
        { alerts, mail, type },
        { filters: allFilters, keywords: allKeywords },
      ] = await Promise.all([getAlerts(), getEveryPossibleKewords()]);
      setKeywords(allKeywords);
      setFilters(allFilters);
      setMail(mail);
      setAlertType(type);
      setFirstMail(mail);
      setAlerts(alerts);
      setIsLoading(false);
    };
    exec();
  }, [perm]);

  return (
    <SettingsContext.Provider
      value={{
        alerts,
        setAlerts,
        keywords,
        mail,
        setMail,
        firstMail,
        setFirstMail,
        removePopup,
        setRemovePopup,
        isLoading,
        searchedValue,
        setSearchedValue,
        alertType,
        setAlertType,
        filters,
      }}
      {...props}
    />
  );
};

export default SettingsContextProvider;
