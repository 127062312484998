import * as React from "react";
import Select from "@valraiso-esf/esf-ui/es/select";
import { useSEOContext } from "../../common/contexts/seo-context";
import { useAppContext } from "../../common/contexts/app-context";
import SwitchButton from "../../common/switch-button";
import { sortSeo } from "../../../utils/sort";

const SeoObservatoryFilters = ({ domain, device, setDevice }) => {
  const {
    sync,
    filters,
    setSelectedFilter,
    selectedFilter,
    selectedSort,
    setSelectedSort,
    data,
    setData,
    historicalData,
    setHistoricalData,
  } = useSEOContext();
  const { ESFDomain, trackEvent } = useAppContext();

  return (
    <div className="seo__top">
      {domain} : {window.innerWidth <= 695 && <br />}
      {sync}
      <div className="seo__top__right__filters">
        <Select
          label="Trier par: "
          value={selectedSort}
          onValueChanged={(e) => {
            if (e === "Volume") trackEvent("Seo sort by volume");
            setSelectedSort(e);
            sortSeo(e, data, setData, filters);
            if (historicalData)
              sortSeo(e, historicalData, setHistoricalData, filters);
          }}
          className="seo-popup-set-kind__input"
        >
          <option>Aucun tri</option>
          <option>Volume</option>
        </Select>
        <Select
          label={ESFDomain ? "Langues" : "Thématiques"}
          value={selectedFilter}
          onValueChanged={(e) => {
            trackEvent("Seo filter", { props: { filter: e } });
            setSelectedFilter(e);
          }}
          className="seo-popup-set-kind__input"
        >
          {filters.map((elmt, index) => {
            return <option key={elmt + "select filter" + index}>{elmt}</option>;
          })}
        </Select>
        <SwitchButton
          value={device}
          values={["Ordinateur", "Téléphone"]}
          onValueChanged={setDevice}
        />
      </div>
    </div>
  );
};

export default SeoObservatoryFilters;
