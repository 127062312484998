import * as React from "react";
import CustomPeriod from "./custom-period";
import { subDays } from "date-fns";
import { toDateString } from "../../../utils/dates";

const Period = ({
  state,
  setState,
  updatePeriodSelection,
  updatePeriodSelectionAuto,
  globalLoading,
}) => {
  const [customPeriod, setCustomPeriod] = React.useState(false);

  const PERIOD = [
    {
      name: "Aujourd'hui",
      start: toDateString(new Date()),
      end: toDateString(new Date()),
    },
    {
      name: "Derniers 7 jours",
      start: toDateString(subDays(new Date(), 7)),
      end: toDateString(new Date()),
    },
    {
      name: "Derniers 30 jours",
      start: toDateString(subDays(new Date(), 30)),
      end: toDateString(new Date()),
    },
    {
      name: "Derniers 365 jours",
      start: toDateString(subDays(new Date(), 365)),
      end: toDateString(new Date()),
    },
  ];

  return (
    <div className="period">
      <div className="period__choices">
        {PERIOD.map((period) => {
          return (
            <button
              disabled={globalLoading}
              key={period.name}
              className="period__choice"
              onClick={() => {
                updatePeriodSelectionAuto(
                  period.start,
                  period.end,
                  period.name
                );
              }}
            >
              {period.name}
            </button>
          );
        })}
        <hr className="period__choice__separator" />
        <button
          className="period__choice"
          onClick={() => setCustomPeriod(!customPeriod)}
        >
          Période personnalisée
        </button>
        {customPeriod && (
          <button
            className="custom-period__validation"
            onClick={updatePeriodSelection}
          >
            VALIDER
          </button>
        )}
        {customPeriod && (
          <button
            className="custom-period__deletion"
            onClick={() => setCustomPeriod(false)}
          >
            FERMER
          </button>
        )}
      </div>
      {customPeriod && (
        <CustomPeriod
          state={state}
          setState={setState}
          className="custom-period"
        />
      )}
    </div>
  );
};

export default Period;
