import * as React from "react";
import useSetPage from "../../hooks/use-set-page";
import SEOContextProvider from "../common/contexts/seo-context";
import SeoObservatory from "./detail";

const Seo = ({ domain, perm, setPage, filters }) => {
  useSetPage(setPage, "Référencement");

  return (
    <SEOContextProvider domain={domain}>
      <SeoObservatory perm={perm} domain={domain} seo={filters[0].seo} />
    </SEOContextProvider>
  );
};

export default Seo;
