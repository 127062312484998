import * as React from "react";
import {
  getData,
  getDetail,
  getOldData,
  getProductsIncomes,
} from "../../../services/api";
import { subYears } from "date-fns";
import { filterSourcesAndAffiliations } from "../../../utils/seo-sea";
import { getDataStats } from "../../performances/date-comparison/date-comparison";
import { toDateString } from "../../../utils/dates";
import { DEFAULT_GOALS_DATA } from "../../../data";

const DEFAULT_FILTER = { comparative: [], domains: [] };

const AnalysisContext = React.createContext();

export const useAnalysisContext = () => {
  const context = React.useContext(AnalysisContext);
  if (!context)
    throw new Error(
      "useAnalysisContext must be used within a AnalysisContextProvider"
    );
  return context;
};

const AnalysisContextProvider = (props) => {
  const { setApiOff, setGlobalLoading, domain, trackEvent } = props
    ? props
    : {};

  const [domainsData, setDomainsData] = React.useState([]);
  const [country, setCountry] = React.useState(DEFAULT_FILTER);
  const [sources, setSources] = React.useState(DEFAULT_FILTER);
  const [devices, setDevices] = React.useState(DEFAULT_FILTER);
  const [affiliations, setAffiliations] = React.useState([]);
  const [charged, setCharged] = React.useState([]);
  const [values, setValues] = React.useState([]);
  const [valuesComparison, setValuesComparison] = React.useState(true);
  const [firstTimeOpen, setFirstTimeOpen] = React.useState(-1);
  const [orderIds, setOrderIds] = React.useState({
    successMethod: [],
    successData: [],
    detailSuccess: [],
    totalFailure: false,
    totalSuccess: false,
    failureNumber: 0,
    successNumber: 0,
  });
  const [saleTunnelDetailOpen, setSaleTunnelDetailOpen] = React.useState(false);
  const [saleTunnelDetailWasOpen, setSaleTunnelDetailWasOpen] =
    React.useState("");
  const [orderLoading, setOrderLoading] = React.useState(false);
  const [abortIncomes, setAbortIncomes] = React.useState(false);
  const [errorData, setErrorData] = React.useState([[], 0]);
  const [comparisonPeriod, setComparisonPeriod] = React.useState({
    start: 1,
    end: 1,
  });
  const [hoteIdsRate, setHoteIdsRate] = React.useState({});
  const [selectedDetail, setSelectedDetail] = React.useState("Sites");
  const [seoSeaData, setSeoSeaData] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState({
    kind: "Sites",
    name: domain,
    filters: { name: [], type: [], filter: [] },
  });
  const [datePeriod, setDatePeriod] = React.useState({
    start: toDateString(new Date()),
    end: toDateString(new Date()),
    name: "Aujourd'hui",
  });
  // products
  const [tunnel, setTunnel] = React.useState({
    products: ["Tout produit"],
    data: [DEFAULT_GOALS_DATA],
    execCodes: [],
    affiliation: [],
  });
  const [domainsIncomes, setDomainsIncomes] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState("Tout produit");
  const [feedbackDone, setFeedBackDone] = React.useState(
    window.localStorage.getItem("feedback") != null
  );

  const updateSelectedDetail = (newDetail) => {
    setSelectedDetail(newDetail);
    if (newDetail !== selectedDetail) {
      trackEvent("Analysis comparative", {
        props: { detail: newDetail },
      });
    }
  };

  const updateAllPerfomancesData = async (_selectedFilter, _datePeriod) => {
    setGlobalLoading(true);
    const body = {
      kind: _selectedFilter.kind,
      name: _selectedFilter.name,
      dateTo: _datePeriod.end,
      dateFrom: _datePeriod.start,
      filters: _selectedFilter.filters.filter[0]
        ? _selectedFilter.filters.filter
        : undefined,
    };
    trackEvent("Analysis context", {
      props: { context: JSON.stringify(body) },
    });
    // reinitialize
    setSaleTunnelDetailWasOpen("");
    setSaleTunnelDetailOpen(false);
    setOrderLoading(false);
    setAbortIncomes(false);
    setSelectedProduct("Tout produit");
    setFirstTimeOpen(0);
    setCharged(["Sites", "Affiliations"]);
    // values
    const [{ data: valuesRes }, comparativeRes, response, productsData] =
      await Promise.all([
        getData(body),
        getDetail(body),
        getOldData({
          ...body,
          dateTo: toDateString(
            subYears(new Date(_datePeriod.end), comparisonPeriod.end)
          ),
          dateFrom: toDateString(
            subYears(new Date(_datePeriod.start), comparisonPeriod.start)
          ),
        }),
        getProductsIncomes(body),
      ]).catch(() => {
        setApiOff(true);
        setGlobalLoading(false);
      });
    setDomainsData(valuesRes);
    // comparative
    const { dataDevice, dataCountry, dataSource } = comparativeRes;
    setDevices(dataDevice);
    setCountry(dataCountry);
    setSources(dataSource);
    setAffiliations(productsData.tunnel.affiliation);
    setSeoSeaData([]);
    setSeoSeaData(
      filterSourcesAndAffiliations(dataSource, productsData.tunnel)
    );
    // date-comparative
    const dateComparativeRes = getDataStats(valuesRes, response);
    setValues(dateComparativeRes);
    const shouldCompare = dateComparativeRes[0].old !== 0 ? true : false;
    if (!shouldCompare) {
      setTimeout(() => {
        setValuesComparison(false);
      }, 500);
    }
    // sale-tunnel
    setTunnel(productsData.tunnel);
    setDomainsIncomes(productsData.domains);
    setApiOff(false);
    setGlobalLoading(false);
  };

  return (
    <AnalysisContext.Provider
      value={{
        datePeriod,
        domainsData,
        selectedFilter,
        country,
        setCountry,
        devices,
        setDevices,
        sources,
        setSources,
        setGlobalLoading,
        setSelectedFilter,
        charged,
        setCharged,
        affiliations,
        values,
        setValues,
        valuesComparison,
        firstTimeOpen,
        setFirstTimeOpen,
        orderIds,
        setOrderIds,
        saleTunnelDetailOpen,
        setSaleTunnelDetailOpen,
        saleTunnelDetailWasOpen,
        setSaleTunnelDetailWasOpen,
        orderLoading,
        abortIncomes,
        errorData,
        comparisonPeriod,
        setComparisonPeriod,
        setDatePeriod,
        updateAllPerfomancesData,
        selectedDetail,
        updateSelectedDetail,
        seoSeaData,
        setSeoSeaData,
        hoteIdsRate,
        setHoteIdsRate,
        tunnel,
        selectedProduct,
        setSelectedProduct,
        setErrorData,
        domainsIncomes,
        setOrderLoading,
        setAbortIncomes,
        setApiOff,
        setDomainsData,
        setAffiliations,
        setValuesComparison,
        setTunnel,
        setDomainsIncomes,
        feedbackDone,
        setFeedBackDone,
      }}
      {...props}
    />
  );
};

export default AnalysisContextProvider;
