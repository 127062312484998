import * as React from "react";
import { useLocation } from "react-router-dom";
import { login, domainAcceptableForToken } from "../services/api";
import { isESFDomain } from "../utils/seo";

const useClientLogin = (
  setLoggedIn,
  setDomain,
  setAuth,
  setPerm,
  setESFDomain
) => {
  const { search } = useLocation();

  React.useEffect(() => {
    const exec = async () => {
      const url = new URLSearchParams(search);
      const auth = url.get("auth");
      const token = url.get("token");
      if (auth) {
        const response = await login(undefined, auth);
        if (response.success) {
          setLoggedIn(true);
          setDomain(response.success);
          window.localStorage.setItem("domain", response.success);
          setESFDomain(isESFDomain(response.success));
          window.localStorage.setItem("auth", auth);
        }
        setAuth(auth);
        return;
      }
      if (token) {
        const response = await login(undefined, undefined, token);
        if (response.success) {
          setDomain(response.domain);
          setESFDomain(isESFDomain(response.domain));
          setPerm(response.success);
          setLoggedIn(true);
          window.localStorage.setItem("token", token);
          const storedDomain = window.localStorage.getItem("domain");
          if (storedDomain) {
            const accepted = await domainAcceptableForToken(storedDomain);
            if (accepted) setDomain(storedDomain);
            else window.localStorage.setItem("domain", response.domain);
          } else {
            window.localStorage.setItem("domain", response.domain);
          }
        }
      }
    };
    exec();
  }, [search, setAuth, setDomain, setLoggedIn, setPerm, setESFDomain]);
};

export default useClientLogin;
