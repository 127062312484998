import * as React from "react";
import SwitchButton from "../../common/switch-button";
import Select from "@valraiso-esf/esf-ui/es/select";
import { useNationalSEOContext } from "../../common/contexts/national-seo-context";

const NationalSeoObservatoryFilters = () => {
  const {
    rankSync,
    geograhicZone,
    setGeograhicZone,
    specificities,
    keyword,
    setKeyword,
    keywordsList,
    device,
    setDevice,
  } = useNationalSEOContext();

  return (
    <>
      <span className="seo__syncdate">{rankSync}</span>
      <div className="seo__top">
        <div className="seo__top__column">
          <div className="seo__top__right">
            <div className="seo__top__select">
              <Select
                label="Sélection géographique"
                value={geograhicZone}
                onValueChanged={setGeograhicZone}
                className="seo-popup-set-kind__input"
              >
                <optgroup label="Régions">
                  {specificities &&
                    specificities.map((elmt) => {
                      return <option key={elmt + "choice"}>{elmt}</option>;
                    })}
                </optgroup>
                <optgroup label="ESF">
                  <option>ESF</option>
                </optgroup>
              </Select>
            </div>
            <div className="seo__top__select">
              <Select
                label="Mots-clés"
                value={keyword.keyword}
                onValueChanged={(e) => {
                  const index = keywordsList.findIndex(
                    (elmt) => elmt.keyword === e
                  );
                  setKeyword({ ...keywordsList[index] });
                }}
                className="seo-popup-set-kind__input"
              >
                <optgroup label="Français">
                  {keywordsList &&
                    keywordsList.map((elmt) => {
                      if (elmt.filter !== "fr") return null;
                      return (
                        <option key={"fr" + elmt.keyword + "choice"}>
                          {elmt.keyword}
                        </option>
                      );
                    })}
                </optgroup>
                <optgroup label="Anglais">
                  {keywordsList &&
                    keywordsList.map((elmt) => {
                      if (elmt.filter !== "en") return null;
                      return (
                        <option key={"en" + elmt.keyword + "choice"}>
                          {elmt.keyword}
                        </option>
                      );
                    })}
                </optgroup>
              </Select>
            </div>
            <SwitchButton
              value={device}
              values={["Ordinateur", "Téléphone"]}
              onValueChanged={setDevice}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NationalSeoObservatoryFilters;
